import React, { useEffect, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ProfileContext } from "../context/ProfileContext";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/Theme";
import { axiosCustom } from "../endpoints/Axios";
import Home from "../components/home/Home";
import Footer from "../components/footer/Footer";
import AboutUs from "../components/useful-links/AboutUs";
import TopBar from "../components/bars/topbar/TopBar";
import { GET_CATEGORIES_TREE, REFRESH_TOKEN } from "../endpoints/Constants";
import { useState } from "react";
import PrivacyPolicy from "../components/useful-links/PrivacyPolicy";
import TermsAndConditions from "../components/useful-links/TermsAndConditions";
import { NotFound } from "./NotFound";
import { ProductDetails } from "../components/products/product-details/ProductDetails";
import { CategoryProducts } from "../components/products/category-products/CategoryProducts";
import AuthComponentsContextProvider from "../context/AuthComponentsContext";
import SideBar from "../components/bars/sidebar/SideBar";
import { Profile } from "../components/profile/Profile";
import CartContextProvider from "../context/CartContext";
import { MyCart } from "../components/cart/MyCart";
import { Helmet } from "react-helmet";
import { OrderDetails } from "../components/profile/orders/OrderDetails";

const MainRoutes = () => {
  const profile = useContext(ProfileContext);
 
  useEffect(() => {
    axiosCustom.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if ((error?.response?.status === 401 || error?.response?.status === 403) && !prevRequest?.sent) {
          prevRequest.sent = true;
          if (localStorage.getItem("ematkn")) {
            axiosCustom
              .post(REFRESH_TOKEN, {})
              .then((res) => {
                localStorage.setItem("ematkn", res.data.access_token)
                window.location.reload();
              })
              .catch((err) => {
                localStorage.removeItem("ematkn")
                window.location.reload();
              });
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosCustom.interceptors.response.eject();
    };
  }, []);

  

  const useCurrentRoute = useLocation();
  const defaultDescription = "eMart is B2B marketplace that brings together micro, "+
  "small, medium enterprises (SME) business with single "+
  "platform that make merchants to order their needs "+
  "from wide range of models and goods and receive "+
  "delivery with wide range of payment methods "+
  "designed to facilitate the payment way "+
  "Using the state of art of technology and machine "+
  "learning, Mart solution also help the brands and "+
  "manufacturers to gain critical insights into market they "+
  "serve to refine their strategies and increase "+
  "efficiencies"
  const defaultKeywords = "emart, marketplace, sme, business, merchants, market, brands, technology, machine learning, insights, strategy, efficiency, electronics, mobile phones, accessories, mobile accessories, tablets, online shopping, iphone, samsung, huawei, apple, nokia, sony, lg, xiaomi, realme, oppo, "+
    "إيمارت, موبايل, تجارة,إلكترونيات, تسوق عبر الإنترنت, تسوق أونلاين, تلفزيون, أي فون, آيفون"
 
  const [meta, setMeta] = useState({
    title: "eMart",
    description: defaultDescription,
    keywords: defaultKeywords,
  });

  const pagesDefaultMetaData = {
    "/": {
      title: "eMart | Home",
      description: defaultDescription,
      keywords: defaultKeywords,
    },
    "profile":{
      title: "eMart | Profile",
      description: defaultDescription,
      keywords: defaultKeywords,
    },
    "cart":{
      title: "eMart | Cart",
      description: defaultDescription,
      keywords: defaultKeywords,
    },
    "order":{
      title: "eMart | Order",
      description: defaultDescription,
      keywords: defaultKeywords,
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if(useCurrentRoute.pathname === "/"){
      setMeta(pagesDefaultMetaData["/"])
    }
    else{
      if(pagesDefaultMetaData[useCurrentRoute.pathname.split("/")[1]]){
        setMeta(pagesDefaultMetaData[useCurrentRoute.pathname.split("/")[1]])
      }
    }
  }, [useCurrentRoute]);// eslint-disable-line

  const [categoriesTree, setCategoriesTree] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    setLoading(true);
    axiosCustom
      .get(GET_CATEGORIES_TREE)
      .then((res) => {
        setCategoriesTree(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //window.location.pathname = "/not-found"
      });
  }, []);



  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthComponentsContextProvider>
         <CartContextProvider>
         <Helmet>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="keywords" content={meta.keywords} />
          </Helmet>
         
          {profile.isLoading === true || loading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <TopBar categoriesTree={categoriesTree} />
              <SideBar categoriesTree={categoriesTree}/>
              <Box  minHeight={"60vh"}>

                <Routes>
                  <Route path="/" element={<Home categoriesTree={categoriesTree}/>} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />

                  <Route path="/products/*" element={<CategoryProducts />} />
                  <Route path="/product/*" element={<ProductDetails />} />
                  {profile.connected ?
                  <Route path="/profile/*" element={<Profile />} />
                  :null}
                  {profile.connected ?
                  <Route path="/cart" element={<MyCart />} />
                  :null}
                  {profile.connected ?
                  <Route path="/order" element={<OrderDetails />} />
                  :null}
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </Box>
              <Footer categoriesTree={categoriesTree} />
            </Box>
          )}
          </CartContextProvider>
        </AuthComponentsContextProvider>
      </ThemeProvider>
    </>
  );
};

export default MainRoutes;
