import React, { useEffect, useState } from "react";
import { getCategoryById, getCategoryProducts } from "../Functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { ProductCard } from "../../../ui-common-components/ProductCard";
import PaginationCustom from "../../../ui-common-components/PaginationCustom";
import { BreadcrumbsCustom } from "../../../ui-common-components/Breadcrumbs";
import { breadcrumbRec } from "../../../common-functions/Functions";
import { Helmet } from "react-helmet";

export const CategoryProducts = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const navigate = useNavigate();

  const [category, setCategory] = useState();
  const [products, setProducts] = useState();
  const [metaData, setMetaData] = useState({ last_page: -1 });
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const buildBreadcrumbs = (data) => {
    let breadcrumbsIn = [
      {
        href: "/products/"+data.slug+"?categoryId=" + data.id + "&page=1",
        name: data.name,
      },
    ];
    breadcrumbsIn = breadcrumbRec(breadcrumbsIn, data.path, t("home"));
    setBreadcrumbs(breadcrumbsIn.reverse());
  };

  const fetchProducts = async (
    categoryId,
    page,
    searchText,
    price,
    filters
  ) => {
    setProductsLoading(true);
    const data = await getCategoryProducts(
      categoryId,
      page,
      searchText,
      price,
      filters
    );
    setProducts(data.data);
    setMetaData(data.meta);
    setProductsLoading(false);
  };

  const searchTemplateData = {
    name: t("search") + ": " + searchParams.get("search"),
  };

  const initMinMaxPrice = (data) => {
    const priceFilter = searchParams.get("priceFilter");

    if (priceFilter) {
      const values = priceFilter.split(",");
      setMinMaxValue([values[0], values[1]]);
    } else {
      const minMax = getMinMaxValue(data);
      setMinMaxValue(minMax);
    }
  };
  const fetchCategoryDetails = async (categoryId) => {
    setCategoryLoading(true);
    if (categoryId) {
      try {
        const data = await getCategoryById(categoryId);
        buildBreadcrumbs(data);
        if (parseInt(categoryId) === 1) {

          data.name = searchTemplateData.name;
        }
        setCategory(data);
        initMinMaxPrice(data);
      } catch (error) {
        navigate("/not-found");
      }
    } else {
      setCategory(searchTemplateData);
    }
    setCategoryLoading(false);
  };

  const getFilterString = (categoryId, page, priceFilter, searchText) => {
    let filterString = window.location.search;
    filterString = filterString.replace("?", "");

    filterString = filterString.replace("&categoryId=" + categoryId, "");
    filterString = filterString.replace("categoryId=" + categoryId, "");

    filterString = filterString.replace("&page=" + page, "");
    filterString = filterString.replace("page=" + page, "");

    filterString = filterString.replace("&priceFilter=" + priceFilter, "");

    filterString = filterString.replace("&search=" + searchText, "");
    filterString = filterString.replace("search=" + searchText, "");

    return filterString;
  };

  useEffect(() => {
    let categoryId = searchParams.get("categoryId");
    const page = searchParams.get("page");

    if(categoryId === "offers"){
      fetchProducts(
        categoryId,
        page,
        "",
        "",
        ""
      );
      setCategoryLoading(false)
    }
    else{

      const priceFilter = searchParams.get("priceFilter");
      const search = searchParams.get("search");
      if(!categoryId && !search) {
        navigate("/not-found");
      }
      else{

        if(!categoryId){
          categoryId = 1
        }

        if (
          !category ||
          (category && parseInt(category.id) !== parseInt(categoryId))
        ) {
          fetchCategoryDetails(categoryId);
        } else if (category) {
          initMinMaxPrice(category);
        }

        // Updating search text
        if(search && category){
          let data = category
          if (parseInt(category.id) === 1) {

            data.name = searchTemplateData.name;
          }
          setCategory(data);
        }
  
        fetchProducts(
          categoryId !== 1 ? categoryId : null,
          page,
          search,
          priceFilter,
          getFilterString(categoryId, page, priceFilter, search)
        );
      }
    }
    

  }, [searchParams]); // eslint-disable-line

  const filterProducts = () => {
    let categoryId = searchParams.get("categoryId");
    let page = searchParams.get("page");
    let search = searchParams.get("search");

    // Reset params
    let params = "?categoryId=" + categoryId;

    if (search) {
      params += "&search=" + search;
    }

    params += "&page=" + page;

    // Price filtering
    params += "&priceFilter=" + (minMaxValue[0] + "," + minMaxValue[1]);

    // Rest of the filters
    category.filterable_attributes.forEach((attribute) => {
      if (attribute.type !== "price") {
        let firstCheck = true;
        attribute.options.forEach((option, index) => {
          if (option.checked) {
            if (firstCheck) {
              params += "&" + attribute.code + "=";
            }

            if (!firstCheck) {
              params += ",";
            }

            params += option.id;

            if (firstCheck) {
              firstCheck = false;
            }
          }
        });
      }
    });
    navigate(window.location.pathname + params);
  };

  const getMinMaxValue = (category) => {
    const min = category.min_price ? category.min_price : 0;
    const max = category.max_price ? category.max_price : 100000;
    return [min, max];
  };
  const [minMaxValue, setMinMaxValue] = React.useState([0, 0]);

  const handleChange = (event, newValue) => {
    setMinMaxValue(newValue);
  };

  const handleCheckboxChange = (event, index, subIndex) => {
    category.filterable_attributes[index].options[subIndex].checked =
      event.target.checked;
    setCategory({ ...category });
  };

  const skeletonSize = Array(12).fill(0);

  const buildProductsGrid = (data) => {
    return (
      <Grid
        container
        //spacing={5}
        //rowSpacing={0}
        marginTop={2}
        sx={{
          justifyContent: "flex-start",
          backgroundColor: "primary.mainLight",
          p: 0.3,
        }}
      >
        {!productsLoading && data.length === 0 ? (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              height: "50vh",
              fontSize: { xs: "0.8rem", md: "1rem" },
            }}
          >
            {t("not_available")}
          </Typography>
        ) : null}

        {data.map((item, index) => {
          /*if(item && index%2==0){
            item.promotion_message= "test"
          }*/
          return (
            <Grid
              key={"product-" + index}
              item
              xs={6}
              sm={4}
              md={4}
              lg={3}
              sx={{
                
               // margin: 0.55,
                //marginBottom: 1,
                //marginRight:1,

               // padding: 1,
              }}
            >
              <Box sx={{
                backgroundColor: "primary.light",
               // margin: 0.55,
                //marginBottom: 1,
                //marginRight:1,
                //height: { xs: "320px", md: "430px" },
                padding: 1,
                m:0.3,
                height:"99%"
              }}>

              <ProductCard
                product={item}
                loading={productsLoading}
                hasBadge={true}
                />
                </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const meta = {
    title: category ? category.meta_title : "eMart | Category",
    description: category
      ? category.meta_description
      : "The online marketplace emart-eg.com is Egypt’s online market leader in the fields of IT, consumer electronics and telecommunication. It’s offering consistently low prices as well as fast, reliable and free delivery. We enable a comfortable and efficient shopping experience at consistently low prices. At the same time, we create a comprehensive platform for inspiration, information and communication that invites customers to actively participate",
    keywords: category ? category.meta_keywords : "",
  };

  return (
    <Box>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
      </Helmet>

      {categoryLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "700px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {!category || category.id === 1 ? null : (
            <BreadcrumbsCustom breadcrumbs={breadcrumbs} hasend={true} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "89%",
                direction: theme.lang.direction,
              }}
            >
              <Typography
                sx={{
                  padding: "1rem 0",
                  fontSize: "1.5rem",
                }}
              >
                {category ? category.name : t("offers")}
              </Typography>
              <Grid
                container
                spacing={{ xs: 3, md: 7 }}
                sx={{ direction: theme.lang.direction }}
              >
                <Grid item xs={12} sm={8} md={3} lg={2.5}>
                  {category && category.filterable_attributes &&
                    category.filterable_attributes.map((filterItem, index) => {
                      return (
                        <Box key={"filter-" + index}>
                          {filterItem.type === "price" ? (
                            <Box>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {t("price")}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  "& .MuiSlider-thumb": {
                                    width: "15px",
                                    height: "15px",
                                  },
                                }}
                              >
                                <Slider
                                  min={
                                    category.min_price ? category.min_price : 0
                                  }
                                  max={
                                    category.max_price
                                      ? category.max_price
                                      : 100000
                                  }
                                  value={minMaxValue}
                                  onChange={handleChange}
                                  disableSwap
                                  sx={{
                                    width: "90%",
                                  }}
                                  step={10}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: 1,
                                  direction: "ltr",
                                }}
                              >
                                <FormControl
                                  sx={{ margin: "0.5rem", width: "50%" }}
                                >
                                  <InputLabel
                                    sx={{
                                      fontSize: "0.9rem",
                                      "&.Mui-focused": {
                                        color: "primary.main",
                                      },
                                    }}
                                  >
                                    {t("from_currency")}
                                  </InputLabel>
                                  <OutlinedInput
                                    type={"text"}
                                    value={minMaxValue[0]}
                                    onChange={(e) => {
                                      handleChange(null, [
                                        parseInt(e.target.value)
                                          ? parseInt(e.target.value)
                                          : 0,
                                        minMaxValue[1],
                                      ]);
                                    }}
                                    label={t("from_currency")}
                                    inputProps={{}}
                                    sx={{
                                      fontSize: "0.9rem",
                                      ".MuiInputBase-input": {
                                        padding: "0.5rem 0.5rem",
                                      },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "primary.main",
                                        },
                                    }}
                                  />
                                </FormControl>
                                <FormControl
                                  sx={{ margin: "0.5rem", width: "50%" }}
                                >
                                  <InputLabel
                                    sx={{
                                      fontSize: "0.9rem",
                                      "&.Mui-focused": {
                                        color: "primary.main",
                                      },
                                    }}
                                  >
                                    {t("to_currency")}
                                  </InputLabel>
                                  <OutlinedInput
                                    type={"text"}
                                    value={minMaxValue[1]}
                                    onChange={(e) => {
                                      handleChange(null, [
                                        minMaxValue[0],
                                        parseInt(e.target.value)
                                          ? parseInt(e.target.value)
                                          : 0,
                                      ]);
                                    }}
                                    label={t("to_currency")}
                                    inputProps={{}}
                                    sx={{
                                      fontSize: "0.9rem",
                                      ".MuiInputBase-input": {
                                        padding: "0.5rem 0.5rem",
                                      },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "primary.main",
                                        },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <Divider
                                sx={{ borderColor: "rgba(0,0,0,0.9)" }}
                              />
                            </Box>
                          ) : (
                            <Box>
                              <Accordion
                                elevation={0}
                                dir={theme.lang.direction}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  sx={{
                                    pr: 0,
                                    pl: 0,

                                    "&.Mui-expanded": {
                                      minHeight: 60,
                                      maxHeight: 60,
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      m: 0,
                                    }}
                                  >
                                    {filterItem.name ? filterItem.name : "-"}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pt: 0 }}>
                                  <FormControl
                                    component="fieldset"
                                    variant="standard"
                                    sx={{
                                      maxHeight: "400px",
                                      overflow: "auto",
                                      width: "100%",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <FormGroup>
                                      {filterItem.options.map(
                                        (item, subIndex) => {
                                          return (
                                            <FormControlLabel
                                              key={
                                                "item-" + index + "-" + subIndex
                                              }
                            
                                              control={
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    mr: 1,
                                                    ml: 1,
                                                  }}
                                                >
                                                  <Checkbox
                                                    checked={
                                                      item.checked
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      handleCheckboxChange(
                                                        e,
                                                        index,
                                                        subIndex
                                                      );
                                                    }}
                                                  />
                                                  {item.swatch_value ? (
                                                    <img
                                                      src={item.swatch_value}
                                                      alt="..."
                                                      width="15px"
                                                      loading="lazy"
                                                    />
                                                  ) : null}
                                                </Box>
                                              }
                                              label={item.label}
                                            />
                                          );
                                        }
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </AccordionDetails>
                              </Accordion>
                              <Divider
                                sx={{ borderColor: "rgba(0,0,0,0.9)" }}
                              />
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  {category && category.filterable_attributes &&
                  category.filterable_attributes.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        sx={{
                          width: { xs: "100%", md: "60%" },
                          borderRadius: "10px",
                          color: "primary.light",
                          backgroundColor: "primary.main",
                          textTransform: "capitalize",
                          mt: 2,
                          ":hover": {
                            backgroundColor: "primary.border",
                          },
                        }}
                        onClick={() => {
                          filterProducts();
                        }}
                      >
                        {t("confirm")}
                      </Button>
                      <Button
                        sx={{
                          width: { xs: "100%", md: "30%" },
                          borderRadius: "10px",
                          color: "primary.light",
                          backgroundColor: "primary.border",
                          textTransform: "capitalize",
                          mt: 2,
                          ":hover": {
                            backgroundColor: "primary.borderDarker",
                          },
                        }}
                        onClick={() => {
                          let search = searchParams.get("search");
                          let params =
                            "?categoryId=" + searchParams.get("categoryId");
                          if (search) {
                            params += "&search=" + search;
                          }
                          params += "&page=" + searchParams.get("page");
                          navigate(window.location.pathname + params);
                        }}
                      >
                        {t("reset")}
                      </Button>
                    </Box>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={9} lg={9.5}>
                  {productsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          p: 3,
                          backgroundColor: "primary.bgLight",
                          direction: theme.lang.direction,
                        }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={"100%"}
                          animation="wave"
                        />
                      </Box>

                      {buildProductsGrid(skeletonSize)}
                    </Box>
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          p: 2,
                          backgroundColor: "primary.bgLight",
                          direction: theme.lang.direction,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            color: "primary.gray",
                          }}
                        >
                          {metaData.total} {t("results")}
                        </Typography>
                      </Box>

                      {buildProductsGrid(products)}
                    </Box>
                  )}
                  <Grid item xs={12}>
                    <PaginationCustom totalPages={metaData.last_page} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
