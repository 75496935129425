import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const drawerWidth = 350;

function ResponsiveDrawer(props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const drawer = (
    <Box
      sx={{
        direction: theme.lang.direction,
      }}
    >
      <Typography
        sx={{
          p: "1rem 1rem",
          color: "primary.light",
          backgroundColor: "primary.main",
        }}
      >
        {t("all_categories")}
      </Typography>
      <Divider />
      <List sx={{ p: "1rem 1rem" }} 
      onClick={(e)=>{
        if(e.target.tagName === "A"){
          props.handleDrawerToggle()
        }
      }}
      >{props.dataList}</List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ //width: { lg: drawerWidth }, 
      //  flexShrink: { lg: 0 }
       }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          anchor={theme.lang.lang === "ar" ? "right" : "left"}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", 
          //  lg: "none",
            lg: "block",
           },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: {xs:"250px", md: drawerWidth},
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
