import { EmojiEvents, Info } from "@mui/icons-material";
import { Box, Divider, Link, Typography, useTheme } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { calculatePercentage, getBrand, getRegularPrice } from "../Functions";
import { ProfileContext } from "../../../context/ProfileContext";

export const ProductInfo = (props) => {
  const product = props.product;
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useContext(ProfileContext);

  const brand = getBrand(product.attributes);
  const regularPrice = getRegularPrice(product);

  return (
    <>
      {brand && (
        <Box>
          {brand.typeImg ? (
            <img src={brand.value} alt="..." width={"50px"} loading="lazy" />
          ) : (
            <Typography
              sx={{
                fontSize: "0.9rem",
                color: "primary.gray",
              }}
            >
              {brand.value}
            </Typography>
          )}
        </Box>
      )}
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "1.5rem",
        }}
      >
        {product.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          mb: 3,
          mt: 1,
        }}
      >
        <Typography
          sx={{
            color: "primary.gray",
            fontSize: "0.9rem",
            mr: theme.lang.lang === "en" ? 2 : 0,
            ml: theme.lang.lang === "en" ? 0 : 2,
          }}
        >
          {t("product_model")} : {product.product_number}
        </Typography>
        <Typography
          sx={{
            color: "primary.gray",
            fontSize: "0.9rem",
          }}
        >
          {t("sku")} : {product.sku}
        </Typography>
      </Box>
      {profile.connected && product.promotion_message ? (
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              padding: "0.3rem 0.5rem",
              textAlign: theme.lang.textAlign,
              border: "2px dashed",
              borderColor: "primary.main",
              color: "primary.green",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {product.promotion_message}
            <Link
              href="/products?categoryId=offers&page=1"
              sx={{
                textDecoration: "none",
              }}
            >
              {t("all_offers")}
            </Link>
          </Typography>
        </Box>
      ) : null}
      {profile.connected ? (
        <Box
          sx={{
            display: "flex",
            mb: 1.5,
            mt: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              fontSize: "1.5rem",
              mr: theme.lang.lang === "en" ? 2 : 0,
              ml: theme.lang.lang === "en" ? 0 : 2,
            }}
          >
            {product.formated_price}
            <Typography
              component={"span"}
              sx={{
                fontSize: "inherit",
                fontWeight: 400,
              }}
            >
              {" " + t("egp")}
            </Typography>
          </Typography>

          {regularPrice ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  textDecoration: "line-through",
                  color: "primary.gray",
                  fontSize: "0.9rem",
                }}
              >
                {regularPrice}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "inherit",
                    fontWeight: 400,
                  }}
                >
                  {" " + t("egp")}
                </Typography>
              </Typography>

              <Typography
                sx={{
                  color: "primary.green",
                  border: "2px solid",
                  borderColor: "primary.green",
                  borderRadius: "20px",
                  width: "fit-content",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  p: 0.2,
                  pr: 1,
                  pl: 1,
                  m: 1,
                  mt: 0,
                  mb: 0,
                }}
              >
                {calculatePercentage(regularPrice, product.formated_price)}%{" "}
                {t("off")}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {profile.connected && product.type === "bundle" ? (
        <Typography
          sx={{
            color: "#45a43f",
            fontWeight: "bolder",
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#e7f3e7",
            width: "fit-content",
            mt: 1,
            mb: 2,
            p: "0 0.5rem",
            borderRadius: "7px",
          }}
        >
          <Info />
          <Typography
            component={"span"}
            sx={{
              m: 1,
              fontWeight: "inherit",
              fontSize: "inherit",
            }}
          >
            {t("save")} {product.formated_saved_amount} {t("egp")}
          </Typography>
        </Typography>
      ) : null}
      {profile.connected ? (
        <Typography
          sx={{
            color: "primary.light",
            backgroundColor: "primary.main",
            width: "fit-content",
            fontSize: "0.7rem",
            p: 0.5,
            pr: 0.5,
            pl: 0.5,
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            mb: 2,
            mt: 3,
          }}
        >
          <EmojiEvents sx={{ mr: 0.2, ml: 0.2, fontSize: "1.1rem" }} />
          {t("get")} {product.reward_points} {t("points")}
        </Typography>
      ) : null}
      <Divider sx={{ borderColor: "rgba(0,0,0,0.7)", mb: 2 }} />
      <Box
        sx={{
          borderLeft: theme.lang.lang === "en" ? "2px solid #000" : "none",
          borderRight: theme.lang.lang === "ar" ? "2px solid" : "none",
          borderColor: "primary.main",
          pl: theme.lang.lang === "en" ? 1 : 0,
          pr: theme.lang.lang === "ar" ? 1 : 0,
        }}
      >
        <Box
          sx={{
            textAlign: "justify",
            direction: theme.lang.direction,
            width: "100%",
          }}
          component={"span"}
          dangerouslySetInnerHTML={{
            __html: product.description,
          }}
        ></Box>
      </Box>
    </>
  );
};
