import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Link, useTheme } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import { getSliderLink } from "./Functions";

export const TopSlider = (props) => {
  const theme = useTheme();

  const getSrcImg = (slider) => {
    if(window.innerWidth < 700){
      return slider.image_url
    }
    return slider.front_image_url
  }
  

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={false}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
      
    >
      {props.topSliders &&
        props.topSliders.map((slider) => {
          return (
            <SwiperSlide key={slider.id} style={{backgroundColor:"transparent"}}>
              <Box
                sx={{
              width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  direction: theme.lang.direction,// marginBottom:"2rem"
                  
                }}
              >
                {slider.clickable ? (
                  <Link
                    href={getSliderLink(slider)}
                    sx={{
                      width:"auto",
                    }}
                  >
                    <img src={getSrcImg(slider)} alt="..." width="100%" loading="lazy"/>
                  </Link>
                ) : (
                  <Box sx={{
                    width:"auto"
                  }}>
                    <img src={getSrcImg(slider)} alt="..." width="100%" loading="lazy"/>
                    </Box>
                )}
              </Box>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
