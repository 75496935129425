import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRewardPointsAvailable, getRewardPointsExpired } from "./Functions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import PaginationCustom from "../../../ui-common-components/PaginationCustom";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuery from "../../../common-functions/QuerySearch";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const RewardPointsDetails = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState([]);
  const [expired, setExpired] = useState([]);

  const navigate = useNavigate();
  let query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const [availableMetaData, setAvailableMetaData] = useState({ last_page: 1 });
  const [expiredMetaData, setExpiredMetaData] = useState({ last_page: 1 });
  const [metaData, setMetaData] = useState(availableMetaData);

  const fetchRewardPointsAvailable = async (page) => {
    try {
      setLoading(true);
      const data = await getRewardPointsAvailable(page);
      setAvailable(data.data);
      setAvailableMetaData(data.meta);
      setLoading(false);
    } catch (error) {
      setAvailable([]);
    }
  };

  const fetchRewardPointsExpired = async (page) => {
    try {
      setLoading(true);
      const data = await getRewardPointsExpired(page);
      setExpired(data.data);
      setExpiredMetaData(data.meta);
      setLoading(false);
    } catch (error) {
      setExpired([]);
    }
  };

  useEffect(() => {
    const page = searchParams.get("page");
    if (page) {
      fetchRewardPointsAvailable(page);
      fetchRewardPointsExpired(page);
    }
  }, [searchParams]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const page = parseInt(query.get("page"));

    navigate(
      window.location.pathname +
        window.location.search.replace("page=" + page, "page=1")
    );

    if (parseInt(newValue) === 1) {
      setMetaData(expiredMetaData);
    } else {
      setMetaData(availableMetaData);
    }
  };
  const buildItems = (items, activeFlag) => {
    return (
      <Box
        sx={{
          minHeight: "50vh",
        }}
      >
        {items.map((item, index) => {
          return (
            <Box
              key={"item-" + index}
              sx={{
                backgroundColor: "primary.light",
                p: 2,
                borderRadius: "7px",
                mb: index === items.length - 1 ? 0 : 2,
              }}
            >
              <Typography fontWeight="bold" sx={{ color: "primary.main" }}>
                {t("expire_date")}:{" "}
                <Typography
                  component={"span"}
                  fontWeight="bold"
                  sx={{ color: "primary.dark" }}
                >
                  {item.exp_date ? item.exp_date : t("no_expire_date")}
                </Typography>
              </Typography>
              {activeFlag ? (
                <Typography fontWeight="bold" sx={{ color: "primary.main" }}>
                  {t("points_available_to_use")}:{" "}
                  <Typography
                    component={"span"}
                    fontWeight="bold"
                    sx={{ color: "primary.green" }}
                  >
                    {item.reward_points} {t("reward_points")}
                  </Typography>
                </Typography>
              ) : (
                <Typography fontWeight="bold" sx={{ color: "primary.main" }}>
                  {t("points_expired")}:{" "}
                  <Typography
                    component={"span"}
                    fontWeight="bold"
                    sx={{ color: "primary.red" }}
                  >
                    {item.reward_points} {t("reward_points")}
                  </Typography>
                </Typography>
              )}
            </Box>
          );
        })}
        {items.length === 0 ? (
          <Box>
            <Typography
              sx={{
                color: "primary.main",
                textAlign: "center",
              }}
            >
              {t("no_data_found")}
            </Typography>
          </Box>
        ) : null}
      </Box>
    );
  };
  const tabs = [
    {
      label: t("available"),
      page: buildItems(available, true),
    },
    {
      label: t("expired"),
      page: buildItems(expired, false),
    },
  ];
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontSize: "1.7rem",
          fontWeight: "bold",
          textAlign: "center",
          color: "primary.gray",
          mb: 2,
        }}
      >
        {t("points_details")}
      </Typography>

      <Box
        sx={{
          backgroundColor: "primary.light",
          p: "1rem",
          borderRadius: "7px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {tabs.map((tab, index) => {
                return (
                  <Tab
                    {...a11yProps(index)}
                    key={"tab-" + index}
                    label={tab.label}
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
          {loading ? (
            <Box
              sx={{
                minHeight: "50vh",
              }}
            >
              {/*<Skeleton
              variant="rectangular"
              width={"100%"}
              height={"10vh"}
              sx={{ borderRadius: "7px", mb: "1rem", mt: 2 }}
          />*/}

              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"50vh"}
                sx={{ borderRadius: "7px", mb: "1rem" }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                p: 2,
                backgroundColor: "primary.mainLight",
                borderRadius: "7px",
              }}
            >
              {tabs.map((tab, index) => {
                return (
                  <TabPanel
                    key={"tab-panel-" + index}
                    value={value}
                    index={index}
                  >
                    {tab.page}
                  </TabPanel>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
      {metaData && <PaginationCustom totalPages={metaData.last_page} />}
    </Box>
  );
};
