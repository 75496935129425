import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDate } from "../orders/Functions";

const statusColors = {
  approved: "primary.green",
  canceled: "primary.red",
  expired: "primary.red",
  pending: "primary.neonCarrot",
};
export const AccumulationsAndTransactions = (props) => {
  const { t } = useTranslation();

  const data = props.data ? props.data : [];
  const accumulationsFlag = props.accumulationsflag;

  return (
    <Box>
      {data &&
        data.map((item, index) => {
          return (
            <Box
              key={"item-" + index}
              sx={{
                backgroundColor: "primary.light",
                p: 2,
                borderRadius: "7px",
                mb: index === data.length - 1 ? 0 : 2,
              }}
            >
              <Grid container>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "primary.gray",
                      }}
                    >
                      {t("order")}{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: "primary.main",
                          fontWeight: "bold",
                        }}
                      >
                        #{
                        accumulationsFlag ?
                        (item.order_item_id ? item.order_item_id : 0)
                            :
                            (item.order_id ? item.order_id : 0)
                    }
                      </Typography>{" "}
                    </Typography>
                    <Typography>
                      {t("points")}
                      {": "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: accumulationsFlag ? "primary.green" : "primary.red",
                          fontWeight: "bold",
                        }}
                      >
                        {item.reward_points} {t("reward_points")}
                      </Typography>
                    </Typography>
                    {accumulationsFlag ? (
                      <Typography>
                        {t("status")}
                        {": "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: statusColors[item.status],
                            fontWeight: "bold",
                          }}
                        >
                          {t(item.status)}
                        </Typography>
                      </Typography>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    textAlign={"end"}
                    fontWeight="bold"
                    sx={{ color: "primary.gray" }}
                  >
                    {getDate(item.created_at)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      {data && data.length === 0 ? (
        <Box>
          <Typography
            sx={{
              color: "primary.main",
              textAlign: "center",
            }}
          >
            {t("no_data_found")}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};
