export const breadcrumbRec = (breadcrumbsIn, data, homeLabel) => {
    if (data) {
      breadcrumbsIn.push({
        href: "/products/"+data.slug+"?categoryId=" + data.id + "&page=1",
        name: data.name,
      });
      if (data.parent) {
        return breadcrumbRec(breadcrumbsIn, data.parent, homeLabel);
      } else {
        breadcrumbsIn.push({
          href: "/",
          name: homeLabel,
        });
      }
    }
    else{
      breadcrumbsIn.push({
        href: "/",
        name: homeLabel,
      });
    }
  
    
    return breadcrumbsIn;
  };
  