export const BASE_URL = process.env.REACT_APP_BASE_URL
/********************************User*****************************************/
export const USER_LOGIN = "./api/customer/login"
export const USER_LOGOUT = "./api/customer/logout"
export const USER_PROFILE = "./api/customer/get"
export const REFRESH_TOKEN = "./api/customer/refresh"
export const USER_SIGNUP = "./api/customer/register"
export const SUBMIT_PHONE = "./api/phone-number"
export const SUBMIT_OTP = "./api/otp/submit"
export const RESEND_OTP = "./api/otp/resend"
export const GET_CITIES = "./api/countries/1/states"
export const GET_DISTRICTS = "./api/states/{id}/zones"

export const RESTORE_ACCOUNT = "./api/customer/restore"

export const RESET_SUBMIT_PHONE = "./api/customer/forgot-password"
export const RESET_SUBMIT_OTP = "./api/customer/submit-reset"
export const RESET_RESEND_OTP = "./api/customer/resend-reset-code"
export const RESET_PASSWORD = "./api/customer/reset-password"


export const GET_BANKS = "./api/vendors/payment-methods/banks"
export const GET_EWALLETS = "./api/vendors/payment-methods/e-wallets"
export const VENDOR_SIGNUP = "./api/vendors/register"


export const GET_NOTIFICATIONS = "./api/customer-notifications"


/********************************Home*****************************************/
export const GET_ALL_SLIDERS = "./api/front/sliders"
export const GET_FLASH_SALES = "./api/V2/flash-sales"

/********************************Category***************************************/
export const GET_CATEGORIES_TREE = "./api/categories-tree"
export const CATEGORY = "./api/categories"

/********************************Product***************************************/
export const PRODUCTS = "./api/V2/products"
export const RELATED_PRODUCTS = "./related-products"
export const UP_SELLING = "./up-sells"
export const BESTSELLERS = "./api/best-sellers"
export const RECENTLY_BOUGHT = "./api/recently-bought"

/********************************Cart******************************************/
export const ADD_PRODUCT_TO_CART = "./api/V2/checkout/cart/add"
export const REMOVE_PRODUCT_FROM_CART = "./api/V2/checkout/cart/remove-item"
export const UPDATE_CART = "./api/V2/checkout/cart/update"
export const GET_CART_SUMMARY = "./api/V2/checkout/cart-summary"
export const GET_CART_DETAILS = "./api/V2/checkout/cart"
export const SAVE_CART = "./api/V2/checkout/cart/save"
export const PROMO_CODE = "./api/checkout/cart/coupon"

/********************************Orders***************************************/
export const PLACE_ORDER = "./api/V2/checkout/place-order"
export const ORDERS = "./api/V2/orders"
export const CANCEL_ORDER = "./api/orders"
export const GET_ORDER_BY_ID = "./api/orders"
export const REORDER = "./api/reorder-order"

/********************************Reward points********************************/
export const REWARD_POINTS_SUMMARY = "/api/customer/reward-points"
export const REWARD_POINTS_ACCUMULATIONS = "/api/reward-points/accumulations"
export const REWARD_POINTS_TRANSACTIONS = "/api/reward-points/transactions"
export const REWARD_POINTS_AVAILABLE = "/api/reward-points/active"
export const REWARD_POINTS_EXPIRED = "/api/reward-points/expired"

/********************************Footer***************************************/
export const ABOUT_US = "./api/pages/about-us"
export const TERMS_AND_CONDITIONS = "./api/pages/terms-conditions"
export const PRIVACY_POLICY = "./api/pages/privacy-policy"