import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import { BrowserRouter } from "react-router-dom";

import ProfileContextProvider from "./context/ProfileContext";
import MainRoutes from "./routes/MainRoutes";
import './i18n';


const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <ProfileContextProvider>
          <MainRoutes />
        </ProfileContextProvider>
      </Suspense>
    </BrowserRouter>
);
