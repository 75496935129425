import { Box, Grid, Link, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFlashSales } from "./Functions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper";
import Countdown from "react-countdown";
import { ProfileContext } from "../../context/ProfileContext";
import { getBrand, getRegularPrice } from "../products/Functions";

export const FlashSales = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);

  const [flashSale, setFlashSale] = useState();
  const [loading, setLoading] = useState(true);

  const fetchFlashSales = async () => {
    setLoading(true);
    try {
      if(profile.connected){
        const data = await getFlashSales();
        if (!data || data.length === 0) {
          props.setFlashSalesExist(false);
        } else {
          setFlashSale(data[0]);
          setLoading(false);
        }
      }
      else{
        props.setFlashSalesExist(false);
        setLoading(false);
      }
    } catch (error) {
      props.setFlashSalesExist(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFlashSales();
  }, []); // eslint-disable-line

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const bgColor = "#E6E6E6";
    const numberStyle = {
      color: "#383838",
      fontSize: "1.8rem",
      backgroundColor: bgColor,
      p: "0rem 0.5rem",
      borderRadius: "3px",
    };
    const labelStyle = {
      textTransform: "uppercase",
      fontSize: "1rem",
    };

    const formatted = [days, hours, minutes];
    formatted.forEach((item, index) => {
      formatted[index] = item.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    });
    return (
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={numberStyle}>{formatted[0]}</Typography>
          <Typography sx={labelStyle}>{t("days")}</Typography>
        </Box>
        <Typography
          sx={{
            margin: "0 3px",
            fontSize: "1.8rem",
          }}
        >
          :
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={numberStyle}>{formatted[1]}</Typography>
          <Typography sx={labelStyle}>{t("hours")}</Typography>
        </Box>
        <Typography
          sx={{
            margin: "0 3px",
            fontSize: "1.8rem",
          }}
        >
          :
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={numberStyle}>
            {(parseInt(formatted[2]) + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
          <Typography sx={labelStyle}>{t("minutes")}</Typography>
        </Box>
      </Box>
    );
  };

  const loadingPlaceHolder = (
    <>
      <Skeleton
        variant="rectangular"
        width={"70%"}
        height={"30px"}
        animation="wave"
        sx={{
          mb: 2,
        }}
      />

      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={"335px"}
        animation="wave"
        sx={{
          mb: 2,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={"35px"}
          animation="wave"
          sx={{
            mt: 1,
            mb: "10px",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={"30%"}
          height={"20px"}
          animation="wave"
          sx={{
            mb: 0.5,
          }}
        />
        <Skeleton
          variant="rectangular"
          width={"25%"}
          height={"20px"}
          animation="wave"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Skeleton
            variant="rectangular"
            width={"50px"}
            height={"50px"}
            animation="wave"
            sx={{
              mt: 2,
              mb: 2,
            }}
          />
          <Typography
            sx={{
              margin: "0 3px",
              fontSize: "1.8rem",
            }}
          >
            :
          </Typography>
          <Skeleton
            variant="rectangular"
            width={"50px"}
            height={"50px"}
            animation="wave"
            sx={{
              mt: 2,
              mb: 2,
            }}
          />
          <Typography
            sx={{
              margin: "0 3px",
              fontSize: "1.8rem",
            }}
          >
            :
          </Typography>
          <Skeleton
            variant="rectangular"
            width={"50px"}
            height={"50px"}
            animation="wave"
            sx={{
              mt: 2,
              mb: 2,
            }}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <Box
        sx={{
          border: "2px solid",
          borderColor: "primary.main",
          borderRadius: "7px",
          p: "1.5rem",
          textAlign: "center",
          direction: theme.lang.direction,
        }}
      >
        {loading ? (
          loadingPlaceHolder
        ) : (
          <>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                textTransform: "capitalize",
                mb: 2,
                textAlign: theme.lang.textAlign,
              }}
            >
              {flashSale.title}
            </Typography>

            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              navigation={false}
              modules={[Pagination]}
              className="mySwiper"
            >
              {flashSale &&
                flashSale.products.map((product, index) => {
                  const regularPrice = getRegularPrice(product);

                  return (
                    <SwiperSlide key={index}>
                      <Link
                        href={
                          "product/" +
                          product.url_key +
                          "/" +
                          product.sku +
                          "?id=" +
                          product.id
                        }
                        underline="none"
                        width="100%"
                      >
                        <Grid
                          container
                          marginBottom={3}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid item xs={12} sm={5.5} lg={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={product.base_image.medium_image_url}
                                alt="..."
                                height="335px"
                                width={"auto"}
                                style={{ maxWidth: "100%" }}
                                loading="lazy"
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={5.5}
                            lg={12}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                textAlign: {
                                  xs: "start",
                                  /* xs: "center",
                                  sm: "start",
                                  lg: "center",*/
                                },
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: { xs: "auto", sm: "50%", lg: "auto" },
                              }}
                            >
                              <Typography
                                sx={{
                                  marginTop: 3,
                                  fontSize: "14px",
                                  color: "black",
                                  textDecoration: "none",
                                  height: "35px",
                                  width: "auto",
                                  whiteSpace: "normal",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "18px",
                                  marginBottom: "10px",
                                  fontWeight: "bold",
                                  textAlign: "start",
                                }}
                              >
                                {product.name}
                              </Typography>
                              <Box>
                                {profile.connected ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      //justifyContent: "space-between",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "primary.main",
                                        fontSize: "1.5rem",
                                        width: "fit-content",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {product.formated_price}
                                      <Typography
                                        component={"span"}
                                        sx={{
                                          fontWeight: 400,
                                          fontSize: "inherit",
                                        }}
                                      >
                                        {" " + t("egp")}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                ) : null}

                                {profile.connected ? (
                                  <Typography
                                    sx={{
                                      color: "primary.gray",
                                      fontSize: "1rem",
                                      textDecoration: "line-through",
                                      textAlign: "center",
                                    }}
                                  >
                                    {regularPrice}
                                    <Typography
                                      component={"span"}
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: "inherit",
                                      }}
                                    >
                                      {regularPrice ? " " + t("egp") : ""}
                                    </Typography>
                                  </Typography>
                                ) : null}

                                {profile.connected ? (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      justifyContent: "end",
                                      display: "flex",
                                    }}
                                  >
                                    {getBrand(product.attributes).typeImg ? (
                                      <img
                                        src={getBrand(product.attributes).value}
                                        alt="..."
                                        width={"50px"}
                                        loading="lazy"
                                      />
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "primary.gray",
                                        }}
                                      >
                                        {getBrand(product.attributes).value}
                                      </Typography>
                                    )}
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                  lg: "none",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#383838",
                                  fontSize: "0.9rem",
                                  mt: 1,
                                }}
                              >
                                {t("hurry_up_flash_sale")}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  mt: 1,
                                }}
                              >
                                <Countdown
                                  date={
                                    new Date(
                                      new Date(flashSale.to_date).setHours(0)
                                    )
                                  }
                                  renderer={renderer}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Link>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <Box sx={{ display: { xs: "block", sm: "none", lg: "block" } }}>
              <Typography
                sx={{
                  color: "#383838",
                  fontSize: "0.9rem",
                  mt: 1,
                }}
              >
                {t("hurry_up_flash_sale")}
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <Countdown
                  date={new Date(new Date(flashSale.to_date).setHours(0))}
                  renderer={renderer}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
