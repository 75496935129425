import { Box, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { Notifications } from "./Notifications";
import { Orders } from "./orders/Orders";
import { AccountDetails } from "./AccountDetails";
import { Addresses } from "./Addresses";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { PersonOutlined, Widgets } from "@mui/icons-material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { RewardPoints } from "./reward-points/RewardPoints";
import RedeemIcon from "@mui/icons-material/Redeem";
import { RewardPointsDetails } from "./reward-points/RewardPointsDetails";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ minHeight: "100%" }}
    >
      {value === index && (
        <Box
          sx={{
            p: { xs: "1rem", md: "1rem" },
            backgroundColor: "primary.mainLight",
            minHeight: "50vh",
            borderRadius: "7px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export const Profile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const [orientation, setOrientation] = useState("vertical");
  useEffect(() => {
    setOrientation(window.innerWidth >= 900 ? "vertical" : "horizontal");
    window.addEventListener("resize", () => {
      setOrientation(window.innerWidth >= 900 ? "vertical" : "horizontal");
    });
  }, []); // eslint-disable-line

  const tabs = [
    {
      label: t("my_details"),
      icon: <PersonOutlined />,
      page: <AccountDetails />,
      href: "/profile/details",
      hrefPath: "/profile/details",
    },
    {
      label: t("reward_points_title"),
      icon: <RedeemIcon />,
      page: <RewardPoints />,
      href: "/profile/reward-points?page=1",
      hrefPath: "/profile/reward-points",
    },
    {
      label: t("notifications"),
      icon: <NotificationsActiveIcon />,
      page: <Notifications />,
      href: "/profile/notifications",
      hrefPath: "/profile/notifications",
    },
    {
      label: t("orders"),
      icon: <Widgets />,
      page: <Orders />,
      href: "/profile/orders?page=1",
      hrefPath: "/profile/orders",
    },
    {
      label: t("address"),
      icon: <PinDropIcon />,
      page: <Addresses />,
      href: "/profile/address",
      hrefPath: "/profile/address",
    },
  ];

  const useCurrentRoute = useLocation();

  useEffect(() => {
    const tabIndex = tabs.findIndex(
      (tab) => tab.hrefPath === useCurrentRoute.pathname
    );
    if (tabIndex !== -1) {
      setValue(tabIndex);
    } else {
      if (useCurrentRoute.pathname === "/profile/reward-points-details") {
        setValue(tabs.length);
      } else {
        navigate("/not-found");
      }
    }
  }, [useCurrentRoute]); // eslint-disable-line

  return (
    <Grid
      container
      justifyContent={"center"}
      marginTop={3}
      sx={{ direction: theme.lang.direction }}
    >
      <Grid
        item
        xs={12}
        md={2.1}
        lg={2}
        sx={{
          height: { xs: "auto", md: "100%" },
        }}
      >
        <Tabs
          orientation={orientation}
          variant="scrollable"
          value={value}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{
            height: "100%",
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={"tab-" + index}
                label={tab.label}
                icon={tab.icon}
                iconPosition="start"
                href={tab.href}
                {...a11yProps(index)}
                sx={{
                  alignItems: "center",
                  direction: theme.lang.direction,
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  justifyContent: "flex-start",
                  minHeight: "auto",
                  fontSize: "1rem",
                  m: "0.5rem 0",
                  p: "0.5rem 1rem",
                  ":hover": {
                    color: "primary.main",
                  },
                  ".MuiTab-iconWrapper": {
                    mr: 1,
                    ml: 1,
                  },
                }}
              />
            );
          })}
          <Tab
            {...a11yProps(tabs.length)}
            sx={{
              display: "none",
            }}
          />
        </Tabs>
      </Grid>
      <Grid
        item
        xs={12}
        md={9.5}
        lg={9}
        sx={{ minHeight: "100%", padding: "0 1rem" }}
      >
        {tabs.map((tab, index) => {
          return (
            <TabPanel key={"tab-panel-" + index} value={value} index={index}>
              {tab.page}
            </TabPanel>
          );
        })}
        <TabPanel
          key={"tab-panel-" + tabs.length}
          value={value}
          index={tabs.length}
        >
          <RewardPointsDetails />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
