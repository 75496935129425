import { Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { AccumulationsAndTransactions } from "./AccumulationsAndTransactions";
import {
  getRewardPointsAccumulations,
  getRewardPointsSummary,
  getRewardPointsTransactions,
} from "./Functions";
import PaginationCustom from "../../../ui-common-components/PaginationCustom";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuery from "../../../common-functions/QuerySearch";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const RewardPoints = () => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  let query = useQuery();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const page = parseInt(query.get("page"));

    navigate(
      window.location.pathname +
        window.location.search.replace("page=" + page, "page=1")
    );

    if(parseInt(newValue) === 1){
      setMetaData(transactionsMetaData)
    }
    else{
      setMetaData(accumulationsMetaData)
    }
  };

  const [balancePoints, setBalancePoints] = useState(0);
  const [balance, setBalance] = useState(0);
  const [accumulations, setAccumulations] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loadingTabs, setLoadingTabs] = useState(true);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const [accumulationsMetaData, setAccumulationsMetaData] = useState({last_page: 1});
  const [transactionsMetaData, setTransactionsMetaData] = useState({last_page: 1});
  const [metaData, setMetaData] = useState(accumulationsMetaData);

  const fetchRewardPointsSummary = async (page) => {
    try {
      setLoadingSummary(true);
      const data = await getRewardPointsSummary(page);
      setBalancePoints(data.reward_points);
      setBalance(data.reward_points_value);
      setLoadingSummary(false);
    } catch (error) {
      setBalancePoints(0);
      setBalance(0);
    }
  };
  const fetchRewardPointsAccumulations = async (page) => {
    try {
      setLoadingTabs(true);
      const data = await getRewardPointsAccumulations(page);
      console.log(data)
      setAccumulations(data.data);
      setAccumulationsMetaData(data.meta);

      setLoadingTabs(false);
    } catch (error) {
      setAccumulations([]);
    }
  };
  const fetchRewardPointsTransactions = async (page) => {
    try {
      const data = await getRewardPointsTransactions(page);
      setTransactions(data.data);
      setTransactionsMetaData(data.meta);

    } catch (error) {
      setTransactions([]);
    }
  };

  useEffect(() => {
    fetchRewardPointsSummary();
  }, []);

  useEffect(() => {
    const page = searchParams.get("page");
    if(page){
      fetchRewardPointsAccumulations(page);
      fetchRewardPointsTransactions(page);
    }

  }, [searchParams]);

  const tabs = [
    {
      label: t("accumulations"),
      page: (
        <AccumulationsAndTransactions
          data={accumulations}
          accumulationsflag={true}
        />
      ),
    },
    {
      label: t("transactions"),
      page: (
        <AccumulationsAndTransactions
          data={transactions}
          accumulationsflag={false}
        />
      ),
    },
  ];

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontSize: "1.7rem",
          fontWeight: "bold",
          textAlign: "center",
          color: "primary.gray",
          mb: 2,
        }}
      >
        {t("points")}
      </Typography>
      <Box
        sx={{
          backgroundColor: "primary.light",
          p: "1rem",
          borderRadius: "7px",
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12}>
            <Box width={"100%"} display="flex" justifyContent="center">
              {loadingSummary ? (
                <Skeleton variant="circular" width={"150px"} height={"150px"} />
              ) : (
                <Box
                  sx={{
                    border: "3px solid",
                    borderColor: "primary.main",
                    borderRadius: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.main",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <Typography sx={{ fontWeight: "700" }}>
                    {t("balance")}
                  </Typography>
                  <Typography sx={{ fontWeight: "700" }}>
                    {balancePoints} {t("reward_points")}
                  </Typography>
                  <Typography sx={{ fontWeight: "600" }}>=</Typography>
                  <Typography sx={{ fontWeight: "600" }}>
                    {balance.toFixed(2)} {t("egp")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                width: "fit-content",
              }}
            >
              <Link
                href="/profile/reward-points-details"
                sx={{
                  color: "primary.main",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                <Typography>{t("click_for_more")}</Typography>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12}>
            
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2,display:"flex", justifyContent:"center" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {tabs.map((tab, index) => {
                      return (
                        <Tab
                          {...a11yProps(index)}
                          key={"tab-" + index}
                          label={tab.label}
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            ":hover": {
                              color: "primary.main",
                            },
                          }}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
                {loadingTabs ? (
              <Box
                sx={{
                  minHeight: "50vh",
                }}
              >
                {/*<Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"10vh"}
                  sx={{ borderRadius: "7px", mb: "1rem", mt: 2 }}
              />*/}

                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"50vh"}
                  sx={{ borderRadius: "7px", mb: "1rem" }}
                />
              </Box>
            ) : (
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: "primary.mainLight",
                    borderRadius: "7px",
                  }}
                >
                  {tabs.map((tab, index) => {
                    return (
                      <TabPanel
                        key={"tab-panel-" + index}
                        value={value}
                        index={index}
                      >
                        {tab.page}
                      </TabPanel>
                    );
                  })}
                </Box>
            )}
              </Box>
          </Grid>

          <Grid item xs={12}>
            {
              metaData &&
              <PaginationCustom totalPages={metaData.last_page} />
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
