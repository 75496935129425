import { axiosCustom } from "../../../endpoints/Axios";
import { CANCEL_ORDER, GET_ORDER_BY_ID, REORDER } from "../../../endpoints/Constants";

export const getDate = (date) => {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

const statusColor = {
    ordered: "#F8A44C",
    confirmed: "#53B175",
    cancelled: "#d32f2f",
    delivered: "#53B175",
    partially_delivered: "#53B175",
    in_preparation: "#53B175",
}
const paymentColor ={
    not_paid: "primary.main",
    paid: "primary.main",
    partially_paid: "primary.main",
    partially_refunded: "primary.main",
    refunded: "primary.main",
}

export const getStatusColor = (status) => {
    return statusColor[status] || "primary.dark";
};

export const getPaymentColor = (payment) => {
    return paymentColor[payment] || "primary.dark";
};


export const getOrderById = async(id) =>{
    try {
        const response = await axiosCustom.get(
          GET_ORDER_BY_ID + "/" + id
        );
        return response;
      } catch (e) {
        throw e;
      }
}

export const cancelOrderAPI = async(id) =>{
    try {
        const response = await axiosCustom.post(
          CANCEL_ORDER + "/" + id + "/cancel"
        );
        return response;
      } catch (e) {
        throw e;
      }
}

export const reOrderAPI = async(id) =>{
    try {
        const response = await axiosCustom.post(
          REORDER,
          {
            order_id: id
          }
        );
        return response;
      } catch (e) {
        throw e;
      }
}