import React, { useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import Countdown from "react-countdown";
import { axiosCustom } from "../../../endpoints/Axios";
import { RESET_RESEND_OTP, RESET_SUBMIT_OTP } from "../../../endpoints/Constants";

//let oneMinuteAfter = new Date(new Date().getTime() + 1000 * 60)


export const OTPForm = (props) => {
  const [resendCode, setResendCode] = useState(false);
  let oneMinuteAfter = props.oneMinuteAfter

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const formatted = [days, hours, minutes, seconds];
    formatted.forEach((item, index) => {
      formatted[index] = item.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    });
    if (completed) {
      setResendCode(true);
    }
    return (
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography
          sx={{
            color: "primary.main",
          }}
        >
          {parseInt(formatted[2]).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </Typography>
  
        <Typography
          sx={{
            margin: "0 3px",
          }}
        >
          :
        </Typography>
  
        <Typography
          sx={{
            color: "primary.main",
          }}
        >
          {parseInt(formatted[3]).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </Typography>
      </Box>
    );
  };
  const { t } = useTranslation();
  const theme = useTheme();

  const [otpState, setOtpState] = React.useState("");
  const [error, setError] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [loading, setLoading] = useState(false);

  const handleChangeOtp = (newValue) => {
    setOtpState(newValue);
    setError("");
    if (newValue.toString().length === props.OTPLength) {
      setLoading(true)
      axiosCustom
        .post(RESET_SUBMIT_OTP, {
          phone: props.phone,
          code: newValue,
        })
        .then((res) => {
          props.setOTP(newValue)
          props.nextStep();
          setLoading(false)
        })
        .catch((err) => {
          setOtpState("")
          setLoading(false)
          setSeverity("error")
          setError(err.response.data.errors.code[0]);
        });
    }
  };



  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 3000);
  }, [error]);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: 300 }}
    >
      <Typography
        sx={{
          mb: 0,
        }}
      >
        {t("enter_verification_code").replace("#", props.OTPLength)}
      </Typography>
      {error && (
        <Alert
          severity={severity}
          sx={{
            width: "100%",
            margin: "0 1rem",
            display: "flex",
            alignItems: "center",
            direction: theme.lang.direction,
          }}
        >
          <Typography component={"span"} sx={{ mr: 1, ml: 1 }}>
            {error}
          </Typography>
        </Alert>
      )}
      
      <MuiOtpInput
        sx={{
          margin: "0.5rem 1rem",
          mb: 2,
          width:"100%"
        }}
        value={otpState}
        onChange={handleChangeOtp}
        length={props.OTPLength}
        TextFieldsProps={{ disabled: loading, placeholder: "-" }}
      />

      <Grid
        item
        xs={11}
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 4,
        }}
      >
        {!resendCode ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: theme.lang.flexRowDirection,
            }}
          >
            <Typography
              sx={{
                ml: 2,
                mr: 2,
              }}
            >
              {t("request_new_code").replace("#", "")}
            </Typography>
            <Countdown
              date={oneMinuteAfter}
              renderer={renderer}
            />
          </Box>
        ) : (
          <Button
            sx={{
              textTransform: "capitalize",
            }}
            disabled={loading}
            onClick={() => {
              setLoading(true);
              setError("");
              axiosCustom.post(RESET_RESEND_OTP, {
                  phone: props.phone
                }).then((res)=>{
                  setLoading(false)
                  setSeverity("success")
                  setError(t("code_sent"));
                  
                  setResendCode(false)
                })
                .catch((err)=>{
                  setLoading(false)
                  setSeverity("error")
                  setError(err.response.data.errors.code[0]);
                })
            }}
          >
            {t("resend_code")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
