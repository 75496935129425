import { LocalShipping, ShoppingBasket } from "@mui/icons-material";
import { Box, Divider, Link, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosCustom } from "../../../endpoints/Axios";
import { ORDERS } from "../../../endpoints/Constants";
import { getDate, getStatusColor } from "./Functions";
import emartLogo from "../../../assets/logo2.png";
import PaginationCustom from "../../../ui-common-components/PaginationCustom";
import { useSearchParams } from "react-router-dom";

export const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [metaData, setMetaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  useEffect(() => {
    setLoading(true);
    const page = searchParams.get("page");

    axiosCustom
      .get(ORDERS + "?page=" + page + "&limit=5")
      .then((res) => {
        res.data.data = res.data.data.sort(function (a, b) {
          return -(a.cart_id - b.cart_id);
        });
        setOrders(res.data.data);
        setMetaData(res.data.meta);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    }, [searchParams]); // eslint-disable-line

  const buildOrderItem = (item) => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
              color: "primary.gray",
             
            }}
          >
            <ShoppingBasket sx={{ mr: 1, ml: 1 }} />
            {t("order")} #{item.id}
          </Typography>

          <Typography
            sx={{
              color:
                item.status === "cancelled" ? "primary.gray" : "primary.dark",
            }}
          >
            {t("seller")} :{" "}
            {item.vendor_id === 0 ? (
              <img src={emartLogo} alt="..." width="60px" />
            ) : (
              item.vendor_code
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mb: 2,
          }}
        >
          <Typography
            sx={{
              ml: 1,
              mr: 1,
              fontWeight: "bold",
              color:
                item.status === "cancelled" ? "primary.gray" : "primary.dark",
            }}
          >
            {t("quantity")}: {item.total_qty_ordered}
          </Typography>

          <Typography
            sx={{
              fontWeight: "bold",
              color:
                item.status === "cancelled" ? "primary.gray" : "primary.dark",
            }}
          >
            {t("total")}: {item.formated_grand_total}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "start",
            color: getStatusColor(item.status),
            mr: 1,
            ml: 1,
          }}
        >
          {t(item.status)}
        </Typography>
        <Divider sx={{ borderColor: "rgba(0,0,0,0.7)", mt: 2 }} />
        <Typography
          sx={{
            color: "primary.gray",
            fontWeight: "bold",
            textAlign: "end",
            m: 2,
          }}
        >
          <LocalShipping
            sx={{
              ml: 1,
              mr: 1,
              color:
                item.status === "cancelled" ? "primary.gray" : "primary.main",
            }}
          />
          {t("shipping_price") +
            ": " +
            item.formated_shipping_amount +
            " " +
            t("egp")}
        </Typography>
        <Divider sx={{ borderColor: "rgba(0,0,0,0.7)" }} />
      </Box>
    );
  };
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontSize: "1.7rem",
          fontWeight: "bold",
          textAlign: "center",
          color: "primary.gray",
          mb: 2,
        }}
      >
        {t("orders")}
      </Typography>

      {loading ? (
        <Box
          sx={{
            minHeight: "50vh",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"30vh"}
            sx={{ borderRadius: "7px", mb: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"30vh"}
            sx={{ borderRadius: "7px" }}
          />
        </Box>
      ) : (
        <Box>
          {
            orders.length === 0 ? (
              <Typography
                sx={{
                  textAlign: "center",
                  color: "primary.dark",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  mt:2
                }}
              >
                {t("no_orders")}
              </Typography>
            ) : (
              null)
          }
          {orders.map((order, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: "primary.light",
                borderRadius: "7px",
                p: "1rem",
                mb: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                <Typography sx={{ fontWeight: "inherit", fontSize: "inherit" }}>
                  #{order.cart_id}
                </Typography>
                <Typography sx={{ fontWeight: "inherit", fontSize: "inherit" }}>
                  {getDate(order.orders[0].created_at)}
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 2,
                }}
              >
                {order.orders.map((item, index) =>
                  item.status === "cancelled" ? (
                    <Box key={index}>
                    {buildOrderItem(item)}
                    </Box>
                  ) : (
                    <Link
                      key={index}
                      href={"/order?id=" + item.id}
                      underline="none"
                      sx={{
                        color: "primary.dark",
                        ":hover": {
                          color: "primary.dark",
                        },
                      }}
                    >
                      {buildOrderItem(item)}
                    </Link>
                  )
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {
        metaData &&
        <PaginationCustom totalPages={metaData.last_page} />
      }
    </Box>
  );
};
