import { Box, CircularProgress, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedSnackbars from "../../../ui-common-components/SnackBar";
import {
  getProductById,
  getRelatedProducts,
  getUpSellingProducts,
} from "../Functions";
import ProductsSwiper from "../../../ui-common-components/ProductsSwiper";
import { BreadcrumbsCustom } from "../../../ui-common-components/Breadcrumbs";
import { breadcrumbRec } from "../../../common-functions/Functions";
import { ProfileContext } from "../../../context/ProfileContext";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import { DetailsTabs } from "./DetailsTabs";
import { ProductInfo } from "./ProductInfo";
import { VendorsTable } from "./VendorsTable";
import { Helmet } from "react-helmet";
import "./styles.css";

export const ProductDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useContext(ProfileContext);

  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState(""); // eslint-disable-line
  const [snackBarSevirity, setSnackBarSevirity] = useState(""); // eslint-disable-line
  const [product, setProduct] = useState();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [upSellingProducts, setUpSellingProducts] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const buildBreadcrumbs = (data) => {
    let breadcrumbsIn = [];
    breadcrumbsIn = breadcrumbRec(breadcrumbsIn, data, t("home"));
    setBreadcrumbs(breadcrumbsIn.reverse());
  };

  const fetchProduct = async (id) => {
    setLoading(true);
    try {
      const data = await getProductById(id);
      buildBreadcrumbs(data.category);      
      setProduct(data);
      setLoading(false);
    } catch (error) {
      try {
        setSnackBarMessage(error.response.data.message);
      } catch (error) {
        setSnackBarMessage(t("error_occurred"));
      }
      setSnackBarSevirity("error");
      openSnackBar.current();
      setTimeout(() => {
        navigate("/not-found");
      }, [1000]);
    }
  };

  const fetchRelatedProducts = async (id) => {
    try {
      const data = await getRelatedProducts(id);
      setRelatedProducts(data);
    } catch (error) {
      setRelatedProducts([]);
    }
  };

  const fetchUpSellingProducts = async (id) => {
    try {
      const data = await getUpSellingProducts(id);
      setUpSellingProducts(data);
    } catch (error) {
      setUpSellingProducts([]);
    }
  };

  const useCurrentRoute = useLocation();

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) {
      navigate("/");
    } else {
      fetchProduct(id);
      fetchRelatedProducts(id);
      fetchUpSellingProducts(id);
    }
  }, [useCurrentRoute]); // eslint-disable-line

  const meta = {
    title: product ? product.name : "eMart | Product",
    titleMeta: product ? product.meta_title : "eMart | Product",
    description: product ? product.meta_description : "The online marketplace emart-eg.com is Egypt’s online market leader in the fields of IT, consumer electronics and telecommunication. It’s offering consistently low prices as well as fast, reliable and free delivery. We enable a comfortable and efficient shopping experience at consistently low prices. At the same time, we create a comprehensive platform for inspiration, information and communication that invites customers to actively participate",
    keywords: product ? product.meta_keywords : "",
  };

  return (
    <Box>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="title" content={meta.titleMeta} />
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
      </Helmet>

      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "700px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <BreadcrumbsCustom breadcrumbs={breadcrumbs} hasend={true} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt:3
            }}
          >
            <Grid
              container
             // spacing={5}
              sx={{
                width: { xs: "100%", md: "90%" },
                marginTop: { xs: "0", md: "0rem" },
                direction: theme.lang.direction,
                justifyContent:{xs:"center", md:"space-between"}
              }}
            >
              <Grid
                item
                xs={6}
                md={3.5}
                lg={2.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <Grid item xs={8} sm={6} md={12}>
                  <Swiper
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={false}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {product.images &&
                      product.images.map((slider) => {
                        return (
                          <SwiperSlide key={slider.id}>
                            <img
                             // width="100%"
                              id="productDetailsImage"
                              src={slider.large_image_url}
                              alt="..."
                              loading="lazy"
                            />
                          </SwiperSlide>
                        );
                      })}
                    {product.images.length === 0 && (
                      <SwiperSlide>
                        <img
                          width="100%"
                          id="productDetailsImage"
                          src={product.base_image.large_image_url}
                          alt="..."
                          loading="lazy"
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>
                </Grid>
              </Grid>
              <Grid
                item
                xs={11}
                md={8}
                lg={5}
                sx={{ direction: theme.lang.direction }}
              >
                <ProductInfo product={product} />
              </Grid>

                <Grid
                  item
                  xs={11}
                  md={11}
                  lg={4}
                  sx={{
                    direction: theme.lang.direction,
                    textAlign: "center",
                  }}
                  >
                  {profile.connected ? (
                  <VendorsTable product={product} setProduct={setProduct} />
                  ) : null}
                </Grid>
            </Grid>
          </Box>
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
            >
              <Divider sx={{ borderColor: "rgba(0,0,0,0.7)", width: "75%" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
                minHeight: "250px",
                height: "auto",
              }}
            >
              <DetailsTabs product={product} />
            </Box>
          </Box>
        </Box>
      )}
      <Grid container spacing={0} justifyContent="center">
        {relatedProducts && relatedProducts.length ? (
          <Grid item xs={11}>
            <ProductsSwiper
              data={{
                title: t("related_products"),
                products: relatedProducts,
              }}
              flashsale={false}
            />
          </Grid>
        ) : null}

        {upSellingProducts && upSellingProducts.length ? (
          <Grid item xs={11}>
            <ProductsSwiper
              data={{
                title: t("up_selling"),
                products: upSellingProducts,
              }}
              flashsale={false}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
