import { ShoppingCartOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../context/CartContext";
import Menu from "@mui/material/Menu";
import emartLogo from "../../assets/logo2.png";
import "./styles.css"

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 5,
  },
}));

export const CartBadge = () => {
  const cartContext = useContext(CartContext);

  const theme = useTheme();
  const { t } = useTranslation();
  const cartDetails = cartContext.cart;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
        <IconButton
          aria-label="cart"
          sx={{
            //p: 1,
            display: "flex",
            justifyContent:"space-between",
            backgroundColor:"transparent",
            ":hover":{
              backgroundColor:"transparent",
              textDecoration:"underline"
            }
          }}
          aria-controls={open ? "cart-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <StyledBadge
            badgeContent={cartDetails ? parseInt(cartDetails.items_qty) : 0}
            max={99}
            color="error"
            sx={{
              ml:2,
                mr:2
            }}
          >
            <ShoppingCartOutlined
              sx={{
                color: "primary.main",
                fontSize: "2rem",
                mb: 0,
                p: 0,
                
              }}
            />
          </StyledBadge>
          <Typography
            sx={{
              color: "primary.main",
             // ml: 2,
             // mr: 2,
              fontWeight: "bold",
            }}
          >
            {cartDetails ? cartDetails.formated_grand_total : 0}{" "}
            <Typography
              component={"span"}
              sx={{
                color: "inherit",
                fontWeight: 400,
              }}
            >
              {t("egp")}
            </Typography>
          </Typography>
        </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="cart-menu"
        open={open}
        onClose={handleClose}
       // MenuListProps={{ onMouseLeave: handleClose }}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: 300,
            direction: theme.lang.direction,
            height: "auto",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            maxHeight: 350,
            overflowY: "auto",
          }}
        >
          {cartDetails &&
            cartDetails.items &&
            cartDetails.items.map((item, index) => {
              return item.items.map((product, subIndex) => {
                return (
                  <Box key={"item-" + index + "-" + subIndex}>
                    <Link
                      href={
                        "/product/" +
                        product.product.url_key +
                        "/" +
                        product.product.sku +
                        "?id=" +
                        product.product.id
                      }
                      underline="none"
                    >
                      <Grid container justifyContent={"space-evenly"}>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            id="myCartMenuProductImg"
                            width="100%"
                            src={product.product.base_image.small_image_url}
                            alt="..."
                            loading="lazy"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                color: "black",
                                fontWeight: "bold",
                                textDecoration: "none",
                                height: "45px",
                                width: "auto",
                                whiteSpace: "normal",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textAlign: theme.lang.textAlign,
                              }}
                            >
                              {product.product.name}
                            </Typography>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    mt: 1,
                                    color: "primary.main",
                                    fontWeight: "bold",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {product.formated_price}
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      fontSize: "inherit",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {" " + t("egp")}
                                  </Typography>
                                </Typography>
                                <Typography
                                  sx={{
                                    mt: 0.5,
                                    color: "primary.main",
                                    fontWeight: "bold",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {t("quantity") + ": " + product.quantity}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    mt: 1,
                                    color: "primary.dark",
                                    fontSize: "0.9rem",
                                    textAlign: "center",
                                  }}
                                >
                                  {t("seller")}
                                </Typography>
                                {parseInt(item.vendor_id) === 0 ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      width={"60%"}
                                      src={emartLogo}
                                      alt="..."
                                      loading="lazy"
                                    />
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{
                                      mt: 0.5,
                                      color: "primary.dark",
                                      fontSize: "0.9rem",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.vendor_code}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Link>
                    <Divider sx={{ mb: 1, mt: 1 }} />
                  </Box>
                );
              });
            })}
          {!cartDetails && (
            <Box
              sx={{
                m: 1,
              }}
            >
              <Typography
                sx={{
                  color: "primary.dark",
                  fontSize: "0.9rem",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {t("cart_empty")}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  color: "primary.gray",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                {t("cart_empty_sub_title")}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />
        <Link
          href="/cart"
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            mt: 1,
          }}
          onClick={handleClose}
          underline="none"
        >
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", width: "50%" }}
          >
            {t("cart")}
          </Button>
        </Link>
      </Menu>
    </Box>
  );
};
