import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import egyptIcon from "../assets/egypt.png";
import ukIcon from "../assets/uk.png";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography, useTheme } from "@mui/material";

export default function LanguageSelector() {
  const { i18n, t } = useTranslation();
  const theme = useTheme();

  return (
    <FormControl
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: theme.lang.flexRowDirection,
      }}
    >
      {/*<Typography
        sx={{
          m: "0 1rem",
          fontSize: "1rem",
          color: "primary.light",
          fontWeight: "bold",
        }}
      >
        {t("language")}
      </Typography>*/}
      <Select
        value={theme.lang.lang}
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
          window.location.reload();
        }}
        sx={{
          padding: "0",
          height: 30,
          color: "primary.light",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.light",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.light",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.light",
          },
          ".MuiSelect-icon":{
            color: "primary.light",
          }
        }}
      >
        <MenuItem value={"en"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width:"100%"
            }}
          >
            
            <Box marginRight={2}>{t("english")} </Box>
            <img src={ukIcon} alt="..." />
          </Box>
        </MenuItem>
        <MenuItem value={"ar"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width:"100%"
            }}
          >
            <Box marginRight={2}>{t("arabic")} </Box>
            <img src={egyptIcon} alt="..." />
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
