import { Box, Link, Skeleton, Typography, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  calculatePercentage,
  getBrand,
  getRegularPrice,
} from "../components/products/Functions";
import { ProfileContext } from "../context/ProfileContext";
import StarIcon from "@mui/icons-material/Star";
import { Language } from "@mui/icons-material";
export const ProductCard = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useContext(ProfileContext);
  const badges = {
    discount: {
      label: t("discount_badge"),
      bgColor: "primary.neonCarrot",
      icon: null,
    },
    featured: {
      label: t("featured"),
      bgColor: "#0CBE5F",
      icon: <StarIcon sx={{ fontSize: "0.9rem" }} />,
    },
    new: {
      label: t("new_badge"),
      bgColor: red[900],
      icon: null,
    },
    bundle: {
      label: t("bundle_badge"),
      bgColor: "primary.blue",
      icon: null,
    },
  };
  const getBadgeType = () => {
    if (props.product.formated_regular_price) {
      return "discount";
    } else if (props.product.type !== "simple") {
      return "bundle";
    } else if (props.product.featured) {
      return "featured";
    } else if (props.product.new) {
      return "new";
    }

    return "none";
  };

  const regularPrice = getRegularPrice(props.product);
  const brand = getBrand(props.product.attributes);

  return props.loading ? (
    <Box
      height={"100%"}
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Skeleton width={"100%"} height="50px" animation="wave" />
      <Skeleton
        variant="rectangular"
        width={"100%"}
        sx={{
          height: { xs: "150px", md: "200px" },
        }}
        animation="wave"
      />
      <Skeleton width="60%" animation="wave" />
      <Skeleton width="40%" animation="wave" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <Skeleton width="25px" height="25px" animation="wave" />
      </Box>
    </Box>
  ) : (
    <Link
      href={
        "/product/" +
        props.product.url_key +
        "/" +
        props.product.sku +
        "?id=" +
        props.product.id
      }
      underline="none"
      width="100%"
      height="100%"
    >
      <Box
        width="100%"
        height="100%"
        textAlign={"center"}
        sx={{ direction: theme.lang.direction, height:{xs:"340px",md: "390px"} }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection:{xs:"column-reverse", sm:"row"},
            alignItems:{xs:"end", sm:"unset"},
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.8rem",
              color: "primary.gray",
              textAlign:{xs:"start",sm:"unset"},
              width:{xs:"100%", sm:"unset"}
              //minHeight: "20px"
            }}
          >
            {props.product.category && props.product.category.name}
          </Typography>
          {!props.hasBadge || getBadgeType() === "none" ? null : (
            <Typography
              sx={{
                //position: "absolute",
                p: "0.1rem 0.5rem",
                //m: 1,
                width:"fit-content",

                fontSize: "0.7rem",
                backgroundColor: badges[getBadgeType()].bgColor,
                color: "#FFF",
                borderRadius: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                //left:
              }}
            >
              {badges[getBadgeType()].icon}
              {badges[getBadgeType()].label}
            </Typography>
          )}
        </Box>
        <Typography
          sx={{
            marginTop: { xs: 0.5, md: 0.5 },
            marginBottom: { xs: 1, md: 1 },
            fontSize: "14px",
            color: "black",
            textDecoration: "none",
            height: "35px",
            width: "auto",
            whiteSpace: "normal",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            textAlign: theme.lang.textAlign,
            lineHeight: "18px",
            fontWeight: "bold",
          }}
        >
          {props.product.name}
        </Typography>
        <Box
          sx={{
            //height: {xs:"50%", md:"50%"},
            height: { xs: "150px", md: "200px" },
            display: "flex",
            justifyContent: "center",
            maxWidth: "100%",
            mb: { xs: 1, md: 2 },
          }}
        >
          <img
            src={props.product.base_image.medium_image_url}
            alt="..."
            height={"100%"}
            style={{ maxWidth: "100%" }}
            loading="lazy"
          />
        </Box>

        {profile.connected ? (
          <>
            <Box
              sx={
                {
                  // display: "flex",
                  // justifyContent: "space-between",
                  //  alignItems: "center",
                }
              }
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "primary.main",
                  fontSize: { xs: "0.85rem", md: "1rem" },
                  //textAlign: theme.lang.textAlign,
                  textAlign: "center",
                }}
              >
                {props.product.formated_price}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: { xs: "0.85rem", md: "1rem" },
                    fontWeight: 400,
                  }}
                >
                  {" " + t("egp")}
                </Typography>
              </Typography>
            </Box>

            <Typography
              sx={{
                color: "primary.gray",
                fontSize: { xs: "0.7rem", md: "0.8rem" },
                textAlign: "center",
                mt: 0,
                textDecoration: "none",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontSize: "inherit",
                  textDecoration: "line-through",
                }}
              >
                {regularPrice}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: "inherit",
                  fontWeight: 400,
                  textDecoration: "line-through",
                }}
              >
                {regularPrice ? " " + t("egp") : ""}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: "inherit",
                  fontWeight: "bold",
                  color: "primary.green",
                  textDecoration: "none",
                  mr: 2,
                  ml: 2,
                }}
              >
                {regularPrice
                  ? "  " +
                    calculatePercentage(
                      regularPrice,
                      props.product.formated_price
                    ) +
                    "% " +
                    t("off")
                  : ""}
              </Typography>
            </Typography>
          </>
        ) : null}

        <Box sx={{minHeight:"16%", display:"flex", flexDirection:"column", justifyContent:"end" }}>

          <Box
            sx={{
              width: "100%",
              //textAlign:"end"
              display: "flex",
              justifyContent: props.product.global ? "space-between" : "flex-end",
            }}
          >
            {props.product.global ? (
              <Box
                sx={{
                  backgroundColor: "#E03142",
                  color: "primary.light",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  pl: 0.6,
                  pr: 0.9,
                  pt: 0.1,
                  pb: 0.1,
                }}
              >
                <Language
                  sx={{ color: "primary.light", fontSize: "1.2rem", mr: 0.7 }}
                />
                G
              </Box>
            ) : null}
            {brand ? (
              <>
                {brand.typeImg ? (
                  <img
                    src={brand.value}
                    alt="..."
                    height={"25px"}
                    loading="lazy"
                  />
                ) : (
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      color: "primary.gray",
                      height: "25px",
                    }}
                  >
                    {brand.value}
                  </Typography>
                )}
              </>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "primary.gray",
                  height: "25px",
                }}
              >
                <br />
              </Typography>
            )}
          </Box>

          {profile.connected ? (
            <Box>
              {props.product.promotion_message ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    mt: 0.5,
                    padding: "0.1rem 0.5rem",
                    height: "25px",
                    width: "auto",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    textAlign: theme.lang.textAlign,
                    lineHeight: "18px",
                    border: "2px dashed",
                    borderColor: "primary.main",
                    color: "primary.green",
                  }}
                >
                  {props.product.promotion_message}
                </Typography>
              ) : (
                null
                )}
                {/*<Typography sx={{ height: "30px" }}>
                  <br />
              </Typography>*/}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Link>
  );
};
