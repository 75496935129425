import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const AttributesTable = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return props.product.attributes && props.product.attributes.length ? (
    <>
      <Box sx={{ width: { xs: "100%", lg: "100%" }, mb: 4 }}>
        {/*<Typography
          sx={{
            color: "primary.main",
            fontWeight: "bold",
            fontSize: "1rem",
            mb: 1,
          }}
        >
          {t("attributes")}
        </Typography>*/}
        {props.product.attributes.map((attribute, index) => {
          return (
            <Typography
              key={"attribute-" + index}
              sx={{
                direction: theme.lang.direction,
                backgroundColor:
                  index % 2 !== 0 ? "primary.bgLight" : "primary.light",

                p: "0.5rem 1rem",
                display: "flex",
                justifyContent: "space-between",
                color: "primary.gray",
              }}
            >
              {attribute.label ? attribute.label : "-"}{" "}
              <Typography
                component={"span"}
                sx={{
                  width: "40%",
                  textAlign: theme.lang.textAlign,
                  color: "primary.border",
                }}
              >
                {attribute.value}
              </Typography>
            </Typography>
          );
        })}
      </Box>
    </>
  ) : null;
};
