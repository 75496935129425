import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { HourglassBottom } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.gray,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderLeftStyle: theme.lang.lang === "en" ? "solid" : "none",
    borderRightStyle: theme.lang.lang === "en" ? "none" : "solid",
    marginRight: theme.lang.lang === "en" ? 0 : "19px",
    borderRadius: 0,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.primary.gray,
  display: "flex",
  height: 22,
  alignItems: "center",
  justifyContent: "center",
  ...(ownerState.active && {
    color: theme.palette.primary.main,
    width: 25,
    height: 25,
  }),
  "& .QontoStepIcon-completedIcon": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    zIndex: 1,
    borderRadius: "50%",
    width: 25,
    height: 25,
  },
  "& .QontoStepIcon-circle": {
    width: 25,
    height: 25,
    borderRadius: "100%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : active ? (
        <HourglassBottom />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

export const OrderStatusStepper = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [steps, setSteps] = React.useState([
    "ordered",
    "confirmed",
    "in_preparation",
    "delivered",
  ]);
  const stepsLabels = {
    ordered: {
      past: t("ordered"),
      noun: t("order_step"),
      progress: t("order_step"),
    },
    confirmed: {
      past: t("confirmed"),
      noun: t("confirmed_step"),
      progress: t("confirmed_progress"),
    },
    delivered: {
      past: t("delivered"),
      noun: t("delivered_step"),
      progress: t("delivered_progress"),
    },
    partially_delivered: {
      past: t("partially_delivered"),
      noun: t("partially_delivered_step"),
      progress: t("partially_delivered_progress"),
    },
    in_preparation: {
      past: t("in_preparation"),
      noun: t("in_preparation_step"),
      progress: t("in_preparation_progress"),
    },
  };

  //props.order.delivered_in_batches = 1
  //props.order.status = "partially_delivered"

  const [activeStep, setActiveStep] = React.useState(0);
  React.useEffect(() => {
    if (props.order.delivered_in_batches) {
      steps.splice(3, 0, "partially_delivered");
      setSteps([...steps]);
    }
    if (props.order.status === "in_preparation") {
      setActiveStep(steps.indexOf(props.order.status) + 0);
    } else {
      setActiveStep(steps.indexOf(props.order.status) + 1);
    }
  }, [props.order.status]);// eslint-disable-line

  React.useEffect(() => {
    if (props.order.status === "in_preparation") {
      setActiveStep(steps.indexOf(props.order.status) + 0);
    } else {
      setActiveStep(steps.indexOf(props.order.status) + 1);
    }
  }, [steps]);// eslint-disable-line

  const getLabel = (step, index) => {
    if (index < activeStep) {
      return stepsLabels[step].past;
    } else if (index === activeStep) {
      return stepsLabels[step].progress;
    } else {
      return stepsLabels[step].noun;
    }
  };
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<QontoConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{
                textAlign: "start",
                ".MuiStepLabel-label": {
                  mr: theme.lang.lang === "ar" ? 1 : 0,
                  ml: theme.lang.lang === "ar" ? 1 : 0,
                },
              }}
            >
              {getLabel(step, index)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
