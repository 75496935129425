import {
  Box,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../context/ProfileContext";

export const AccountDetails = () => {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);

  const fields = [
    { label: t("first_name"), value: profile.data.first_name },
    { label: t("last_name"), value: profile.data.last_name },
    { label: t("phone_number"), value: profile.data.phone },
    { label: t("email"), value: profile.data.email },
    { label: t("store_name"), value: profile.data.store_name },
  ];
  return (
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontSize: "1.7rem",
            fontWeight: "bold",
            textAlign: "center",
            color: "primary.gray",
            mb: 2,
          }}
        >
          {t("my_details")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "primary.light",
            p: "1rem",
            borderRadius: "7px",
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12}>
              <Box width={"100%"} display="flex" justifyContent="center">
                <Card
                  variant="elevation"
                  sx={{
                    marginBottom: 5,
                    maxWidth: { xs: "50%", sm: "25%", md: "20%" },
                  }}
                  elevation={0}
                >
                  <img
                    alt="Store Logo"
                    loading="lazy"
                    src={profile.data.store_image_url}
                    style={{ maxWidth: "100%" }}
                  />
                </Card>
              </Box>
            </Grid>
            {fields.map((field, index) => (
              <Grid item xs={10} sm={6} key={index}>
                <Box
                  sx={{
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "primary.gray",
                      fontSize: "0.8rem",
                    }}
                  >
                    {field.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: 600,
                    }}
                  >
                    {field.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
  );
};
