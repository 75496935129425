import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import imgPlaceholder from "../../../assets/placeholder-image.png";
import { axiosCustom } from "../../../endpoints/Axios";
import { FileUpload } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  GET_BANKS,
  GET_EWALLETS,
  VENDOR_SIGNUP,
} from "../../../endpoints/Constants";

const SignupVendorForm = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const body = new FormData();
  const [loading, setLoading] = useState(false);

  const [imgsList, setImgsList] = useState([
    {
      name: "commercial_registration",
      url: "",
    },
    {
      name: "tax_card",
      url: "",
    },
  ]);

  const [checkListState, setCheckListState] = useState([
    {
      type: "cash",
      id: "",
      errorId: "",
      number: "",
      errorNumber: "",
      checked: false,
      listItems: [],
    },
    {
      type: "bank",
      id: "",
      errorId: "",
      number: "",
      errorNumber: "",
      checked: false,
      listItems: [],
    },
    {
      type: "e-wallet",
      id: "",
      errorId: "",
      number: "",
      errorNumber: "",
      checked: false,
      listItems: [],
    },
  ]);
  const [checkListEmptyError, setCheckListEmptyError] = useState(false);

  const fetchBanks = async () => {
    axiosCustom
      .get(GET_BANKS)
      .then((res) => {
        const banks = res.data.data;
        checkListState[1].listItems = banks;
        setCheckListState([...checkListState]);
      })
      .catch((err) => {});
  };

  const fetchEWallets = async () => {
    axiosCustom
      .get(GET_EWALLETS)
      .then((res) => {
        const wallets = res.data.data;
        checkListState[2].listItems = wallets;
        setCheckListState([...checkListState]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchBanks();
    fetchEWallets();
  }, []);// eslint-disable-line

  const validateForm = (values) => {
    setCheckListEmptyError(false);
    checkListState.forEach((item) => {
      item.errorId = "";
      item.errorNumber = "";
    });

    let checkFound = false;
    let error = false;
    checkListState.forEach((item) => {
      if (item.checked) {
        checkFound = true;
        if (item.id === "" && item.type !== "cash") {
          item.errorId =
            item.type === "bank" ? t("bank_required") : t("ewallet_required");
          error = true;
        }

        if (
          (item.number === "" || item.number === "+20") &&
          item.type !== "cash"
        ) {
          item.errorNumber =
            item.type === "bank"
              ? t("iban_required")
              : t("phone_number_required");
          error = true;
        }

        if(item.type === "bank"){
          if(item.number.length !== 29){
            item.errorNumber = t("iban_invalid");
            error = true;
          }
        }
      }
    });

    if (!checkFound) {
      setCheckListEmptyError(true);
    }

    return checkFound && !error;
  };
  return (
    <Formik
      initialValues={{
        commercial_registration: "",
        tax_card: "",
        checkList: checkListState,
      }}
      validationSchema={Yup.object().shape({
        commercial_registration: Yup.string().required(t("photo_required")),
        tax_card: Yup.string().required(t("photo_required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (validateForm(values)) {
          setLoading(true);
          body.append(
            "commercial_registration",
            values.commercial_registration
          );
          body.append("tax_card", values.tax_card);
          checkListState.forEach((item, index) => {
            if (item.checked) {
              body.append("payment_methods[" + index + "][type]", item.type);
              body.append("payment_methods[" + index + "][id]", item.id);
              body.append(
                "payment_methods[" + index + "][number]",
                item.number.replace("+2", "")
              );
            }
          });
          axiosCustom
            .post(VENDOR_SIGNUP, body)
            .then((res) => {
              props.nextStep();
              setLoading(false);
            })
            .catch((err) => {
              setErrors({ submit: err.response.data.message });
              setLoading(false);
              // scroll dialog to top
              const dialog = document.getElementById(
                "signup-vendor-dialog-main-content"
              );
              dialog.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              direction: theme.lang.direction,
            }}
          >
            {errors.submit && (
              <Alert
                severity="error"
                sx={{
                  width: "100%",
                  margin: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                  direction: theme.lang.direction,
                }}
              >
                <Typography component={"span"} sx={{ mr: 1, ml: 1 }}>
                  {errors.submit}
                </Typography>
              </Alert>
            )}

            <Typography
              sx={{
                textAlign: "center",
                p: 2,
              }}
            >
              {t("complete_profile_vendor")}
            </Typography>

            {imgsList.map((item, index) => {
              return (
                <FormControl
                  fullWidth
                  error={Boolean(touched[item.name] && errors[item.name])}
                  sx={{ margin: "0rem 1rem" }}
                >
                  <FormHelperText
                    sx={{
                      color: "text.lightGray",
                      fontSize: "1rem",
                      textAlign: theme.lang.textAlign,
                    }}
                  >
                    {t(item.name)}*
                  </FormHelperText>
                  <Box textAlign={"center"}>
                    <img
                      height={"150px"}
                      width={"fit-content"}
                      style={{ maxWidth: "100%" }}
                      src={item.url ? item.url : imgPlaceholder}
                      alt="..."
                      loading="lazy"
                    />
                  </Box>
                  <Box textAlign={"center"}>
                    <input
                      accept="image/*"
                      id={"contained-button-file-" + index}
                      type="file"
                      hidden
                      onChange={(e) => {
                        const localUrl = URL.createObjectURL(e.target.files[0]);
                        imgsList[index].url = localUrl;
                        setImgsList([...imgsList]);
                        setFieldValue(item.name, e.target.files[0]);
                      }}
                    />
                    <label htmlFor={"contained-button-file-" + index}>
                      <Button
                        disabled={loading}
                        component="span"
                        sx={{
                          textTransform: "capitalize",
                          mt: 1,
                        }}
                        endIcon={<FileUpload sx={{ ml: 1, mr: 1 }} />}
                      >
                        {t("upload_photo")}
                      </Button>
                    </label>
                  </Box>

                  {touched[item.name] && errors[item.name] && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text"
                      sx={{
                        textAlign: theme.lang.textAlign,
                      }}
                    >
                      {errors[item.name]}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            })}

            <Typography
              sx={{
                textAlign: "center",
                p: 2,
                mt: 2,
                pb: 0,
              }}
            >
              {t("choose_payment_methods")}
            </Typography>
            {checkListEmptyError && (
              <Box width={"100%"}>
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {t("payment_method_error")}
                </FormHelperText>
              </Box>
            )}

            {values.checkList.map((item, index) => {
              return (
                <FormControl
                  fullWidth
                  error={Boolean(touched[item.type] && errors[item.type])}
                  sx={{ margin: "0rem 1rem" }}
                >
                  <FormControlLabel
                    sx={{
                      m: 1,
                      mt: 2,
                      mb: 0,
                    }}
                    control={
                      <Switch
                        checked={item.checked ? true : false}
                        onChange={(e) => {
                          values.checkList[index].checked = e.target.checked;
                          setFieldValue("checkList", values.checkList);
                        }}
                      />
                    }
                    label={t(item.type)}
                  />
                  {item.checked && item.type !== "cash" ? (
                    <FormControl
                      fullWidth
                      required
                    >
                      <FormHelperText
                        sx={{
                          color: "text.lightGray",
                          fontSize: "1rem",
                          textAlign: theme.lang.textAlign,
                        }}
                      >
                        {t(item.type)}*
                      </FormHelperText>

                      <Select
                        displayEmpty
                        renderValue={
                          item.id !== "" ? undefined : () => t(item.type)
                        }
                        id={"outlined-" + item.type + "-select"}
                        value={item.id}
                        name={item.type}
                        onChange={(e) => {
                          values.checkList[index].id = e.target.value;
                          setFieldValue("checkList", values.checkList);
                        }}
                      >
                        {item.listItems &&
                          item.listItems.map((menuItem) => {
                            return (
                              <MenuItem
                                key={"menu-" + index + "-" + menuItem.id}
                                value={menuItem.id}
                                sx={{ direction: theme.lang.direction }}
                              >
                                {menuItem.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {item.errorId && (
                        <FormHelperText
                          error
                          sx={{
                            textAlign: theme.lang.textAlign,
                          }}
                        >
                          {item.errorId}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : null}

                  {item.checked && item.type === "bank" ? (
                    <FormControl fullWidth required>
                      <FormHelperText
                        sx={{
                          color: "text.lightGray",
                          fontSize: "1rem",
                          textAlign: theme.lang.textAlign,
                        }}
                      >
                        {t("bank_account_number")}*
                      </FormHelperText>
                      <OutlinedInput
                        id={"outlined-adornment-" + item.type}
                        type="text"
                        value={item.number}
                        name="bank_account_number"
                        onBlur={handleBlur}
                        fullWidth
                        onChange={(e) => {
                          values.checkList[index].number = e.target.value;
                          setFieldValue("checkList", values.checkList);
                        }}
                        placeholder={t("bank_account_number")}
                        inputProps={{}}
                        style={{
                          color: "#000",
                          borderRadius: "10px",
                        }}
                      />
                      {item.errorNumber && (
                        <FormHelperText
                          error
                          sx={{
                            textAlign: theme.lang.textAlign,
                          }}
                        >
                          {item.errorNumber}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : null}
                  {item.checked && item.type === "e-wallet" ? (
                    <FormControl
                      fullWidth
                      sx={{
                        direction: "ltr",
                        mb: 2,
                        ".react-tel-input .form-control:focus": {
                          borderColor: "primary.main",
                          boxShadow:
                            "0px 0px 0px 1px " + theme.palette.primary.main,
                        },
                      }}
                    >
                      <FormHelperText
                        sx={{
                          textAlign: theme.lang.textAlign,
                          fontSize: "1rem",
                        }}
                      >
                        {t("phone_number")}
                      </FormHelperText>
                      <PhoneInput
                        inputStyle={{
                          width: "100%",
                          color: "#000",
                          borderRadius: "10px",
                        }}
                        value={item.number}
                        name={"phone"}
                        specialLabel={""}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          item.number = "+" + e;
                        }}
                        country={"eg"}
                        onlyCountries={["eg"]}
                        countryCodeEditable={false}
                      />
                      {item.errorNumber && (
                        <FormHelperText
                          error
                          sx={{
                            textAlign: theme.lang.textAlign,
                          }}
                        >
                          {item.errorNumber}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : null}
                  {touched[item.type] && errors[item.type] && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text"
                      sx={{
                        textAlign: theme.lang.textAlign,
                      }}
                    >
                      {errors[item.type]}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            })}

            <Grid item xs={11}>
              <Button
                fullWidth
                disabled={loading}
                size="medium"
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  padding: "0.7rem 0rem",
                  color: "text.white",
                  backgroundColor: "text.tertiary",
                  ":hover": {
                    backgroundColor: "text.tertiaryHover",
                  },
                  borderRadius: "48px",
                  textTransform: "none",
                  fontSize: "1.2rem",
                  marginBottom: "1.2rem",
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                ) : (
                  t("register_vendor")
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default SignupVendorForm;
