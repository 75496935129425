import axios from "axios";

export const axiosCustom = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL !== '' ? process.env.REACT_APP_BASE_URL : "",
    headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'locale': localStorage.getItem("i18nextLng"),
        "Authorization": "Bearer " + localStorage.getItem("ematkn"),
    },
})
