import {
  KeyboardArrowDown,
  Person,
  PersonOutlined,
  Widgets,
} from "@mui/icons-material";
import { Box, Link, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import { ProfileContext } from "../../../context/ProfileContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { axiosCustom } from "../../../endpoints/Axios";
import CustomizedSnackbars from "../../../ui-common-components/SnackBar";
import { USER_LOGOUT } from "../../../endpoints/Constants";

export const UserInfo = () => {
  const profile = useContext(ProfileContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const authComponents = useContext(AuthComponentsContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");

  return (
    <>
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />
      {profile.connected ? (
        <Box
          sx={{
            m: "0 2rem",
            display: "flex",
            alignItems: {xs:"center", sm:"baseline"},
            flexDirection: {xs:"column", sm:theme.lang.flexRowDirection},
          }}
        >
          <Typography
            sx={{
              color: "primary.light",
              direction: theme.lang.direction,
              display: "flex",
              alignItems: "center",
              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {t("hello")}{" "}
            <Typography
              component={"span"}
              sx={{
                color: "inherit",
                fontWeight: "bold",
                ml: 0.7,
                mr: 0.7,
              }}
            >
              {profile.data.first_name}!
            </Typography>
            <KeyboardArrowDown fontSize="small" />
          </Typography>
          {parseInt(profile.data.vendor_status) === 1 ? (
            null)
            :
          <Typography
            sx={{
              color: "primary.light",
              mr: 1,
              ml: 1,
              display:{xs:"none",sm:"block"},
            }}
          >
            |
          </Typography>
          }
          {parseInt(profile.data.vendor_status) === 1 ? (
            /*<Link
              href="/admin"
              target="_blank"
              rel="noreferrer"
              sx={{
                color: "primary.light",
                fontWeight: 500,
                fontSize: "0.8rem",
                ":hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "primary.light",
                },
              }}
            >
              {t("vendor_mode")}
            </Link>*/
            null
          ) : (
            <Typography
              sx={{
                color: "primary.light",
                fontWeight: 500,
                fontSize: "0.8rem",
                ":hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                authComponents.openSignupVendor();
              }}
            >
              {t("become_seller")}
            </Typography>
          )}

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 1,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                width: 150,
                direction: theme.lang.direction,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Link
              href="/profile/details"
              underline="none"
              sx={{ color: "inherit", ":hover": { color: "inherit" } }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                {t("profile")}
              </MenuItem>
            </Link>

            <Link
              href="/profile/orders"
              underline="none"
              sx={{ color: "inherit", ":hover": { color: "inherit" } }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Widgets fontSize="small" />
                </ListItemIcon>
                {t("orders")}
              </MenuItem>
            </Link>

            <Divider />

            <MenuItem
              onClick={() => {
                axiosCustom
                  .post(USER_LOGOUT)
                  .then((res) => {
                    localStorage.removeItem("ematkn");
                    window.location.href = "/";
                  })
                  .catch((err) => {
                    setSnackBarMessage(err.response.data.message);
                    setSnackBarSevirity("error");
                    openSnackBar.current();
                  });
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t("logout")}
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box
          sx={{
            m: "0 2rem",
          }}
        >
          <Typography
            sx={{
              color: "primary.light",
              display: "flex",
              alignItems: "center",
              flexDirection: theme.lang.flexRowDirection,
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={(e) => {
              authComponents.openLogin();
            }}
          >
            <PersonOutlined sx={{ color: "primary.light", fontSize: "2rem" }} />
            {t("sign_in")}
          </Typography>
        </Box>
      )}
    </>
  );
};
