import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AlertDialogSlide from "../../../ui-common-components/AlertDialogSlide";
import { cancelOrderAPI, getDate, getOrderById, 
 // reOrderAPI
 } from "./Functions";
import { OrderStatusStepper } from "./OrderStatusStepper";
import CustomizedSnackbars from "../../../ui-common-components/SnackBar";
import emartLogo from "../../../assets/logo2.png";
import { Close, 
  //Replay
 } from "@mui/icons-material";
export const OrderDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const navigate = useNavigate();
  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");
  const [loadingCancelOrder, setLoadingCancelOrder] = useState(false);
  const openAlertDialog = useRef(null);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertAction, setAlertAction] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const [alertMode, setAlertMode] = useState(null);

  const fetchOrderDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getOrderById(id);
      if (response.data.data.status === "cancelled") {
        navigate("/not-found");
      } else {
        setOrder(response.data.data);
        setLoading(false);
      }
    } catch (e) {
      setSnackBarMessage(e.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();
      setLoading(false);
    }
  };
  const useCurrentRoute = useLocation();

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) {
      navigate("/");
    } else {
      fetchOrderDetails(id);
    }
  }, [useCurrentRoute]);// eslint-disable-line

  const shippingDetails = [
    {
      title: t("name"),
      value: order
        ? order.shipping_address.first_name +
          " " +
          order.shipping_address.last_name
        : "-",
    },
    {
      title: t("store_name"),
      value: order ? order.shipping_address.store_name : "-",
    },
    {
      title: t("phone_number"),
      value: order ? order.shipping_address.phone : "-",
    },
    {
      title: t("city"),
      value: order ? order.shipping_address.state_name : "-",
    },
    {
      title: t("district"),
      value: order ? order.shipping_address.zone_name : "-",
    },
    {
      title: t("address"),
      value: order ? order.shipping_address.address1[0] : "-",
    },
  ];

  const orderSummary = [
    {
      title: t("sub_total"),
      value: order ? order.formated_sub_total + " " + t("egp") : 0,
    },
    {
      title: t("shipping_cost"),
      value: order ? order.formated_shipping_amount + " " + t("egp") : 0,
    },
    {
      title: t("discount_amount"),
      value: order ? order.formated_discount_amount + " " + t("egp") : 0,
    },
    {
      title: t("total"),
      value: order ? order.formated_grand_total + " " + t("egp") : 0,
    },
  ];

  const containerStyle = {
    p: 2,
    backgroundColor: "primary.mainLight",
    mb: 2,
    height: "fit-content",
  };
  const contentStyle = {
    p: 2,
    backgroundColor: "primary.light",
    borderRadius: "7px",
  };
  const titleStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "primary.gray",
    textAlign: "center",
    mb: 2,
  };

  const openAlert = (mode) => {
    if (mode === "cancelOrder") {
      setAlertMsg(t("cancel_order_confirmation"));
      setAlertAction(t("cancel"));
      setAlertTitle(t("cancel_order"));
      openAlertDialog.current();
    }
    /*if (mode === "reOrder") {
      setAlertMsg(t("re_order_confirmation"));
      setAlertAction(t("reorder"));
      setAlertTitle(t("reorder"));
      openAlertDialog.current();
    }*/
  };

  const confirmAlert = () => {
    if (alertMode === "cancelOrder") {
      cancelOrder();
    }
   /* if (alertMode === "reOrder") {
      reOrder();
    }*/
  };

  const cancelOrder = async () => {
    try {
      setLoadingCancelOrder(true);
      const res = await cancelOrderAPI(order.id);
      if (res.data.success) {
        setSnackBarSevirity("success");
        setSnackBarMessage(t("cancel_order_success"));
      } else {
        setSnackBarSevirity("error");
        setSnackBarMessage(res.data.message);
      }
      openSnackBar.current();
      setTimeout(() => {
        navigate("/profile/orders");
      }, 1000);
    } catch (e) {
      setSnackBarMessage(e.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();
      setLoadingCancelOrder(false);
    }
  };

  /*const reOrder = async () => {
    try {
      setLoadingCancelOrder(true);
      const res = await reOrderAPI(order.id);
      setSnackBarSevirity("success");
      setSnackBarMessage(res.data.message);
      openSnackBar.current();
      setTimeout(() => {
        navigate("/profile/orders");
      }, 1000);
    } catch (e) {
      setSnackBarMessage(e.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();
      setLoadingCancelOrder(false);
    }
  };*/
  return (
    <Box>
      <AlertDialogSlide
        confirmAlertRef={confirmAlert}
        openAlertDialog={openAlertDialog}
        title={alertTitle}
        description={alertMsg}
        actionText={alertAction}
      />

      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />
      {loading ? (
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              sx={{
                width: { xs: "100%", md: "95%", lg: "90%" },
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <Grid container item xs={11.5} md={5.8}>
                <Grid item xs={12} sx={containerStyle}>
                  <Skeleton variant="rectangular" height={300} width={"100%"} />
                </Grid>
                <Grid item xs={12} sx={containerStyle}>
                  <Skeleton variant="rectangular" height={200} width={"100%"} />
                </Grid>
              </Grid>
              <Grid container item xs={11.5} md={5.8}>
                <Grid item xs={12} sx={containerStyle}>
                  <Skeleton variant="rectangular" height={200} width={"100%"} />
                </Grid>
                <Grid item xs={12} sx={containerStyle}>
                  <Skeleton variant="rectangular" height={150} width={"100%"} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            mt: 3,
            direction: theme.lang.direction,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              sx={{
                width: { xs: "100%", md: "95%", lg: "90%" },
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <Grid container item xs={11.5} md={5.8}>
                <Grid item xs={12} sx={containerStyle}>
                  <Box sx={contentStyle}>
                    <Typography sx={titleStyle}>{t("order")}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("order_id")}
                        </Typography>
                        <Typography
                          sx={{
                            color: "primary.dark",
                            fontSize: "0.95rem",
                            fontWeight: "bold",
                          }}
                        >
                          #{order.id}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("order_date")}
                        </Typography>
                        <Typography
                          sx={{
                            color: "primary.dark",
                            fontSize: "0.95rem",
                            fontWeight: "bold",
                          }}
                        >
                          {getDate(order.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <OrderStatusStepper order={order} />
                    </Box>
                    {/*order.can_be_reordered && (
                      <Box
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Button
                          disabled={loadingCancelOrder}
                          variant="outlined"
                          sx={{
                            mt: 2,
                            width: { xs: "50%", md: "30%" },
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            setAlertMode("reOrder");
                            openAlert("reOrder");
                          }}
                          startIcon={<Replay sx={{ ml: 1, mr: 1 }} />}
                        >
                          {t("reorder")}
                        </Button>
                      </Box>
                    )*/}
                    {order.can_cancel && (
                      <Box
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Button
                          disabled={loadingCancelOrder}
                          variant="outlined"
                          color="error"
                          sx={{
                            mt: 2,
                            width: { xs: "50%", md: "30%" },
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            setAlertMode("cancelOrder");
                            openAlert("cancelOrder");
                          }}
                          startIcon={<Close sx={{ ml: 1, mr: 1 }} />}
                        >
                          {t("cancel_order")}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sx={containerStyle}>
                  <Box sx={contentStyle}>
                    <Typography sx={titleStyle}>{t("products")}</Typography>

                    {order.items.map((product, index) => {
                      return (
                        <Box key={"item-" + index} sx={{ pt: 1 }}>
                          <Link
                            href={
                              "/product/" +
                              product.product.url_key +
                              "/" +
                              product.product.sku +
                              "?id=" +
                              product.product.id
                            }
                            underline="none"
                          >
                            <Grid container justifyContent={"space-evenly"}>
                              <Grid
                                item
                                xs={1.5}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  width="100%"
                                  src={
                                    product.product.base_image.small_image_url
                                  }
                                  alt="..."
                                  loading="lazy"
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      color: "black",
                                      fontWeight: "bold",
                                      textDecoration: "none",
                                      height: "45px",
                                      width: "auto",
                                      whiteSpace: "normal",
                                      overflow: "hidden",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: "vertical",
                                      textAlign: theme.lang.textAlign,
                                    }}
                                  >
                                    {product.product.name}
                                  </Typography>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <Typography
                                        sx={{
                                          mt: 1,
                                          color: "primary.main",
                                          fontWeight: "bold",
                                          fontSize: "0.9rem",
                                        }}
                                      >
                                        {product.formated_price +
                                          " " +
                                          t("egp")}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          mt: 0.5,
                                          color: "primary.main",
                                          fontWeight: "bold",
                                          fontSize: "0.9rem",
                                        }}
                                      >
                                        {t("quantity") +
                                          ": " +
                                          product.qty_ordered}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography
                                        sx={{
                                          mt: 1,
                                          color: "primary.dark",
                                          fontSize: "0.9rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {t("seller")}
                                      </Typography>

                                      {parseInt(order.vendor_id) === 0  ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            width={"25%"}
                                            src={emartLogo}
                                            alt="..."
                                            loading="lazy"
                                          />
                                        </Box>
                                      ) : (
                                        <Typography
                                          sx={{
                                            mt: 0.5,
                                            color: "primary.dark",
                                            fontSize: "0.9rem",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {order.vendor_code}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Link>
                          <Divider sx={{ mb: 1, mt: 1 }} />
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={11.5}
                md={5.8}
                sx={{ display: "flex", alignContent: "flex-start" }}
              >
                <Grid item xs={12} sx={containerStyle}>
                  <Box sx={contentStyle}>
                    <Typography sx={titleStyle}>
                      {t("shipping_details")}
                    </Typography>

                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mb: 1,
                      }}
                    >
                      {t("shipping_method")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "primary.dark",
                        fontSize: "0.9rem",
                        mb: 2,
                      }}
                    >
                      {order.shipping_title}
                    </Typography>

                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      {t("ship_to")}
                    </Typography>
                    {shippingDetails &&
                      shippingDetails.map((item, index) => {
                        return (
                          <Box
                            key={"shipping-" + index}
                            sx={{ display: "flex", width: "100%" }}
                          >
                            <Typography
                              sx={{
                                width: { xs: "50%", md: "30%" },
                                color: "primary.dark",
                                fontWeight: "bold",
                              }}
                            >
                              {item.title}:
                            </Typography>
                            <Typography
                              sx={{ color: "primary.gray", fontWeight: "bold" }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12} sx={containerStyle}>
                  <Box sx={contentStyle}>
                    <Typography sx={titleStyle}>
                      {t("order_details")}
                    </Typography>
                    <Box
                      sx={{
                        mb: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("payment_status")}
                        </Typography>
                        <Typography
                          sx={{
                            color: "primary.dark",
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t(order.payment_status)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("payment_method")}
                        </Typography>
                        <Typography
                          sx={{
                            color: "primary.dark",
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                          }}
                        >
                          {order.payment_title}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      sx={{ mt: 2, mb: 2, borderColor: "primary.border" }}
                    />
                    {orderSummary.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "primary.dark",
                          }}
                        >
                          <Typography
                            sx={{
                              width: { xs: "60%", lg: "50%" },
                              color: "inherit",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{ color: "inherit", fontWeight: 500 }}
                          >
                            {item.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};
