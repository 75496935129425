import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { Grid, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { axiosCustom } from "../../../endpoints/Axios";
import { SUBMIT_PHONE } from "../../../endpoints/Constants";
export const PhoneForm = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        phone: "+20",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        phone: Yup.string()
          .length(13, t("phone_number_invalid"))
          .required(t("empty_phone_error")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setLoading(true);

        axiosCustom
          .post(SUBMIT_PHONE, {
            phone: values.phone.replace("+2", ""),
          })
          .then((res) => {
            if (res.status === 200) {
              props.setPhone(values.phone.replace("+2", ""));
              if (res.data.verified) {
                props.handleStepChange(2);
              } else {
                props.handleStepChange(1);
                props.setOTPLength(parseInt(res.data.length))
              }
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setErrors({ submit: err.response.data.errors.phone[0] });
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: 300 }}
          >
            <Typography
              sx={{
                mb: 2,
              }}
            >
              {t("enter_phone_number")}
            </Typography>
            {errors.submit && (
              <Alert
                severity="error"
                sx={{
                  width: "100%",
                  margin: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                  direction: theme.lang.direction,
                }}
              >
                <Typography component={"span"} sx={{ mr: 1, ml: 1 }}>
                  {errors.submit}
                </Typography>
              </Alert>
            )}

            <FormControl
              fullWidth
              error={Boolean(touched.phone && errors.phone)}
              sx={{
                margin: "0.5rem 1rem",
                mb: 2,
                ".react-tel-input .form-control:focus": {
                  borderColor: "primary.main",
                  boxShadow: "0px 0px 0px 1px " + theme.palette.primary.main,
                },
              }}
            >
              <FormHelperText
                sx={{
                  textAlign: theme.lang.textAlign,
                  fontSize: "1rem",
                }}
              >
                {t("phone_number")}
              </FormHelperText>
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  color: "#000",
                  borderRadius: "10px",
                }}
                value={values.phone}
                name={"phone"}
                specialLabel={""}
                onBlur={handleBlur}
                onChange={(e) => {
                  values.phone = "+" + e;
                }}
                country={"eg"}
                onlyCountries={["eg"]}
                countryCodeEditable={false}
                isValid={touched.phone && !errors.phone}
              />
              {touched.phone && errors.phone && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-phone-login"
                  sx={{
                    textAlign: theme.lang.textAlign,
                  }}
                >
                  {errors.phone}
                </FormHelperText>
              )}
            </FormControl>

            <Grid item xs={11}>
              <Button
                fullWidth
                disabled={loading}
                size="medium"
                type="submit"
                variant="contained"
                sx={{
                  padding: "0.7rem 0rem",

                  color: "text.white",
                  backgroundColor: "text.tertiary",
                  ":hover": {
                    backgroundColor: "text.tertiaryHover",
                  },
                  borderRadius: "48px",
                  textTransform: "none",
                  fontSize: "1.2rem",
                  marginBottom: "1.2rem",
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                ) : (
                  t("register_button_title")
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
