import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import "react-phone-input-2/lib/material.css";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { RESET_PASSWORD } from "../../../endpoints/Constants";
import { useTranslation } from "react-i18next";
import { axiosCustom } from "../../../endpoints/Axios";
import SwipeableViews from "react-swipeable-views";
import { PhoneForm } from "./PhoneForm";
import { OTPForm } from "./OTPForm";

const ForgetPasswordForm = () => {
  const authComponents = useContext(AuthComponentsContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const nextStep = () => {
    handleStepChange(activeStep + 1);
    if(activeStep === 0){
      setOneMinuteAfter(new Date(new Date().getTime() + 1000 * 60))
    }
  };

  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPLength, setOTPLength] = useState(1);
  const [oneMinuteAfter, setOneMinuteAfter] = useState(new Date(new Date().getTime() + 1000 * 60))
  return (
    <Box>
    
      <SwipeableViews
        axis={theme.lang.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        //disableMouseEvents
        disablemouseevents="true"
        animateHeight={true}
      >
        <PhoneForm
          nextStep={nextStep}
          setPhone={setPhone}
          setOTPLength={setOTPLength}
          
        />
        <OTPForm phone={phone} setOTP={setOTP} nextStep={nextStep} OTPLength={OTPLength} oneMinuteAfter={oneMinuteAfter}/>

        <Formik
          initialValues={{
            phone: phone,
            code: "",
            password: "",
            password_confirmation: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, t("invalid_password_length"))
              .max(16, t("invalid_password_length"))
              .required(t("empty_password_error")),
            password_confirmation: Yup.string()
              .required(t("empty_password_error"))
              .oneOf([Yup.ref("password"), null], t("not_matched_password")),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting }
          ) => {

            setLoading(true)
          
            let body={
              phone: phone,
              code: OTP,
              password: values.password,
              password_confirmation: values.password_confirmation
            }
            axiosCustom.post(RESET_PASSWORD, body).then((res)=>{
              setStatus({success: true})
              setErrors({submit: t("reset_success")})
              setLoading(false)
              setTimeout(()=>{
                authComponents.openLogin();
               // handleStepChange(0)
              }, [1000])
            }).catch((err)=>{
              setLoading(false)
              const errorsArr = Object.keys(err.response.data.errors)
              setStatus({success: false})
              setErrors({ submit: err.response.data.errors[errorsArr[0]][0] });
            })

          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setStatus,
            status,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                height={320}
              >
                {errors.submit && (
                  <Alert
                    severity={status.success ? "success" : "error"}
                    sx={{
                      width: "100%",
                      margin: "0 1rem",
                      direction: theme.lang.direction,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        mr: 1,
                        ml: 1,
                        display: "flex",
                      }}
                      component="span"
                    >
                      {errors.submit}
                    </Typography>
                  </Alert>
                )}

                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  sx={{ margin: "0rem 1rem" }}
                >
                  <FormHelperText
                    sx={{
                      color: "text.lightGray",
                      fontSize: "1rem",
                      textAlign: theme.lang.textAlign,
                    }}
                  >
                    {t("password")}*
                  </FormHelperText>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder={t("password")}
                    inputProps={{}}
                    style={{
                      color: "#000",
                      borderRadius: "10px",
                    }}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password"
                      sx={{
                        textAlign: theme.lang.textAlign,
                      }}
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.password_confirmation &&
                      errors.password_confirmation
                  )}
                  sx={{ margin: "0rem 1rem" }}
                >
                  <FormHelperText
                    sx={{
                      color: "text.lightGray",
                      fontSize: "1rem",
                      textAlign: theme.lang.textAlign,
                    }}
                  >
                    {t("confirm_password")}*
                  </FormHelperText>
                  <OutlinedInput
                    id="outlined-adornment-password_confirmation"
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    value={values.password_confirmation}
                    name="password_confirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password_confirmation visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder={t("confirm_password")}
                    inputProps={{}}
                    style={{
                      color: "#000",
                      borderRadius: "10px",
                    }}
                  />
                  {touched.password_confirmation &&
                    errors.password_confirmation && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password_confirmation"
                        sx={{
                          textAlign: theme.lang.textAlign,
                        }}
                      >
                        {errors.password_confirmation}
                      </FormHelperText>
                    )}
                </FormControl>

                <Grid item xs={11}>
                  <Button
                    fullWidth
                    disabled={loading}
                    size="medium"
                    type="submit"
                    
                    variant="contained"
                    sx={{
                      padding: "0.7rem 0rem",
                      color: "text.white",
                      backgroundColor: "text.tertiary",
                      ":hover": {
                        backgroundColor: "text.tertiaryHover",
                      },
                      borderRadius: "48px",
                      textTransform: "none",
                      fontSize: "1.2rem",
                      marginBottom: "1.2rem",
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        sx={{ color: "#FFF" }}
                        size={"1.8rem"}
                      />
                    ) : (
                      t("confirm")
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </SwipeableViews>
    </Box>
  );
};

export default ForgetPasswordForm;
