import React from "react";

import { createTheme } from "@mui/material/styles";

import PropTypes from "prop-types";
import { Link as RouterLink, MemoryRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return (
      <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
    );
  });
  
  LinkBehavior.propTypes = {
    href: PropTypes.oneOfType([
      PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
      PropTypes.string,
    ]).isRequired,
  };
  
  function Router(props) {
    const { children } = props;
    if (typeof window === "undefined") {
      return <StaticRouter location="/">{children}</StaticRouter>;
    }
    return <MemoryRouter>{children}</MemoryRouter>;
  }
  
  Router.propTypes = {
    children: PropTypes.node,
  };

const theme = createTheme({
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
    lang:{
      lang: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en",
      direction: localStorage.getItem("i18nextLng") === "en" || !localStorage.getItem("i18nextLng") ? "ltr" : "rtl",
      anchor: localStorage.getItem("i18nextLng") === "en" || !localStorage.getItem("i18nextLng") ? "left" : "right",
      flexRowDirection:  localStorage.getItem("i18nextLng") === "en" || !localStorage.getItem("i18nextLng") ? "row" : "row-reverse",
      textAlign:  localStorage.getItem("i18nextLng") === "en" || !localStorage.getItem("i18nextLng") ? "left" : "right"
      
    },
    palette: {
      primary: {
        main: "#722282",
        mainLight: "#F5F5FA",
        dark: "#333E48",
        light: "#FFF",
        border: "#3A4553",
        borderDarker: "#282d33",
        bgLight: "#EFEFEF",
        gray: "#6d6e71",
        neonCarrot:"#F8A44C",
        blue: "#0083FC",
        green:"#53B175",
        red: "#d32f2f"
      },
    },
  });

  export default theme