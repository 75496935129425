import React, { useEffect, useState, useContext } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";

// import required modules
import { Pagination, Grid } from "swiper";
import {
  Box,
  Divider,
  Link,
  Typography,
  useTheme,
  Grid as MUIGrid,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getProducts } from "./Functions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { ProfileContext } from "../../context/ProfileContext";
import {
  calculatePercentage,
  getBrand,
  getRegularPrice,
} from "../products/Functions";
import { ProductCard } from "../../ui-common-components/ProductCard";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const Bestsellers = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const [value, setValue] = useState(0);

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(-1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentCategoryIndex(newValue - 1);
  };

  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);

  const fetchProducts = async (endpointParam) => {
    setLoading(true);
    const data = await getProducts(endpointParam);
    setProducts(data);
    setLoading(false);
  };

  useEffect(() => {
    const endpointParam =
      currentCategoryIndex === -1
        ? ""
        : "?category_id=" + categories[currentCategoryIndex].id;
    fetchProducts(endpointParam);
  }, [currentCategoryIndex]); // eslint-disable-line

  useEffect(() => {
    try {
      setCategories(props.categoriesTree.sub_categories[0].sub_categories);
    } catch (error) {}
  }, [props]);

  const skeletonSize = Array(8).fill(0);

  const loadingPlaceholder = skeletonSize.map((item, index) => {
    return (
      <SwiperSlide key={"product-skeleton-" + index}>
        <Box
          sx={{
            borderRight: {
              xs: theme.lang.lang === "en" ? "1px solid" : 0,
            },
            borderLeft: {
              xs: theme.lang.lang === "en" ? 0 : "1px solid",
            },
            borderColor: { xs: "divider" },
            pr: theme.lang.lang === "en" ? 2 : 0,
        pl: theme.lang.lang === "en" ? 0 : 2,
            mb: { xs: 0, sm: 1 },
            width: "inherit",
          }}
        >
          <MUIGrid
            container
            sx={{
              justifyContent: { xs: "center", sm: "space-between" },
            }}
          >
            <MUIGrid
              item
              xs={12}
              sm={5}
              md={5}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                mb: { xs: 1, sm: 0 },
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"150px"}
                animation="wave"
              />
            </MUIGrid>
            <MUIGrid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
               // pr: theme.lang.lang === "en" ? 2 : 0,
                //pl: theme.lang.lang === "en" ? 0 : 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"55px"}
                  animation="wave"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"30%"}
                    height={"20px"}
                    animation="wave"
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"40%"}
                    height={"20px"}
                    animation="wave"
                    sx={{
                      mb: 0.5,
                    }}
                  />
                </Box>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </Box>
      </SwiperSlide>
    );
  });

  return (
    <>
      <Box
        sx={{
          direction: theme.lang.direction,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderBottom: "2px solid",
              borderColor: "primary.main",
              height: "100%",
            }}
          >
            <Typography sx={{ mt: 2, mb: 2 }}>{t("up_selling")}</Typography>
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                border: "2px solid",
                borderColor: theme.palette.primary.main,
                borderRadius: "3px",
                height: "60%",
                bottom: "20%",
                backgroundColor: "transparent",
              },
            }}
            sx={{
              direction: theme.lang.direction,
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Tab
              label={t("top_20")}
              sx={{ textTransform: "capitalize" }}
              {...a11yProps(0)}
            />
            {categories.map((category, index) => {
              return (
                <Tab
                  key={"tab-" + index}
                  label={category.name}
                  sx={{ textTransform: "capitalize" }}
                  {...a11yProps(index + 1)}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: {xs:1, md:3},
            position: "relative",
            top: "-2px",
            zIndex: -1,
          }}
        >
          <Divider
            sx={{
              opacity: 1,
              borderColor: "#DDD",
              borderBottomWidth: "2px",
              width: "100%",
            }}
          />
        </Box>

        <Swiper
          dir={theme.lang.direction}
          slidesPerView={4}
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={false}
          modules={[Pagination, Grid]}
          className="mySwiper customSlider"
          grid={{
            rows: 2,
            fill: "row",
          }}
          // Responsive breakpoints
          breakpoints={{
            1: {
              slidesPerView: 2,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            650: {
              slidesPerView: 2,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            // when window width is >= 480px
            1080: {
              slidesPerView: 3,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            // when window width is >= 640px
            1350: {
              slidesPerView: 4,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
          }}
        >
          {loading &&  window.innerWidth > 520
            ? loadingPlaceholder
            : products &&
              products.map((product, index) => {
                const regularPrice = getRegularPrice(product);
                const brand = getBrand(product.attributes);
                return (
                  <SwiperSlide key={"product-" + index}>
                    <Link
                      href={
                        "product/" +
                        product.url_key +
                        "/" +
                        product.sku +
                        "?id=" +
                        product.id
                      }
                      underline="none"
                      sx={{
                        borderRight: {
                          xs: theme.lang.lang === "en" ? "1px solid" : 0,
                        },
                        borderLeft: {
                          xs: theme.lang.lang === "en" ? 0 : "1px solid",
                        },
                        borderColor: { xs: "divider" },
                        pr: theme.lang.lang === "en" ? 2 : 0,
                    pl: theme.lang.lang === "en" ? 0 : 2,
                        mb: { xs: 0, sm: 1 },
                        width: "inherit",
                      }}
                    >
                      <MUIGrid
                        container
                        sx={{
                          justifyContent: { xs: "center", sm: "space-between" },
                          display: { xs: "none", sm: "flex" },
                        }}
                      >
                        <MUIGrid
                          item
                          xs={10}
                          sm={4}
                          md={4}
                          display="flex"
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{
                            mb: { xs: 1, sm: 0 },
                          }}
                        >
                          <img
                            src={product.base_image.medium_image_url}
                            alt="..."
                            height="120px"
                            style={{maxWidth:"100%"}}
                            loading="lazy"
                          />
                        </MUIGrid>
                        <MUIGrid
                          item
                          xs={12}
                          sm={7}
                          md={7}
                          sx={{
                            pr: theme.lang.lang === "en" ? 2 : 0,
                            pl: theme.lang.lang === "en" ? 0 : 2,
                           
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              color: "primary.gray",
                              textAlign: "start",
                            }}
                          >
                            {product.category.name}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                              //height: "90%",
                              marginTop: { xs: 0.5, md: 0.5 },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "black",
                                textDecoration: "none",
                                height: "55px",
                                width: "auto",
                                whiteSpace: "normal",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                lineHeight: "18px",
                                //marginBottom: "10px",
                                fontWeight: "bold",
                                textAlign: theme.lang.textAlign,
                              }}
                            >
                              {product.name}
                            </Typography>
                            
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: profile.connected ? "space-between": "flex-end",
                                    alignItems: "center",
                                    mt: { xs: 1, md: 2 },
                                  }}
                                >
                                  {profile.connected ? (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "primary.main",
                                      fontSize: { xs: "0.85rem", md: "1rem" },
                                      textAlign: theme.lang.textAlign,
                                    }}
                                  >
                                    {product.formated_price}
                                    <Typography
                                      component={"span"}
                                      sx={{
                                        fontSize: { xs: "0.85rem", md: "1rem" },
                                        fontWeight: 400,
                                      }}
                                    >
                                      {" " + t("egp")}
                                    </Typography>
                                  </Typography>
                                ) : null}
                                  {brand && (
                                    <Box
                                      sx={{
                                        width: "fit-content",
                                      }}
                                    >
                                      {brand.typeImg ? (
                                        <img
                                          src={brand.value}
                                          alt="..."
                                          height={"25px"}
                                          loading="lazy"
                                        />
                                      ) : (
                                        <Typography
                                          sx={{
                                            fontSize: "0.9rem",
                                            color: "primary.gray",
                                          }}
                                        >
                                          {brand.value}
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                </Box>
                                {profile.connected ? (
                                <Typography
                                  sx={{
                                    color: "primary.gray",
                                    fontSize: { xs: "0.7rem", md: "0.8rem" },
                                    textAlign: theme.lang.textAlign,
                                    mt: 0,
                                    textDecoration: "none",
                                  }}
                                >
                                  {regularPrice ? "" : <br />}
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      fontSize: "inherit",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {regularPrice}
                                  </Typography>
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      fontSize: "inherit",
                                      fontWeight: 400,
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {regularPrice ? " " + t("egp") : ""}
                                  </Typography>
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      fontSize: "inherit",
                                      fontWeight: "bold",
                                      color: "primary.green",
                                      textDecoration: "none",
                                      mr: 0,
                                      ml: 0,
                                    }}
                                  >
                                    {regularPrice
                                      ? " " +
                                        calculatePercentage(
                                          regularPrice,
                                          product.formated_price
                                        ) +
                                        "% " +
                                        t("off")
                                      : ""}
                                  </Typography>
                                </Typography>
                            ) : null}
                              </Box>
                          </Box>
                        </MUIGrid>
                      </MUIGrid>

                      <MUIGrid
                        container
                        sx={{
                         // justifyContent: "space-between",
                          display: { xs: "flex", sm: "none" },
                        }}
                        spacing={0}
                      >
                        <MUIGrid
                          key={"product-" + index}
                          item
                          xs={12}
                          sm={5.5}
                          md={3.8}
                          lg={3}
                          //marginTop={2}
                          sx={{
                            
                          }}
                        >
                          <ProductCard
                            product={product}
                            loading={loading}
                            hasBadge={false}
                          />
                        </MUIGrid>
                      </MUIGrid>
                    </Link>
                  </SwiperSlide>
                );
              })}

          {!loading && products.length === 0 ? (
            <Box sx={{ textAlign: "center", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  color: "primary.main",
                  backgroundColor: "primary.mainLight",
                  p: 2,
                  borderRadius: "3px",
                  mb: 2,
                }}
              >
                {t("no_products")}
              </Typography>
            </Box>
          ) : null}
        </Swiper>
      </Box>
    </>
  );
};
