import { Place } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../context/ProfileContext";

export const Addresses = () => {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const address = [profile.data.default_address];
  const fields = [
    {
      label: t("city"),
      valueKey: "state_name",
    },
    {
      label: t("district1"),
      valueKey: "zone_name",
    },
    {
      label: t("address"),
      valueKey: "address1",
    },
    {
      label: t("phone_number"),
      valueKey: "phone",
    },
  ];

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontSize: "1.7rem",
          fontWeight: "bold",
          textAlign: "center",
          color: "primary.gray",
          mb: 2,
        }}
      >
        {t("shipping_address")}
      </Typography>
      <Box sx={{}}>
        <Grid container justifyContent={"space-between"}>
          {address.map((item, index) => (
            <Grid
              item
              xs={12}
              lg={5.7}
              key={index}
              sx={{
                backgroundColor: "primary.light",
                p: "1rem",
                borderRadius: "7px",
                border: "2px solid",
                borderColor: "primary.main",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Place sx={{ color: "primary.main" }} />
                <Typography
                  sx={{
                    ml: 2,
                    mr: 2,
                    p: "0.3rem 0.7rem",
                    backgroundColor: "primary.main",
                    color: "#FFF",
                    fontSize: "0.8rem",
                    borderRadius: "5px",
                  }}
                >
                  {t("main_address")}
                </Typography>
              </Box>
              <Divider sx={{ mt: 1, mb: 1, borderColor: "rgba(0,0,0,0.5)" }} />
              {fields.map((field, index) => (
                <Box key={"item-"+index} sx={{ display: "flex", mb: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "primary.gray",
                      width: "40%",
                      fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1rem" },
                    }}
                  >
                    {field.label}:
                  </Typography>

                  <Typography
                    sx={{
                      width: "70%",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.9rem",
                        md: "1rem",
                      },
                    }}
                  >
                    {field.valueKey === "address1"
                      ? item[field.valueKey][0] 
                      : item[field.valueKey]}
                  </Typography>
                </Box>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
