import {
  Facebook,
  Instagram,
  Telegram,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo2.png";
import messenger from "../../assets/messenger.png";
import tiktok from "../../assets/tik-tok.png";
import appleStore from "../../assets/app_store.png";
import googleStore from "../../assets/google_play.png";
import { AuthComponentsContext } from "../../context/AuthComponentsContext";
import { ProfileContext } from "../../context/ProfileContext";

const Footer = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const authComponents = useContext(AuthComponentsContext);
  const profile = useContext(ProfileContext);

  const categoriesTree = props.categoriesTree;
  const myAccountLinks = [
    {
      name: t("orders"),
      href: "/profile/orders",
    },
    {
      name: t("shipping_address"),
      href: "/profile/address",
    },
  ];

  const aboutUsLinks = [
    {
      name: t("about_us"),
      href: "/about-us",
    },
    {
      name: t("terms_and_conditions"),
      href: "/terms-and-conditions",
    },
    {
      name: t("privacy_policy"),
      href: "/privacy-policy",
    },
  ];

  const goToGoogleStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.emart.shop";
  };

  const goToAppleStore = () => {
    window.location.href =
      "https://apps.apple.com/eg/app/emart-%D8%A5%D9%8A%D9%85%D8%A7%D8%B1%D8%AA/id1614692611";
  };

  const linksTitleStyle = {
    fontSize: "1.1rem",
    fontWeight: "bold",
    pb: 1,
    textAlign: theme.lang.textAlign,
    color: "primary.main",
    textTransform: "capitalize",
    mt: {xs:2, md:0},
  };

  const linksStyle = {
    fontSize: "0.9rem",
    mt: 1,
    mb: 1,
    color: "primary.gray",
    textAlign: theme.lang.textAlign,
    ":hover": {
      color: "primary.main",
      cursor: "pointer",
    },
  };

  return !categoriesTree ? null : (
    <Box
      sx={{
        backgroundColor: "#F8F8F8",
        mt: 6,
        direction: theme.lang.direction,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F8F8F8",
        }}
      >
        <Grid
          container
          sx={{
          //  minHeight: 100,

            pr: { xs: 2, md: 5 },
            pl: { xs: 2, md: 5 },
            pt: 3,
            pb: 2,
            justifyContent: { xs: "center", lg: "space-between" },
          }}
        >
          <Grid xs={12} md={12} lg={3}
            item
           
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: 100,
            }}
          >
            <Link href="/">
              <img src={logo} alt="..." width="130px" />
            </Link>
           
            <Box sx={{mt:1, mb:1}}>
              <Typography
                sx={{
                  color: "primary.gray",
                }}
              >
                {t("contact_us")}
              </Typography>
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  direction: "ltr",
                  textAlign: theme.lang.textAlign,
                }}
              >
                +201554777776
              </Typography>
            </Box>

            <Box sx={{ color: "#6E767D" }}>
              <a
                href="https://www.facebook.com/emartapp"
                style={{ color: "inherit" }}
                target="_blank"
                rel="noreferrer"
              >
                <Facebook sx={{ m: 1, mt: 0, color: "inherit" }} />
              </a>
              <a
                href="https://www.instagram.com/emartapp01/"
                style={{ color: "inherit" }}
                target="_blank"
                rel="noreferrer"
              >
                <Instagram sx={{ m: 1, mt: 0, color: "inherit" }} />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=+201554777776&text= &type=phone_number&app_absent=0"
                style={{ color: "inherit" }}
                target="_blank"
                rel="noreferrer"
              >
                <WhatsApp sx={{ m: 1, mt: 0, color: "inherit" }} />
              </a>
              <a
                href="https://t.me/emart1258"
                style={{ color: "inherit" }}
                target="_blank"
                rel="noreferrer"
              >
                <Telegram sx={{ m: 1, mt: 0, color: "inherit" }} />
              </a>
              <a
                href="http://m.me/emartapp"
                style={{ color: "inherit", width: "fit-content" }}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  sx={{ color: "inherit", width: "fit-content" }}
                  component="span"
                >
                  <img
                    src={messenger}
                    alt="..."
                    width={"18px"}
                    style={{ margin: "8px", marginTop: 0 }}
                  />
                </Box>
              </a>
              <a
                href="https://www.youtube.com/@emartApp"
                style={{ color: "inherit" }}
                target="_blank"
                rel="noreferrer"
              >
                <YouTube sx={{ m: 1, mt: 0, color: "inherit" }} />
              </a>
              <a
                href="https://www.tiktok.com/@emartapp?ug_source=op.auth&ug_term=Linktr.ee&utm_source=awyc6vc625ejxp86&_r=1"
                style={{ color: "inherit", width: "fit-content" }}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  sx={{ color: "inherit", width: "fit-content" }}
                  component="span"
                >
                  <img
                    src={tiktok}
                    alt="..."
                    width={"18px"}
                    style={{ margin: "8px", marginTop: 0 }}
                  />
                </Box>
              </a>
            </Box>
          </Grid>

          <Grid container item xs={12} sm={6} md={9} lg={4}>
         
            <Grid item xs={12} md={6}>
              <Typography sx={linksTitleStyle}>{t("find_it_fast")}</Typography>
              {categoriesTree.sub_categories[0].sub_categories.map(
                (subCategory, subIndex) => {
                  return (
                    <Link
                      key={"subcategory-" + subIndex}
                      href={
                        "/products/" +
                        subCategory.slug +
                        "?categoryId=" +
                        subCategory.id +
                        "&page=1"
                      }
                      underline="none"
                    >
                      <Typography sx={linksStyle}>
                        {subCategory.name}
                      </Typography>
                    </Link>
                  );
                }
              )}
            </Grid>
            
              <Grid item xs={12} md={6}>
                <Typography sx={linksTitleStyle}>{t("my_account")}</Typography>

                {myAccountLinks.map((link, subIndex) => {
                  return (
                    profile.connected ?
                    <Link
                      key={"link-" + subIndex}
                      href={link.href}
                      underline="none"
                    >
                      <Typography sx={linksStyle}>{link.name}</Typography>
                    </Link>
                    :
                    <Box 
                    key={"link-" + subIndex}
                    onClick={()=>{
                      authComponents.openLogin();
                    }}>
                      <Typography sx={linksStyle}>{link.name}</Typography>
                    </Box>
                  );
                })}
              </Grid>
             
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Typography sx={linksTitleStyle}>{t("about_us")}</Typography>

            {aboutUsLinks.map((link, subIndex) => {
              return (
                <Link
                  key={"aboutus-link-" + subIndex}
                  href={link.href}
                  underline="none"
                >
                  <Typography sx={linksStyle}>{link.name}</Typography>
                </Link>
              );
            })}
            {profile.connected && parseInt(profile.data.vendor_status) !== 1 ? (
              <Typography
                sx={linksStyle}
                onClick={() => {
                  authComponents.openSignupVendor();
                }}
              >
                {t("become_seller")}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={3}>
            <Typography sx={linksTitleStyle}>
              {t("download_our_app")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.emart.shop"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{
                  marginBottom: "1rem",
                }}
              >
                <img
                  src={googleStore}
                  alt=""
                  width={"140px"}
                  onClick={goToGoogleStore}
                />
              </a>
              <a
                href="https://apps.apple.com/eg/app/emart-%D8%A5%D9%8A%D9%85%D8%A7%D8%B1%D8%AA/id1614692611"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <img
                  src={appleStore}
                  alt=""
                  width={"140px"}
                  onClick={goToAppleStore}
                />
              </a>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography
        sx={{
          textAlign: "center",
          p: 1,
          fontSize: "0.9rem",
          color: "primary.gray",
          backgroundColor: "#EAEAEA",
        }}
      >
        ©{" "}
        <Typography
          component={"span"}
          sx={{
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          <Link href="/" underline="none" sx={{ color: "primary.gray" }}>
            eMart
          </Link>
        </Typography>{" "}
        {t("all_rights_reserved")}
      </Typography>
    </Box>
  );
};

export default Footer;
