import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Breadcrumbs, Link, useTheme } from "@mui/material";

export const BreadcrumbsCustom = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        m: 1.2,
        mt: 0,
        mb: 0,
        direction: theme.lang.direction,
      }}
    >
      <Breadcrumbs
        sx={{
          width: {xs:"98%", lg:"90%"},
        }}
        separator={
          theme.lang.lang === "en" ? (
            <NavigateNextIcon fontSize="small" />
          ) : (
            <NavigateBeforeIcon fontSize="small" />
          )
        }
        aria-label="breadcrumb"
      >
        {props.breadcrumbs.map((item, index) => {
          return (
            <Link
              underline="none"
              key={index}
              color="inherit"
              href={item.href}
              
              /*component={
                index === props.breadcrumbs.length - 1 && props.hasend
                  ? "a"
                  : "a"
              }
              disabled={
                index === props.breadcrumbs.length - 1 && props.hasend
                  ? false
                  : false
              }*/
              sx={{
                fontSize:{xs:"0.75rem", md:"0.9rem",},
                ":hover": {
                  color: "primary.main",
                },
                "&[disabled]": {
                  cursor: "default",
                  color: "primary.main",
                  ":hover": {
                    textDecoration: "none",
                  },
                },
              }}
            >
              {item.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
