import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";

// import required modules
import { Pagination, Grid } from "swiper";
import {
  Box,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { ProductCard } from "./ProductCard";

const ProductsSwiper = (props) => {
  const theme = useTheme();

  const [title, setTitle] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    try {
      setTitle(props.data.title);
      setProducts(props.data.products);
    } catch (error) {}
  }, [props]);

  const skeletonSize = Array(6).fill(0);

  const buildProductsSlides = (data) =>{
    return(
      data &&
        data.map((product, index) => {
          return (
            <SwiperSlide key={"product-" + index}>
              <Box
                sx={{
                  
                  backgroundColor: "primary.light",
                  margin: 0,
                  marginBottom: {xs:2, md:4},
                  padding: 0,
                  width:"100%",
                  //height: { xs: "320px", md: "300px" },
                  borderRight: {
                    xs: 0,
                    md: theme.lang.lang === "en" ? "1px solid" : 0,
                  },
                  borderLeft: {
                    xs: 0,
                    md: theme.lang.lang === "en" ? 0 : "1px solid",
                  },
                  borderColor: { xs: 0, md: "divider" },
                  pr:2,
                  pl:2,
                }}
              >
                <ProductCard
                  product={product}
                  loading={props.loading}
                  hasBadge={false}
                />
              </Box>
            </SwiperSlide>
          );
        })
    )
  }
  return (
    <Box
      sx={{
        direction: theme.lang.direction,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderBottom: "2px solid",
            borderColor: "primary.main",
            height: "100%",
          }}
        >
          <Typography sx={{ mt: 2, mb: 2 }}>{title}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
          position: "relative",
          top: "-2px",
          zIndex: -1,
        }}
      >
        <Divider
          sx={{
            opacity: 1,
            borderColor: "#DDD",
            borderBottomWidth: "2px",
            width: "100%",
          }}
        />
      </Box>

      <Swiper
        slidesPerView={6}
        spaceBetween={30}
        dir={theme.lang.direction}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={false}
        modules={[Pagination, Grid]}
        className="mySwiper"
        breakpoints={{
          1: {
            slidesPerView: 2,
          },
          700: {
            slidesPerView: 3,
          },
          1000: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 6,
          },
        }}
      >
        {
          props.loading ?
            buildProductsSlides(skeletonSize)
            :
            buildProductsSlides(products)
        }
       
      </Swiper>
    
    </Box>
  );
};
export default ProductsSwiper;
