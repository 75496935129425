import {
  Box,
  Card,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AttributesTable } from "./AttributesTable";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: "100%" }}
      >
        {value === index && (
          <Box
            sx={{
              
              width: "100%",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  
export const DetailsTabs = (props) => {
    const { t } = useTranslation();
  const theme = useTheme();

  const product = props.product
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [orientation, setOrientation] = useState("vertical");
  useEffect(() => {
    setOrientation(window.innerWidth >= 900 ? "vertical" : "horizontal");
    window.addEventListener("resize", () => {
      setOrientation(window.innerWidth >= 900 ? "vertical" : "horizontal");
    });
  }, []);

  return (
    <Grid
      container
      sx={{
        width: "90%",
        height: "auto",
        display: "flex",
       direction:theme.lang.direction,
      }}
    >
      <Grid
        item
        xs={12}
        md={2.4}
        lg={1.7}
        sx={{ height: { xs: "auto", md: "100%" } }}
      >
        <Tabs
          orientation={orientation}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          
          sx={{
            height: "100%",
            direction:theme.lang.direction,
            ".MuiTabs-indicator": {
              left: theme.lang.lang === "en" ? "unset" : 0,
              right: theme.lang.lang === "en" ? 0 : "unset",
              width: "1.5px",
              zIndex: 2,
            },
          }}
        >
           {product.type === "simple" ? (
          <Tab
            sx={{
              alignItems: "flex-start",
              direction: theme.lang.direction,
              textTransform: "capitalize",
              fontWeight: "bold",
              textAlign: theme.lang.textAlign,
              pr: { xs: 1, md: 0 },
              pl: { xs: 1, md: 0 },
            }}
            label={t("product_details")}
            {...a11yProps(0)}
          />):null}
          {product.type !== "simple" ? (
            <Tab
              sx={{
                alignItems: "flex-start",
                direction: theme.lang.direction,
                textTransform: "capitalize",
                fontWeight: "bold",
                textAlign: theme.lang.textAlign,
                pr: { xs: 1, md: 0 },
                pl: { xs: 1, md: 0 },
              }}
              label={t("this_bundle_contains")}
              {...a11yProps(1)}
            />
          ) : null}
       
        </Tabs>
      </Grid>
      <Grid item xs={12} md={9} lg={7} sx={{ height: "100%", 
      display:"flex"
      ,flexDirection:{xs:"column", md:"row"}
     }}>
      <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: {xs:"-1.5px", md:"0px"},
            left: theme.lang.lang === "en" ? "unset" : {xs:0, md:1.5},
            right: theme.lang.lang === "en" ? {xs:0, md:1.5} : "unset",
            zIndex: 0,
            borderRight: {
              xs: "none",
              md:"1.5px solid rgba(0,0,0,0.2)",
            },
           
            borderBottom: {
              xs: "1.5px solid rgba(0,0,0,0.2)",
              md: "none",
            },
          }}
        >
      
        </Box>
        <TabPanel value={value} index={0}>
        {product.type === "simple" ? (
          <Box width="100%" component={"span"}>
            {/*<Box
              sx={{
                textAlign: "justify",
                direction: theme.lang.direction,
                width: "100%",
              }}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            ></Box>*/}
          <AttributesTable product={product} />
            </Box>
        ):
        <Box
        width="100%"
        sx={{
          //maxHeight: "400px",
          //overflow: "auto",
         // overflowX: "hidden",
          direction: theme.lang.direction,
          p: { xs: "1rem 0", md: "0 1rem" },
        }}
      >
        {/*<Typography
          sx={{
            direction: theme.lang.direction,
            width: "100% !important",
            color: "primary.main",
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          {t("this_bundle_contains")} :
        </Typography>*/}
        {product.bundle_options.map((option, index) => {
          return (
            <Card
              key={"option-" + index}
              elevation={2}
              sx={{
                padding: 1,
                marginBottom: 2,
              }}
            >
              <Link
                href={"/product?id=" + option.product_id}
                underline="none"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontWeight: "bold",
                        fontSize: {
                          xs: "0.9rem",
                          md: "1rem",
                        },
                      }}
                    >
                      {t("quantity_x")}
                      {option.qty}
                    </Typography>

                    {/*profile.connected ? (
                      <Typography
                        sx={{
                          color: "#45a43f",
                          fontWeight: "bolder",
                          fontSize: {xs:"0.6rem", md:"0.9rem"},
                          display: {xs:"none", sm:"flex"},
                          alignItems: "center",
                          backgroundColor: "#e7f3e7",
                          width: "fit-content",
                          mt: 1,
                          mb: 0,
                          p: {xs:"0 0.2rem", md:"0 0.5rem"},
                          borderRadius: "7px",
                        }}
                      >
                        <Info sx={{fontSize: {xs:"1rem", md:"0.9rem"},}}/>
                        <Typography
                          component={"span"}
                          sx={{
                            m: 1,
                            fontWeight: "inherit",
                            fontSize: "inherit",
                          }}
                        >
                          {t("save")} {option.saved_amount.formated_price} {t("egp")}
                        </Typography>
                      </Typography>
                  ) : null*/}
                  </Grid>
                  <Grid container item xs={12} sm={8}>
                    <Grid
                      item
                      xs={10}
                      sx={{
                        padding: {xs:0, md:1},
                        display: "flex",
                        alignItems: "center",
                        paddingRight: theme.lang.lang === "en" ? 1 : 0,
                        paddingLeft: theme.lang.lang === "ar" ? 1 : 0,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            color:"primary.gray"
                          }}
                        >
                          {option.name}
                        </Typography>
                        {/*profile.connected ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: 1,
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                textDecoration: "line-through",
                                color: "primary.gray",
                                fontSize: {
                                  xs: "0.8rem",
                                  sm: "1rem",
                                },
                              }}
                            >
                              {option.regular_price.formated_price} {t("egp")}
                            </Typography>
                            <Typography
                              sx={{
                                color: "primary.main",
                                fontWeight: "bold",
                                fontSize: {
                                  xs: "0.8rem",
                                  sm: "1rem",
                                },
                              }}
                            >
                              {option.final_price.formated_price} {t("egp")}
                            </Typography>
                          </Box>
                            ) : null*/}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={option.base_image.small_image_url}
                        width="100%"
                        alt="..."
                        loading="lazy"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{display: {xs:"block", sm:"none"},}}>
                  {/*profile.connected ? (
                      <Typography
                        sx={{
                          color: "#45a43f",
                          fontWeight: "bolder",
                          fontSize: {xs:"0.8rem", md:"0.9rem"},
                          display:"flex",
                          alignItems: "center",
                          backgroundColor: "#e7f3e7",
                          width: "fit-content",
                          mt: 1,
                          mb: 0,
                          p: {xs:"0 0.2rem", md:"0 0.5rem"},
                          borderRadius: "7px",
                        }}
                      >
                        <Info sx={{fontSize: {xs:"1rem", md:"0.9rem"},}}/>
                        <Typography
                          component={"span"}
                          sx={{
                            m: 1,
                            fontWeight: "inherit",
                            fontSize: "inherit",
                          }}
                        >
                          {t("save")} {option.saved_amount.formated_price} {t("egp")}
                        </Typography>
                      </Typography>
                  ) : null*/}
                  </Grid>
                </Grid>
              </Link>
            </Card>
          );
        })}
      </Box>
        }
        </TabPanel>
       
      </Grid>
    </Grid>
  );
};
