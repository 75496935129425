import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import {
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getFeaturedAndNewProducts } from "./Functions";
import { ProductCard } from "../../ui-common-components/ProductCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export const FeaturedAndNewProducts = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const fetchProducts = async (endpointParam) => {
    setLoading(true);
    const data = await getFeaturedAndNewProducts(endpointParam);
    setProducts(data);
    setLoading(false);
  };

  useEffect(() => {
    const endpointParam = value === 0 ? "?featured=1" : "?new=1";
    fetchProducts(endpointParam);
  }, [value]); // eslint-disable-line

  const buildProductsGrid = (data) => {
    return (
      <Grid
        container
        sx={{
         // justifyContent: "space-between",
        }}
        //spacing={5}
        //rowSpacing={10}
        columnSpacing={{xs:1, md:5}}
      >
        {data &&
          data.map((product, index) => {
            return (
              
                <Grid
                  key={"product-" + index}
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={3}
                  marginTop={2}
                 
                >
                  <Box  sx={{
                    //height: { xs: "200px", md: "300px" },
                    m:0,
                    marginBottom: 3,
                    padding: 0,
                    borderRight: {
                      xs: theme.lang.lang === "en" && ((index+1)%2)!==0 ? "1px solid rgba(0,0,0, 0.12)" : 0,
                      md: theme.lang.lang === "en" && ((index+1)%3)!==0 ? "1px solid rgba(0,0,0, 0.12)" : 0,
                      lg: theme.lang.lang === "en" && ((index+1)%4)!==0 ? "1px solid rgba(0,0,0, 0.12)" : 0,
                    },
                    borderLeft: {
                      xs: theme.lang.lang === "ar" && ((index+1)%2)!==0 ? "1px solid rgba(0,0,0, 0.12)" : 0,
                      md: theme.lang.lang === "ar" && ((index+1)%3)!==0 ? "1px solid rgba(0,0,0, 0.12)" : 0,
                      lg: theme.lang.lang === "ar" && ((index+1)%4)!==0 ? "1px solid rgba(0,0,0, 0.12)" : 0,
                    },
                    pr: theme.lang.lang === "en" ? 2 : 0,
                    pl: theme.lang.lang === "en" ? 0 : 2,
                  }}>

                  <ProductCard 
                    product={product}
                    loading={loading}
                    hasBadge={false}
                    />
                  </Box>
                </Grid>
              
             
            );
          })}

          {
            !loading &&(!data || data.length === 0) ? (
              <Grid item xs={12}>
              <Box sx={{ textAlign: "center", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  color: "primary.main",
                  backgroundColor: "primary.mainLight",
                  p: 2,
                  borderRadius: "3px",
                  mb: 2,
                }}
                >
                {t("no_products")}
              </Typography>
            
            </Box>
                </Grid>
            ):null
          }
      </Grid>
    );
  };

  const skeletonSize = Array(8).fill(0);


  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          sx={{
            /*borderBottom: 1,
            borderColor: "divider",*/
            direction: theme.lang.direction,
          }}
          TabIndicatorProps={{
            style: {
              //border: "2px solid",
              //borderColor: theme.palette.primary.main,
              //borderRadius: "3px",
              //height: "60%",
              //bottom: "20%",
              //backgroundColor: "transparent",
              height: "1px",
              zIndex: 1,
            },
          }}
        >
          <Tab
            label={t("featured")}
            sx={{ textTransform: "capitalize" }}
            {...a11yProps(0)}
          />
          <Tab
            label={t("new")}
            sx={{ textTransform: "capitalize" }}
            {...a11yProps(1)}
          />
        </Tabs>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: {xs:1, md:1},
            position: "relative",
            top: "-2px",
            zIndex: 0,
          }}
        >
          <Divider
            sx={{
              opacity: 1,
              borderColor: "#DDD",
              borderBottomWidth: "2px",
              width: "100%",
            }}
          />
        </Box>
        <TabPanel value={value} index={0}>
          {value === 0
            ? loading
              ? buildProductsGrid(skeletonSize)
              : buildProductsGrid(products)
            : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {value === 1
            ? loading
              ? buildProductsGrid(skeletonSize)
              : buildProductsGrid(products)
            : null}
        </TabPanel>
      </Box>
      
    </>
  );
};
