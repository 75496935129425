import { axiosCustom } from "../../endpoints/Axios";
import {
  BESTSELLERS,
  GET_ALL_SLIDERS,
  GET_FLASH_SALES,
  PRODUCTS,
  RECENTLY_BOUGHT,
} from "../../endpoints/Constants";

export const getAllSliders = async () => {
  try {
    const response = await axiosCustom.get(GET_ALL_SLIDERS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getFlashSales = async () => {
  try {
    const response = await axiosCustom.get(GET_FLASH_SALES);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getFeaturedAndNewProducts = async (endpointParam) => {
  try {
    const response = await axiosCustom.get(
      PRODUCTS + endpointParam + "&limit=8"
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getProducts = async (endpointParam) => {
  try {
    const response = await axiosCustom.get(BESTSELLERS + endpointParam);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getRecentlyBought = async () => {
  try {
    const response = await axiosCustom.get(RECENTLY_BOUGHT);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getSliderLink = (slider) => {
  if (slider.record_type === "products") {
    return (
      "/product/" +
      slider.record_data.url_key +
      "/" +
      slider.record_data.sku +
      "?id=" +
      slider.record_id
    );
  } else if (slider.record_type === "categories") {
    return "/products?categoryId=" + slider.record_id + "&page=1";
  } else {
    return "/";
  }
};
