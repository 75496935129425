import React from "react";
import { Box, List, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomizedMenu = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        sx={{
          backgroundColor: "primary.main",
          color: "primary.light",
          borderRadius: "7px 7px 0 0",
          padding: "0.5rem 1rem",
          display: "flex",
          justifyContent: "space-between",
          direction: theme.lang.direction,
          fontSize: "13px",
        }}
      >
        {t("all_categories")}
      </Typography>

      <List
        sx={{
          p: "0rem 1rem 1rem 1rem",
          background: "#FFF",
          border: "1px solid",
          borderRadius: "0 0 3px 3px",
          borderColor: "primary.main",
          direction: theme.lang.direction,
          fontSize: "13px",
          ":.dropdown:hover .dropdown-menu": {
            display: "block",
            marginTop: 0,
          },
          maxHeight:"412px",
          overflowY:"auto",
        }}
      >
        {props.dataList}
      </List>
    </Box>
  );
};

export default CustomizedMenu;
