import { Box, Link, Typography } from '@mui/material'
import React from 'react'

export const NotFound = () => {
  return (
    <Box 
        sx={{
            height:"500px",
            display: "flex",
            flexDirection:"column",
            justifyContent: "center",
            alignItems:"center"
        }}
    >
        
        <Typography sx={{
            color:"primary.main",
            fontSize:"5rem",
            fontWeight: "bold"
        }}>
            OOPS!!
        </Typography>
        <Typography
            sx={{
                fontSize:"1.5rem"
            }}
        >
            Error 404 Page not found 
            <br/>Please get back to {" "}
            <Link href="/">
                Home
            </Link>
        </Typography>
        
    </Box>
  )
}
