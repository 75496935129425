import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  GET_CITIES,
  GET_DISTRICTS,
  USER_SIGNUP,
} from "../../../endpoints/Constants";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import imgPlaceholder from "../../../assets/placeholder-image.png";
import { axiosCustom } from "../../../endpoints/Axios";
import { FileUpload } from "@mui/icons-material";

const DetailsForm = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const [districts, setDistricts] = useState([]);
  const [storeImgURL, setStoreImgURL] = useState("")
  const body = new FormData()
  useEffect(() => {
    axiosCustom
      .get(GET_CITIES)
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (selectedCity) {
      axiosCustom
        .get(GET_DISTRICTS.replace("{id}", selectedCity))
        .then((res) => {
          setDistricts(res.data.data);
        })
        .catch((err) => {});
    }
  }, [selectedCity]);
  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        phone: props.phone,
        password: "",
        password_confirmation: "",
        store_name: "",
        store_image: "",
        country_id: "1",
        state_id: "",
        zone_id: "",
        address1: "",
        multiple_branches: false,
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().max(30, t("first_name_invalid_size").replace("#", 30)).required(t("first_name_required")),
        last_name: Yup.string().max(30, t("last_name_invalid_size").replace("#", 30)).required(t("last_name_required")),
        email: Yup.string().email(t("email_invalid")),
        password: Yup.string().min(6, t("invalid_password_length")).max(16, t("invalid_password_length")).required(t("empty_password_error")),
        password_confirmation: Yup.string().required(t("empty_password_error")).oneOf([Yup.ref('password'), null], t("not_matched_password")),
        store_name: Yup.string().max(30).required(t("store_name_required")),
        store_image: Yup.string().required(t("store_photo_required")),
        state_id: Yup.string().max(50).required(t("city_required")),
        zone_id: Yup.string().max(50).required(t("district_required")),
        address1: Yup.string().max(255).required(t("address_required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setLoading(true)
        values.phone = props.phone
        Object.keys(values).forEach((key)=>{
          body.append(key, values[key])
        })
        axiosCustom.post(USER_SIGNUP, body).then((res)=>{
          setLoading(false)
          props.nextStep()
        }).catch((err)=>{
          setLoading(false)
          const errorsArr = Object.keys(err.response.data.errors)
          setErrors({ submit: err.response.data.errors[errorsArr[0]][0] });
        })
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: 1200, direction: theme.lang.direction }}
          >
            {errors.submit && (
              <Alert
                severity="error"
                sx={{
                  width: "100%",
                  margin: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                  direction: theme.lang.direction,
                }}
              >
                <Typography component={"span"} sx={{ mr: 1, ml: 1 }}>
                  {errors.submit}
                </Typography>
              </Alert>
            )}

            <FormControl
              fullWidth
              error={Boolean(touched.first_name && errors.first_name)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                htmlFor="outlined-adornment-first_name-signup"
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("first_name")}*
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-first_name-signup"
                type="text"
                value={values.first_name}
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("first_name")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.first_name && errors.first_name && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-first_name-signup"
                  sx={{
                     textAlign:theme.lang.textAlign
                  }}
                >
                  {errors.first_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.last_name && errors.last_name)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                htmlFor="outlined-adornment-last_name-signup"
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("last_name")}*
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-last_name-signup"
                type="text"
                value={values.last_name}
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("last_name")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.last_name && errors.last_name && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-last_name-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.last_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("email")}
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-email-signup"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("email")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("password")}*
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-password-signup"
                type={showPassword ? "text" : "password"}
                autoComplete="on"
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={t("password")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(
                touched.password_confirmation && errors.password_confirmation
              )}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("confirm_password")}*
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-password_confirmation-signup"
                type={showPassword ? "text" : "password"}
                autoComplete="on"
                value={values.password_confirmation}
                name="password_confirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password_confirmation visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={t("confirm_password")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.password_confirmation && errors.password_confirmation && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password_confirmation-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.password_confirmation}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.state_id && errors.state_id)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("city")}*
              </FormHelperText>

              <Select
                displayEmpty
                renderValue={
                  values.state_id !== "" ? undefined : () => t("city")
                }
                id={"outlined-adornment-city"}
                value={values.state_id}
                name={"city"}
                onChange={(e) => {
                  setFieldValue("state_id", e.target.value);
                  setSelectedCity(e.target.value);
                }}
              >
                {cities &&
                  cities.map((city) => {
                    return (
                      <MenuItem
                        key={"city-" + city.id}
                        value={city.id}
                        sx={{ direction: theme.lang.direction }}
                      >
                        {city.default_name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {touched.state_id && errors.state_id && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-state_id-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.state_id}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.zone_id && errors.zone_id)}
              sx={{ margin: "0rem 1rem" }}
             
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("district1")}*
              </FormHelperText>

              <Select
                displayEmpty
                renderValue={
                  values.zone_id !== "" ? undefined : () => t("district1")
                }
                id={"outlined-adornment-district"}
                value={values.zone_id}
                name={"district"}
                onChange={(e) => {
                  setFieldValue("zone_id", e.target.value);
                }}
              >
                {districts &&
                  districts.map((district) => {
                    return (
                      <MenuItem
                        key={"district-" + district.id}
                        value={district.id}
                        sx={{ direction: theme.lang.direction }}
                      >
                        {district.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {touched.zone_id && errors.zone_id && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-zone_id-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.zone_id}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.address1 && errors.address1)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("address")}*
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-address1-signup"
                type="text"
                value={values.address1}
                name="address1"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("address")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.address1 && errors.address1 && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-address1-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.address1}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.store_name && errors.store_name)}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("store_name")}*
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-store_name-signup"
                type="text"
                value={values.store_name}
                name="store_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("store_name")}
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.store_name && errors.store_name && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-store_name-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.store_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.store_image && errors.store_image)}
              sx={{ margin: "0rem 1rem" }}
             
            >
              <FormHelperText
                sx={{
                  color: "text.lightGray",
                  fontSize: "1rem",
                  textAlign: theme.lang.textAlign,
                }}
              >
                {t("store_photo")}*
              </FormHelperText>
              <Box textAlign={"center"}>
                <img
                  height={"150px"}
                  width={"fit-content"}
                  style={{maxWidth: "100%"}}
                  src={storeImgURL ? storeImgURL : imgPlaceholder}
                  alt="..."
                  loading="lazy"
                />
              </Box>
              <Box textAlign={"center"}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={(e) => {
                    const localUrl = URL.createObjectURL(e.target.files[0]);
                    setStoreImgURL(localUrl)
                    setFieldValue("store_image",e.target.files[0])                      
                  }}
                />
                <label htmlFor="contained-button-file">

                  <Button
                    disabled={loading}
                    component="span"
                    sx={{
                      textTransform: "capitalize",
                      mt:1
                    }}
                    endIcon={<FileUpload sx={{ml:1, mr:1}}/>}
                  >
                    {t("upload_photo")}
                  </Button>
                </label>
              </Box>

              {touched.store_image && errors.store_image && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-store_image-signup"
                  sx={{
                    textAlign:theme.lang.textAlign
                 }}
                >
                  {errors.store_image}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(
                touched.multiple_branches && errors.multiple_branches
              )}
              sx={{ margin: "0rem 1rem" }}
            >
              <FormControlLabel
                sx={{
                  m: 1,
                }}
                control={
                  <Checkbox
                    checked={values.multiple_branches ? true : false}
                    onChange={(e) => {
                      setFieldValue(
                        "multiple_branches",
                        !values.multiple_branches
                      );
                    }}
                  />
                }
                label={t("have_other_branches")}
              />
            </FormControl>

            <Grid item xs={11}>
              <Button
                fullWidth
                disabled={loading}
                size="medium"
                type="submit"
                variant="contained"
                sx={{
                  padding: "0.7rem 0rem",
                  color: "text.white",
                  backgroundColor: "text.tertiary",
                  ":hover": {
                    backgroundColor: "text.tertiaryHover",
                  },
                  borderRadius: "48px",
                  textTransform: "none",
                  fontSize: "1.2rem",
                  marginBottom: "1.2rem",
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                ) : (
                  t("next")
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default DetailsForm;
