import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

import { Box, Typography, useTheme } from "@mui/material";
import { useState, useContext } from "react";
import { PhoneForm } from "./PhoneForm";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import { OTPForm } from "./OTPForm";
import DetailsForm from "./DetailsForm";
import { ConfirmationPage } from "./ConfirmationPage";

const SignupForm = (props) => {
  const authComponents = useContext(AuthComponentsContext);
  const theme = useTheme();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);

    if(step === 1){
      setOneMinuteAfter(new Date(new Date().getTime() + 1000 * 60))
    }
  };
  

  const [phone, setPhone] = useState("")
  const [OTPLength, setOTPLength] = useState(1)
  const [oneMinuteAfter, setOneMinuteAfter] = useState(new Date(new Date().getTime() + 1000 * 60))
  const nextStep = () => {
    handleStepChange(activeStep + 1);
   
  };
  return (
    <>
      <Box >
        <SwipeableViews
          axis={theme.lang.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          disableMouseEvents
          animateHeight={true}
        >
          <PhoneForm handleStepChange={handleStepChange} setPhone={setPhone} setOTPLength={setOTPLength}/>
          <OTPForm nextStep={nextStep} phone={phone} OTPLength={OTPLength} oneMinuteAfter={oneMinuteAfter}/>
          <DetailsForm nextStep={nextStep} phone={phone}/>
          <ConfirmationPage />

        </SwipeableViews>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          marginBottom: "2rem",
          direction: theme.lang.direction
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            fontSize: { xs: "0.9rem", md: "1rem" },
            mr:2,
            ml:2
          }}
        >
          {t("have_an_account")}
        </Typography>

        <Typography
          color="primary.main"
          sx={{
            cursor: "pointer",
            fontWeight: "bold",
            textAlign: "left",
            ":hover": {
              color: "primary.border",
            },
            paddingLeft: "4px",
            fontSize: { xs: "0.9rem", md: "1rem" },
          }}
          onClick={() => {
            authComponents.openLogin();
          }}
        >
          {t("sign_in")}
        </Typography>
      </Box>
    </>
  );
};

export default SignupForm;
