import React from "react";
import { Grid, Link, Skeleton } from "@mui/material";
import { getSliderLink } from "./Functions";
import test1 from "../../assets/testFixed.png";
import test2 from "../../assets/testFixed2.png";
import test3 from "../../assets/testFixed3.png";
import test4 from "../../assets/testFixed4.png";

export const FixedTopSlider = (props) => {
  const skeletonSize = Array(4).fill(0);
  const fixedTopSlidersTest = [
    { image_url: test1, clickable: true, link: "/" },
    { image_url: test2, clickable: true, link: "/" },
    { image_url: test3, clickable: true, link: "/" },
    { image_url: test4, clickable: true, link: "/" },
  ];

  const gridSizes = {
    xs: 5.5,
    sm: 5,
    md: 5,
    lg: 3,
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: {xs:"space-evenly", lg:"space-around"},
      }}
    >
      {props.slidersLoading
        ? skeletonSize.map((item, index) => {
            return (
              <Grid
                key={"fixed-skeleton-" + index}
                item
                xs={gridSizes.xs}
                sm={gridSizes.sm}
                md={gridSizes.md}
                lg={gridSizes.lg}
              >
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"150px"}
                  animation="wave"
                />
              </Grid>
            );
          })
        : //fixedTopSlidersTest.map((slider, index) => {
          props.fixedTopSliders &&
          props.fixedTopSliders.map((slider, index) => {
            return (
              <Grid
                key={"fixed-" + index}
                item
                xs={gridSizes.xs}
                sm={gridSizes.sm}
                md={gridSizes.md}
                lg={gridSizes.lg}
              >
                <img
                  src={slider.image_url}
                  alt="..."
                  width={"100%"}
                  loading="lazy"
                />
              </Grid>
            );
          })}
    </Grid>
  );
};
/*props.fixedTopSliders &&
          props.fixedTopSliders.map((slider, index) => {
            return (
              <Grid key={"fixed-" + index} item xs={gridSizes.xs}
                sm={gridSizes.sm}
                md={gridSizes.md}
                lg={gridSizes.lg}
                >
                {slider.clickable ? (
                  <Link href={getSliderLink(slider)}>
                    <img src={slider.image_url} alt="..." width={"100%"}  loading="lazy"/>
                  </Link>
                ) : (
                  <img src={slider.image_url} alt="..." width={"100%"}  loading="lazy" />
                )}
              </Grid>
            );
          })*/
