import { axiosCustom } from "../../endpoints/Axios";
import {
  ADD_PRODUCT_TO_CART,
  PLACE_ORDER,
  PROMO_CODE,
  REMOVE_PRODUCT_FROM_CART,
  SAVE_CART,
  UPDATE_CART,
} from "../../endpoints/Constants";

export const addToCartAPI = async (product, vendorIndex, qty) => {
  try {
    const response = await axiosCustom.post(
      ADD_PRODUCT_TO_CART + "/" + product.id,
      {
        product_id: product.id,
        vendor_id: product.vendors[vendorIndex].vendor_id,
        quantity: qty,
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const updateCartAPI = async (product, index, finalQty) => {
  try {
    const response = await axiosCustom.put(UPDATE_CART, {
      qty: {
        [product.vendors[index].item_id]: finalQty,
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteItemFromCartAPI = async (product, index) => {
  try {
    const response = await axiosCustom.delete(
      REMOVE_PRODUCT_FROM_CART + "/" + product.vendors[index].item_id
    );
    return response;
  } catch (e) {
    throw e;
  }
};


export const saveCartAPI = async () => {
  try {
    const response = await axiosCustom.post(SAVE_CART);
    return response;
  } catch (e) {
    throw e;
  }
};
export const applyPromoCodeAPI = async (code) => {
  try {
    const response = await axiosCustom.post(PROMO_CODE, {
      code: code,
    });
    return response;
  } catch (e) {
    throw e;
  }
};
export const deletePromoCodeAPI = async () => {
  try {
    const response = await axiosCustom.delete(PROMO_CODE);
    return response;
  } catch (e) {
    throw e;
  }
};

export const placeOrderAPI = async (payment, shipping, comment, rewardPoints) => {
  try {
    const response = await axiosCustom.post(PLACE_ORDER,{
      payment: payment,
      shipping: shipping,
      comment: comment,
      reward_points: rewardPoints ? rewardPoints : 0
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const getItemId = (product, vendorIndex, newCart) => {
  const vendorId = product.vendors[vendorIndex].vendor_id;
  const item = newCart.items.find((item) => item.vendor_id === vendorId);
  const itemId = item.items.find((item) => item.product_id === product.id).id;
  return itemId;
};

export const getAvailableQty = (productIn, vendorId) => {
  const product = productIn.product
  const available_qty =  product.vendors.find((vendor) => vendor.vendor_id === vendorId).available_qty
  return parseInt(available_qty);
}

export const getVendorIndex = (productIn, vendorId) => {
  const product = productIn.product
  const index =  product.vendors.findIndex((vendor) => vendor.vendor_id === vendorId)
  return parseInt(index);
}

export const getVendorIndexInCart = (cartDetails, vendorId) => {
  const vendors = cartDetails.items
  const index =  vendors.findIndex((vendor) => vendor.vendor_id === vendorId)
  return parseInt(index);
}
export const getProductIndexInCart = (products, productId) => {
  const index =  products.findIndex((product) => product.product_id === productId)
  return parseInt(index);
}