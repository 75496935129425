import React, { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import { axiosCustom } from "../endpoints/Axios";
import { GET_CART_DETAILS } from "../endpoints/Constants";

export const CartContext = createContext();

const CartContextProvider = (props) => {
  const [cart, setCart] = useState(null);
  const profile = useContext(ProfileContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profile.connected) {
      setLoading(true);
      axiosCustom
      .get(GET_CART_DETAILS)
      .then((res) => {
        setCart(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
          setLoading(false);
        });
    }
  }, [profile.connected]);

  return (
    <CartContext.Provider
      value={{
        setCart: setCart,
        cart: cart,
        loading: loading,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
