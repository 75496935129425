import { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import SignupVendorForm from "./SignupVendorForm";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../../context/ProfileContext";
import SwipeableViews from "react-swipeable-views";
import { ConfirmationPageVendor } from "./ConfirmationPageVendor";

export default function SignupVendor(props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const profile = useContext(ProfileContext);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.openDialog.current = handleClickOpen;
  });

  const labelStyle = {
    color: "primary.gray",
  };

  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (profile.connected) {
      if (parseInt(profile.data.vendor_status) === 0) {
        setActiveStep(1);
      } else {
        setActiveStep(0);
      }
    }
  }, [profile]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const nextStep = () => {
    handleStepChange(activeStep + 1);
    const dialog = document.getElementById("signup-vendor-dialog-main-content");
    dialog.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    dialog.style.overflow = "none";
  };

  return (
    <div>
      {!profile.connected ? null : (
        <Dialog
          id="signup-vendor-dialog-main"
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
        >
          <Box
            id={"dialog-container"}
            width={{ xs: "80vw", sm: 500 }}
            sx={{
              overflowX: "hidden",
              overflowY: activeStep === 0 ? "scroll" : "hidden",
            }}
          >
            <DialogContent
              sx={{ padding: "0", width: "100%" }}
              id="signup-vendor-dialog-main-content"
            >
              <DialogTitle
                color="text.dark"
                sx={{
                  fontSize: { xs: "5.5vw", md: "2rem" },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {t("vendor_signup")}
              </DialogTitle>

              <SwipeableViews
                axis={theme.lang.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                disableMouseEvents
              >
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pb: 2,
                      direction: theme.lang.direction,
                    }}
                  >
                    <Box
                      sx={{
                        ml: 2,
                        mr: 2,
                      }}
                    >
                      <Typography sx={labelStyle}>{t("name")}:</Typography>
                      <Typography sx={labelStyle}>
                        {t("phone_number")}:
                      </Typography>
                      <Typography sx={labelStyle}>
                        {t("store_name")}:
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        ml: 2,
                        mr: 2,
                      }}
                    >
                      <Typography>{profile.data.name}</Typography>
                      <Typography>{profile.data.phone}</Typography>
                      <Typography>{profile.data.store_name}</Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <SignupVendorForm setopen={setOpen} nextStep={nextStep} />
                </Box>

                <ConfirmationPageVendor />
              </SwipeableViews>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </div>
  );
}
