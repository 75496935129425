import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import ConfirmationImg from "../../../assets/confirmation_artwork.png"
export const ConfirmationPage = () => {
    const { t } = useTranslation();

  return (
    <Box sx={{
        textAlign:"center",
        height:480,
    }}>
        <Box minHeight={"60%"} sx={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        }}>

            <img src={ConfirmationImg} alt="..." width={"80%"} loading="lazy"/>
        </Box>
        <Typography sx={{
            mt:2,
            mb:1,
            color: "primary.main",
            fontSize: {xs:"1rem", md:"1.2rem"},
            fontWeight: "bold"
        }}>
            {t("registration_confirmation_title")}
        </Typography>

        <Typography 
            sx={{
                fontSize:{xs:"0.8rem", md:"0.9rem"}
            }}
        >
            {t("registration_confirmation_message1")}
        </Typography>
        <Typography 
            sx={{
                fontSize:{xs:"0.8rem", md:"0.9rem"}
        
            }}
        >
            {t("registration_confirmation_message2")}
        </Typography>
    </Box>
  )
}
