import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import emartLogoBtn from "../../assets/adaptive_fg.png";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CustomizedSnackbars from "../../ui-common-components/SnackBar";
import {
  addToCartAPI,
  deleteItemFromCartAPI,
  getItemId,
  updateCartAPI,
} from "./Functions";
import { CartContext } from "../../context/CartContext";

export const CartControl = (props) => {
  const { t } = useTranslation();
  const cartContext = useContext(CartContext);

  const product = props.product;
  const setProduct = props.setProduct;
  const index = props.index;

  const [currentQty, setCurrentQty] = React.useState(
    props.product.vendors[index].qty_in_cart
  );

  const [loadingCart, setLoadingCart] = React.useState(false);
  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");// eslint-disable-line
  const [snackBarSevirity, setSnackBarSevirity] = React.useState("");// eslint-disable-line

  const setError = (message) => {
    let errorsTemp = props.errors
      errorsTemp[props.index] = message
      props.setErrors([...errorsTemp]);
  }
  const addToCart = async (vendorIndex) => {
    setError("");
    setLoadingCart(true);
    const qty = product.min_qty ? product.min_qty : 1;
    try {
      const res = await addToCartAPI(product, vendorIndex, qty);
      const itemId = getItemId(product, vendorIndex, res.data.data);
      product.vendors[vendorIndex].qty_in_cart = qty;
      product.vendors[vendorIndex].item_id = itemId;
      setProduct({ ...product });
      setCurrentQty(qty);
      cartContext.setCart(res.data.data);
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
      setError(err.response.data.message);
      /*setSnackBarMessage(err.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();*/
    }
  };

  const updateTimeoutRef = useRef(null);

  const updateCartRequest = async (finalQty) => {
    setError("");
    setLoadingCart(true);
    try {
      const res = await updateCartAPI(product, index, finalQty);
      product.vendors[index].qty_in_cart = finalQty;
      setProduct({ ...product });
      cartContext.setCart(res.data.data);
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
      setError(err.response.data.message);
      /*setSnackBarMessage(err.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();*/
    }
  };

  const removeFromCart = async () => {
    setError("");
    setLoadingCart(true);
    try {
      const res = await deleteItemFromCartAPI(product, index);
      product.vendors[index].qty_in_cart = 0;
      setProduct({ ...product });
      cartContext.setCart(res.data.data);
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
      setError(err.response.data.message);
      /*setSnackBarMessage(err.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();*/
    }
  };

  const updateCart = (qty, type) => {
    let currentQtyValue =parseInt(currentQty)
    let qtyValue =parseInt(qty)
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    if (type === "input") {
      setCurrentQty(qtyValue);
    } else {
      setCurrentQty(currentQtyValue + qtyValue);
    }

    if ((type === "input" && qtyValue === 0) || currentQtyValue + qtyValue === 0) {
      updateTimeoutRef.current = setTimeout(() => {
        removeFromCart();
      }, 1000);
    } else {
      updateTimeoutRef.current = setTimeout(() => {
        if (type === "input") {
          updateCartRequest(qtyValue);
        } else {
          updateCartRequest(currentQtyValue + qtyValue);
        }
      }, 1000);
    }
  };

  const controlBtnStyle = {
    color: "primary.main",
    borderColor: "primary.gray",
    p: 0,
    minWidth: "100%",
    borderRadius: "3px",
  };

  return (
    <Box>
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />

      {loadingCart ? (
        <Button disabled>
          <CircularProgress size={20} />
        </Button>
      ) : parseInt(product.vendors[index].qty_in_cart) !== 0 ? (
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Button
              //disabled={parseInt(currentQty) === (parseInt(product.min_qty) ? parseInt(product.min_qty) : 0)}
              onClick={() => {
                if(parseInt(currentQty) === parseInt(product.min_qty)){
                  //console.log(product.min_qty)
                  updateCart(-1*parseInt(product.min_qty))
                }
                else{
                  updateCart(-1)
                }
              }}
              variant="outlined"
              sx={controlBtnStyle}
            >
              <RemoveIcon />
            </Button>
          </Grid>
          <Grid item xs={4}>
            {/*<Typography
              sx={{
               
              }}
            >
              {currentQty}
            </Typography>*/}
            <TextField
              variant="outlined"
              value={currentQty}
              onChange={(e) => {
                if (parseInt(e.target.value)) {
                  const max = product.max_qty
                    ? product.max_qty
                    : product.vendors[index].available_qty;
                  const min = product.min_qty ? product.min_qty : 0;
                  if (
                    parseInt(e.target.value) <= max &&
                    parseInt(e.target.value) >= min
                  ) {
                    updateCart(parseInt(e.target.value), "input");
                  } else {
                    setError(t("invalid_qty"));
                    /*setSnackBarMessage(t("invalid_qty"));
                    setSnackBarSevirity("error");
                    openSnackBar.current();*/
                  }
                } else {
                  updateCart(0, "input");
                }
              }}
              type="number"
              inputProps={{
                style: { textAlign: "center" },
              }}
              sx={{
                textAlign: "center",

                ".MuiOutlinedInput-input ": {
                  padding: "0.1rem 0.2rem",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.gray",
                },

                ":hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={
                parseInt(currentQty) ===
                (parseInt(product.max_qty)
                  ? parseInt(product.max_qty)
                  : parseInt(product.vendors[index].available_qty))
              }
              onClick={() => {
                const min = product.min_qty ? product.min_qty : 0;
                if(currentQty < min){
                  updateCart(min)
                }
                else{
                  updateCart(1);
                }
              }}
              variant="outlined"
              sx={controlBtnStyle}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Button
          onClick={() => {
            addToCart(index);
          }}
          sx={{
            color: "primary.light",
            backgroundColor: "primary.main",
            borderRadius: "3px",
            p: {
              xs: "0.4rem",
              sm: "0.4rem 0.2rem",
              md: "0.4rem",
              lg: "0.32rem",
            },

            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "primary.border",
            },
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontSize: {
                xs: "0.7rem",
                sm: "0.8rem",
                md: "0.7rem",
                lg: "0.71rem",
              },
              ml: { xs: 0.5, sm: 0.5 },
              mr: { xs: 0.5, sm: 0.5 },
            }}
          >
            {t("add_to_cart")}
          </Typography>
          <Box
            sx={{
              width: { xs: "20px", sm: "25px", md: "25px", lg: "19px" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={emartLogoBtn} alt="..." width="100%" loading="lazy" />
          </Box>
        </Button>
      )}
    </Box>
  );
};
