import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CustomizedSnackbars from "../../ui-common-components/SnackBar";
import {
  deleteItemFromCartAPI,
  getAvailableQty,
  getVendorIndex,
  updateCartAPI,
} from "./Functions";
import { CartContext } from "../../context/CartContext";
import { useTranslation } from "react-i18next";

export const MyCartControl = (props) => {
  const { t } = useTranslation();

  const cartContext = useContext(CartContext);
  const cartDetails = cartContext.cart;
 
  //const savedCart = props.savedCart;
 // const setSavedCart = props.setSavedCart;

  const index = props.index;
  const subIndex = props.subIndex;
  const product = cartDetails.items[index].items[subIndex];
  const vendorId = cartDetails.items[index].vendor_id;
  const [currentQty, setCurrentQty] = React.useState(product.quantity);

  const updatingCartLoading = props.updatingCartLoading;

  useEffect(() => {
    setCurrentQty(product.quantity);
  }, [cartDetails]); // eslint-disable-line
  const [loadingCart, setLoadingCart] = React.useState(false);
  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");// eslint-disable-line
  const [snackBarSevirity, setSnackBarSevirity] = React.useState("");// eslint-disable-line

  const updateTimeoutRef = useRef(null);
  const setError = (message) => {
    let errorsTemp = props.errors
      errorsTemp[props.index][props.subIndex] = message
      props.setErrors([...errorsTemp]);
  }

  const updateCartRequest = async (finalQty) => {
    setError("");
    setLoadingCart(true);
    try {
      const res = await updateCartAPI(
        product.product,
        getVendorIndex(product, vendorId),
        finalQty
      );
      cartContext.setCart({ ...res.data.data });
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
      setError(err.response.data.message);
      /*setSnackBarMessage(err.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();*/
    }
  };

  const removeFromCart = async () => {
    setError("");
    setLoadingCart(true);
    try {
      const res = await deleteItemFromCartAPI(
        product.product,
        getVendorIndex(product, vendorId)
      );
      if (res.data.data) {
        cartContext.setCart({ ...res.data.data });
      } else {
        cartContext.setCart(null);
       // setSavedCart(null);
      }
      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
      setError(err.response.data.message);
      /*setSnackBarMessage(err.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();*/
    }
  };

  const updateCart = (qty, type) => {
    let currentQtyValue =parseInt(currentQty)
    let qtyValue =parseInt(qty)

    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    if (type === "input") {
      setCurrentQty(qtyValue);
    } else {
      setCurrentQty(currentQtyValue + qtyValue);
    }

    if ((type === "input" && qtyValue === 0) || currentQtyValue + qtyValue === 0) {
      updateTimeoutRef.current = setTimeout(() => {
        removeFromCart();
      }, 1000);
    } else {
      updateTimeoutRef.current = setTimeout(() => {
        if (type === "input") {
          updateCartRequest(qtyValue);
        } else {
          updateCartRequest(currentQtyValue + qtyValue);
        }
      }, 1000);
    }
  };

  const controlBtnStyle = {
    color: "primary.main",
    borderColor: "primary.gray",
    p: 0,
    minWidth: "100%",
    borderRadius: "3px",
  };
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />

      {loadingCart ? (
        <Button disabled>
          <CircularProgress size={19} />
        </Button>
      ) : parseInt(product.quantity) !== 0 ? (
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Button
              disabled={
                parseInt(currentQty) ===
                  (parseInt(product.product.min_qty) ? parseInt(product.product.min_qty) : 0) ||
                updatingCartLoading
              }
              onClick={() => {
                if(parseInt(currentQty) === parseInt(product.product.min_qty)){
                  updateCart(-1*parseInt(product.product.min_qty))
                }
                else{
                  updateCart(-1)
                }
              }}
              variant="outlined"
              sx={controlBtnStyle}
            >
              <RemoveIcon />
            </Button>
          </Grid>
          <Grid item xs={4}>
            {/*<Typography
              sx={{
                border: "1px solid",
                borderColor: "primary.border",
                borderRadius: "7px",
                textAlign: "center",
                color: "primary.gray",
                ":hover": {
                  color: "primary.gray",
                },
              }}
            >
              {currentQty}
            </Typography>*/}

            <TextField
              variant="outlined"
              value={currentQty}
              onChange={(e) => {
                if (parseInt(e.target.value)) {
                  const vendorTemp =
                    product.product.vendors[
                      getVendorIndex(product, product.vendor_id)
                    ];
                  const productTemp = product.product;
                  const max = productTemp.max_qty
                    ? productTemp.max_qty
                    : vendorTemp.available_qty;
                  const min = productTemp.min_qty ? productTemp.min_qty : 0;
                  if (
                    parseInt(e.target.value) <= max &&
                    parseInt(e.target.value) >= min
                  ) {
                    updateCart(parseInt(e.target.value), "input");
                  } else {
                    setError(t("invalid_qty"));
                    /*setSnackBarMessage(t("invalid_qty"));
                    setSnackBarSevirity("error");
                    openSnackBar.current();*/
                  }
                } else {
                  updateCart(0, "input");
                }
              }}
              type="number"
              inputProps={{
                style: { textAlign: "center" },
              }}
              sx={{
                textAlign: "center",

                ".MuiOutlinedInput-input ": {
                  padding: "0.1rem 0.2rem",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.gray",
                },

                ":hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={
                parseInt(currentQty) ===
                  (parseInt(product.product.max_qty)
                    ? parseInt(product.product.max_qty)
                    : getAvailableQty(product, vendorId)) || updatingCartLoading
              }
              onClick={() => {
                const min = product.product.min_qty ? product.product.min_qty : 0;
                if(currentQty < min){
                  updateCart(min)
                }
                else{
                  updateCart(1);
                }
              }}
              variant="outlined"
              sx={controlBtnStyle}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};
