import React, { createContext, Component } from "react";
import { USER_PROFILE } from "../endpoints/Constants";
import { axiosCustom } from "../endpoints/Axios";

export const ProfileContext = createContext();

class ProfileContextProvider extends Component {
  
  state = {
    isLoading: false,
    connected: false
  };

  componentDidMount = () => {
      if(localStorage.getItem("ematkn")){
        this.setState({ isLoading:true });
        axiosCustom
        .get(USER_PROFILE)
        .then((res) => {
  
          if (res.status === 200) {
            this.setState({ ...res.data, isLoading:false, connected: true });
          }
        })
        .catch((err) => {
          this.setState({ isLoading:false });
        });
      }
      
      
  };

  render() {
    return (
      <ProfileContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ProfileContext.Provider>
    );
  }
}

export default ProfileContextProvider;
