import { Box, Grid, Link, Skeleton, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductsSwiper from "../../ui-common-components/ProductsSwiper";
import { useTranslation } from "react-i18next";
import { TopSlider } from "./TopSlider";
import { FixedTopSlider } from "./FixedTopSlider";
import { getAllSliders, getRecentlyBought, getSliderLink } from "./Functions";
import { FlashSales } from "./FlashSales";
import { FeaturedAndNewProducts } from "./FeaturedAndNewProducts";
import { Bestsellers } from "./Bestsellers";

const Home = (props) => {
  const [topSliders, setTopSliders] = useState([]);
  const [fixedTopSliders, setFixedTopSliders] = useState([]);
  const [fixedBottomSliders, setFixedBottomSliders] = useState([]);
  const [slidersLoading, setSlidersLoading] = useState([]);
  
  const theme = useTheme();
  const { t } = useTranslation();

  const setSliders = (sliders) => {
    let top = [];
    let fixedTop = [];
    let fixedBottom = [];
    sliders.forEach((slider) => {
      if (slider.type === "top-slider") {
        top.push(slider);
      } else if (slider.type === "fixed-slider") {
        fixedTop.push(slider);
      } else {
        fixedBottom.push(slider);
      }
    });

    setTopSliders(top);
    setFixedTopSliders(fixedTop);
    setFixedBottomSliders(fixedBottom);
  };

  const fetchAllSliders = async () => {
    setSlidersLoading(true);
    const data = await getAllSliders();
    setSliders(data);
    setSlidersLoading(false);
  };

  const [recentlyBought, setRecentlyBought] = useState([]);
  const [recentlyBoughtLoading, setRecentlyBoughtLoading] = useState(true);

  const fetchRecentlyBought = async () => {
    setRecentlyBoughtLoading(true);
    const data = await getRecentlyBought();
    setRecentlyBought(data);
    setRecentlyBoughtLoading(false);
  };

  useEffect(() => {
    fetchAllSliders();
    fetchRecentlyBought();
    document.title = "eMart";
  }, []); // eslint-disable-line

  const [flashSalesExist, setFlashSalesExist] = useState(true);

  return (
    <>
      <Box
        sx={{
          height: "auto",
        }}
      >
        {/*Top slider*/}
        <Box sx={{
        minHeight:{ xs:"auto",lg:"450px"}
        }}>
          {slidersLoading ? (
            <Box
              sx={{
                width: "100%",
                direction: theme.lang.direction,
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"430px"}
                animation="wave"
              />
            </Box>
          ) : (
            <TopSlider topSliders={topSliders} />
          )}
        </Box>

        <Box
          sx={{
            mr: { xs: 0, md: 5 },
            ml: { xs: 0, md: 5 },
          }}
        >
          {/*Fixed Top slider*/}
          <Box marginTop={5}>
            <FixedTopSlider
              slidersLoading={slidersLoading}
              fixedTopSliders={fixedTopSliders}
            />
          </Box>

          <Grid
            container
            sx={{
              justifyContent: { xs: "center", lg: "space-between" },
              direction: theme.lang.direction,
            }}
            marginTop={0}
            spacing={5}
          >
            {flashSalesExist ? (
              <Grid item xs={11} sm={10} md={8}
               lg={3.5}>
                <FlashSales setFlashSalesExist={setFlashSalesExist} />
              </Grid>
            ) : null}

            <Grid item xs={11} //md={flashSalesExist ? 8 : 11} 
            lg={flashSalesExist ? 8.5 : 12}>
              <FeaturedAndNewProducts />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item xs={11} lg={12} sx={{
              marginTop:{xs:0, md:3}
            }} >
              {props.categoriesTree ? (
                <Bestsellers categoriesTree={props.categoriesTree} />
              ) : null}
            </Grid>

            {slidersLoading ? (
              <Grid item xs={11} sm={8} md={6} lg={5} marginTop={3}>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"200px"}
                  animation="wave"
                />
              </Grid>
            ) : (
              fixedBottomSliders &&
              fixedBottomSliders.map((slider, index) => {
                return (
                  <Grid
                    key={"bottom-" + index}
                    item
                    xs={11}
                    sm={8}
                    md={6}
                    lg={5}
                    marginTop={3}
                  >
                    {slider.clickable ? (
                      <Link href={getSliderLink(slider)}>
                        <img
                          src={slider.image_url}
                          alt={"..."}
                          width="100%"
                          loading="lazy"
                        />
                      </Link>
                    ) : (
                      <img
                        src={slider.image_url}
                        alt={"..."}
                        width="100%"
                        loading="lazy"
                      />
                    )}
                  </Grid>
                );
              })
            )}
            {!recentlyBoughtLoading && recentlyBought.length === 0 ? null : (
              <Grid item xs={11} md={12} marginTop={1}>
                <ProductsSwiper
                  loading={recentlyBoughtLoading}
                  data={{
                    title: t("recently_bought"),
                    products: recentlyBought,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Home;
