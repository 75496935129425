import {
  Box,
  Divider,
  Grid,
  Typography,
  Link,
  useTheme,
  TextField,
  Button,
  CircularProgress,
  Skeleton,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../context/CartContext";
import SummarizeIcon from "@mui/icons-material/Summarize";
import emartLogo from "../../assets/logo2.png";
import {
  applyPromoCodeAPI,
  deletePromoCodeAPI,
  placeOrderAPI,
  saveCartAPI,
  getVendorIndex,
  deleteItemFromCartAPI,
  getVendorIndexInCart,
  getProductIndexInCart,
} from "./Functions";
import {
  Delete,
  ExpandLess,
  LocalShipping,
  Loyalty,
} from "@mui/icons-material";
import CustomizedSnackbars from "../../ui-common-components/SnackBar";
import { useNavigate } from "react-router-dom";
import { MyCartControl } from "./MyCartControl";
import AlertDialogSlide from "../../ui-common-components/AlertDialogSlide";
import { getRegularPrice } from "../products/Functions";
import "./styles.css";
import RedeemIcon from "@mui/icons-material/Redeem";
import { getRewardPointsSummary } from "../profile/reward-points/Functions";

export const MyCart = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const cartContext = useContext(CartContext);
  const cartDetails = cartContext.cart;

  const [savedCart, setSavedCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatingCartLoading, setUpdatingCartLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [code, setCode] = useState("");
  const openAlertDialog = useRef(null);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertAction, setAlertAction] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const [alertMode, setAlertMode] = useState(null);

  const [currentState, setCurrentState] = useState("saving");

  const shippingDetails = [
    {
      title: t("name"),
      value: savedCart
        ? savedCart.shipping_address.first_name +
          " " +
          savedCart.shipping_address.last_name
        : "-",
    },
    {
      title: t("store_name"),
      value: savedCart ? savedCart.shipping_address.store_name : "-",
    },
    {
      title: t("phone_number"),
      value: savedCart ? savedCart.shipping_address.phone : "-",
    },
    {
      title: t("city"),
      value: savedCart ? savedCart.shipping_address.state_name : "-",
    },
    {
      title: t("district"),
      value: savedCart ? savedCart.shipping_address.zone_name : "-",
    },
    {
      title: t("address"),
      value: savedCart ? savedCart.shipping_address.address1[0] : "-",
    },
  ];

  const orderSummary = [
    {
      title: t("products_quantity"),
      value: savedCart ? parseInt(savedCart.items_qty) : 0,
    },
    {
      title: t("sub_total"),
      value: savedCart ? savedCart.formated_sub_total + " " + t("egp") : 0,
    },
    {
      title: t("shipping_cost"),
      value:
        savedCart && savedCart.selected_shipping_rate
          ? savedCart.selected_shipping_rate.formated_price + " " + t("egp")
          : 0,
    },
    {
      title: t("discount_amount"),
      value: savedCart ? savedCart.formated_discount + " " + t("egp") : 0,
    },
  ];

  const titleStyle = {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: "primary.dark",
    mb: 2,
    mt: 2,
  };

  const [saveCartLoading, setSaveCartLoading] = useState(false);

  const [errors, setErrors] = useState([]);

  const saveCart = async () => {
    setSaveCartLoading(true);
    try {
      const response = await saveCartAPI();
      setSavedCart(response.data.data);
      cartContext.setCart(response.data.data);
      setSaveCartLoading(false);
      setCurrentState("submitting");
    } catch (e) {
      if(e.response.status === 500){
        setSnackBarMessage(e.response.data.message);
        setSnackBarSevirity("error");
        openSnackBar.current();
      }
      else{
        try {
          
          for (let index = 0; index < errors.length; index++) {
            for (let subIndex = 0; subIndex < errors[index].length; subIndex++) {
              errors[index][subIndex] = "";
            }
          }
    
          e.response.data.errors.forEach((error) => {
            const index = getVendorIndexInCart(cartDetails, error.vendor_id);
            if (index !== -1) {
              const subIndex = getProductIndexInCart(
                cartDetails.items[index].items,
                error.product_id
              );
              if (subIndex !== -1) {
                errors[index][subIndex] = error.message;
              }
            }
          });
    
          setErrors([...errors]);
        } catch (error) {
          setSnackBarMessage(t("something_went_wrong"));
          setSnackBarSevirity("error");
          openSnackBar.current();
        }
      }

      setSaveCartLoading(false);
    }
  };

  /*const fetchSavedCartWithLoading = async () => {
    setLoading(true);
    await saveCart();
    setLoading(false);
  };
  const fetchSavedCartWithoutLoading = async () => {
    setUpdatingCartLoading(true);
    await saveCart();
    setUpdatingCartLoading(false);
  };*/

  useEffect(() => {
    /*if (!cartContext.loading && cartDetails && !savedCart) {
      fetchSavedCartWithLoading();
    } else if (!cartContext.loading && cartDetails && savedCart) {
      fetchSavedCartWithoutLoading();
    } else*/ if (!cartContext.loading) {
      setLoading(false);
    }
  }, [cartContext]); // eslint-disable-line

  const [wantToApplyCode, setWantToApplyCode] = useState(false);
  const [applyPromoCodeLoading, setApplyPromoCodeLoading] = useState(false);

  const handleApplyCode = async () => {
    if (code === "") {
      setSnackBarMessage(t("empty_promo_code"));
      setSnackBarSevirity("error");
      openSnackBar.current();
      return;
    } else {
      try {
        setApplyPromoCodeLoading(true);
        const response = await applyPromoCodeAPI(code);
        setSnackBarMessage(response.data.message);
        if (response.data.success) {
          saveCart();
          setSnackBarSevirity("success");
        } else {
          setSnackBarSevirity("error");
        }
      } catch (e) {
        setSnackBarMessage(e.response.data.message);
        setSnackBarSevirity("error");
      }
      setApplyPromoCodeLoading(false);
      openSnackBar.current();
    }
  };

  const handleDeleteCode = async () => {
    try {
      setApplyPromoCodeLoading(true);
      const response = await deletePromoCodeAPI();
      setSnackBarMessage(response.data.message);
      if (response.data.success) {
        saveCart();
        setSnackBarSevirity("success");
      } else {
        setSnackBarSevirity("error");
      }
    } catch (e) {
      setSnackBarMessage(e.response.data.message);
      setSnackBarSevirity("error");
    }
    setApplyPromoCodeLoading(false);
    openSnackBar.current();
  };

  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");

  const navigate = useNavigate();
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);

  const openAlert = (mode) => {
    if (mode === "submitOrder") {
      setAlertMsg(t("submit_order_confirmation"));
      setAlertAction(t("submit_order"));
      setAlertTitle(t("submit_order"));
      openAlertDialog.current();
    }
  };

  const confirmAlert = () => {
    if (alertMode === "submitOrder") {
      placeOrder();
    }
  };

  const placeOrder = async () => {
    setPlaceOrderLoading(true);
    try {
      let rewardPoints = 0
      
      if(rewardPointsValue){
        rewardPoints = parseFloat(rewardPointsValue) / parseFloat(balancePointsData.conversion_rate)
      }

      await placeOrderAPI(
        "cashondelivery",
        "standardshipping_standardshipping",
        comment,
        rewardPoints
      );
      cartContext.setCart(null);
      navigate("/profile/orders");
      setPlaceOrderLoading(false);
    } catch (error) {
      setSnackBarMessage(error.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();

      setPlaceOrderLoading(false);
    }
  };

  const handleDeleteItem = async (product, vendor_id, index, subIndex) => {
    setUpdatingCartLoading(true);
    try {
      const res = await deleteItemFromCartAPI(
        product.product,
        getVendorIndex(product, vendor_id)
      );
      if (res.data.data) {
        cartContext.setCart({ ...res.data.data });
      } else {
        cartContext.setCart(null);
        setSavedCart(null);
      }
      setUpdatingCartLoading(false);
    } catch (err) {
      errors[index][subIndex] = err.response.data.message;
      setErrors([...errors]);
      /*setSnackBarMessage(err.response.data.message);
      setSnackBarSevirity("error");
      openSnackBar.current();*/
      setUpdatingCartLoading(false);
    }
  };

  const [rewardPointsValue, setRewardPointsValue] = useState("");
  const [loadingBalancePointsSummary, setLoadingBalancePointsSummary] = useState(false)
  const [balancePointsData, setBalancePointsData] = useState(null)

  const fetchRewardPointsSummary = async () => {
    try {
      setLoadingBalancePointsSummary(true);
      const data = await getRewardPointsSummary();
      setBalancePointsData(data);
      setLoadingBalancePointsSummary(false);
    } catch (error) {
      setBalancePointsData(null);
    }
  };

  useEffect(()=>{
    fetchRewardPointsSummary()
  },[])

  return (
    <>
      <AlertDialogSlide
        confirmAlertRef={confirmAlert}
        openAlertDialog={openAlertDialog}
        title={alertTitle}
        description={alertMsg}
        actionText={alertAction}
      />

      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />
      {loading ? (
        <Box
          sx={{
            mt: 3,
            direction: theme.lang.direction,
            minHeight: "50vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              sx={{
                width: { xs: "100%", md: "95%", lg: "90%" },
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <Grid item xs={11.5} lg={8} marginBottom="0rem">
                <Skeleton variant="rectangular" height={"70vh"} width="100%" />
              </Grid>
              <Grid item xs={11.5} lg={3.5}>
                <Skeleton variant="rectangular" height={"30vh"} width="100%" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : !cartDetails || parseInt(cartDetails.items_qty) === 0 ? (
        <Box
          sx={{
            mt: 3,
            direction: theme.lang.direction,
            minHeight: "50vh",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              p: 2,
              pt: 0,
              color: "primary.main",
            }}
          >
            {t("cart_empty")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.3rem",
              p: 2,
              pt: 0,
            }}
          >
            {t("cart_empty_sub_title")}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            mt: 3,
            direction: theme.lang.direction,
            minHeight: "60vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              sx={{
                width: { xs: "100%", md: "95%", lg: "90%" },
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <Grid item xs={11.5} lg={8} marginBottom="0rem">
                <Accordion
                  elevation={0}
                  sx={{ p: 0, width: "100%" }}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandLess />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      p: 0,
                      minHeight: 30,
                      maxHeight: 30,
                      "&.Mui-expanded": {
                        minHeight: 30,
                        maxHeight: 30,
                      },
                    }}
                    /*sx={{
                  borderBottom: "1px solid #BCBCBC",
                  p: "1.2rem 0",
                  minHeight: 30,
                  maxHeight: 30,
                  "&.Mui-expanded": {
                    minHeight: 30,
                    maxHeight: 30,
                  },
                }}*/
                  >
                    <Typography sx={titleStyle}>
                      {"1-"} {t("review_items")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Box>
                      {cartDetails &&
                        cartDetails.items.map((item, index) => {
                          errors.push(Array(item.items.length).fill(""));

                          return (
                            <Box key={"item-" + index}>
                              {item.items.map((product, subIndex) => {
                                const regularPrice = getRegularPrice(
                                  product.product
                                );
                                return (
                                  <Box
                                    key={"item-" + index + "-" + subIndex}
                                    sx={{ pt: 1 }}
                                  >
                                    <Link
                                      href={
                                        "/product/" +
                                        product.product.url_key +
                                        "/" +
                                        product.product.sku +
                                        "?id=" +
                                        product.product.id
                                      }
                                      underline="none"
                                    >
                                      <Grid
                                        container
                                        justifyContent={"space-evenly"}
                                      >
                                        <Grid
                                          item
                                          xs={11.8}
                                          sx={{
                                            height: 0,
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          {currentState === "saving" ? (
                                            <IconButton
                                              disabled={updatingCartLoading}
                                              sx={{
                                                color: "primary.gray",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "end",
                                                fontSize: "0.9rem",
                                                fontWeight: "bold",
                                                textTransform: "capitalize",
                                                position: "relative",
                                                top: "-10px",
                                                //left: { xs: "92%", md: "94.5%" },
                                                p: 1,
                                                height: "40px",
                                                //bottom: { xs: "20px", md: "40px" },
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                const vendor_id =
                                                  cartDetails.items[index]
                                                    .vendor_id;
                                                handleDeleteItem(
                                                  product,
                                                  vendor_id,
                                                  index,
                                                  subIndex
                                                );
                                              }}
                                            >
                                              <Delete
                                                sx={{ color: "#979797" }}
                                              />
                                            </IconButton>
                                          ) : null}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sm={2}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            id="myCartProductImg"
                                            src={
                                              product.product.base_image
                                                .medium_image_url
                                            }
                                            alt="..."
                                            loading="lazy"
                                          />
                                        </Grid>
                                        <Grid item xs={9} md={10}>
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontSize: "0.9rem",
                                                color: "black",
                                                fontWeight: "bold",
                                                textDecoration: "none",
                                                height: "45px",
                                                width: "95%",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                display: "-webkit-box",
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: "vertical",
                                                textAlign: theme.lang.textAlign,
                                              }}
                                            >
                                              {product.product.name}
                                            </Typography>
                                            <Grid
                                              container
                                              sx={{
                                                alignItems: "end",
                                              }}
                                            >
                                              <Grid item xs={7}>
                                                <Box
                                                  sx={{
                                                    mt: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      color: "primary.main",
                                                      fontWeight: "bold",
                                                      fontSize: "0.9rem",
                                                    }}
                                                  >
                                                    {product.formated_price}
                                                    <Typography
                                                      component={"span"}
                                                      sx={{
                                                        fontSize: "inherit",
                                                        fontWeight: 400,
                                                      }}
                                                    >
                                                      {" " + t("egp")}
                                                    </Typography>
                                                  </Typography>
                                                  <Typography
                                                    sx={{
                                                      color: "primary.gray",
                                                      fontWeight: "bold",
                                                      fontSize: "0.7rem",
                                                      textDecoration:
                                                        "line-through",
                                                      mr: 1,
                                                      ml: 1,
                                                    }}
                                                  >
                                                    {}
                                                    {regularPrice}{" "}
                                                    {regularPrice ? (
                                                      <Typography
                                                        component={"span"}
                                                        sx={{
                                                          fontSize: "inherit",
                                                          fontWeight: 400,
                                                        }}
                                                      >
                                                        {" " + t("egp")}
                                                      </Typography>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    width: {
                                                      xs: "60%",
                                                      sm: "40%",
                                                      md: "30%",
                                                    },
                                                  }}
                                                >
                                                  <Box
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                    }}
                                                    sx={{
                                                      width: "100%",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      mt: 1,
                                                    }}
                                                  >
                                                    {currentState ===
                                                    "saving" ? (
                                                      <MyCartControl
                                                        //savedCart={cartDetails}
                                                        //setSavedCart={setSavedCart}
                                                        index={index}
                                                        subIndex={subIndex}
                                                        updatingCartLoading={
                                                          updatingCartLoading
                                                        }
                                                        errors={errors}
                                                        setErrors={setErrors}
                                                      />
                                                    ) : null}
                                                  </Box>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={5}
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    //mt: 1,
                                                    color: "primary.dark",
                                                    fontSize: "0.9rem",
                                                    textAlign: "end",
                                                    mr: 1,
                                                    ml: 1,
                                                  }}
                                                >
                                                  {t("seller")}:
                                                </Typography>
                                                {parseInt(item.vendor_id) ===
                                                0 ? (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "end",
                                                    }}
                                                  >
                                                    <Box
                                                      sx={{
                                                        width: {
                                                          xs: "50px",
                                                          lg: "70px",
                                                        },
                                                      }}
                                                    >
                                                      <img
                                                        width={"100%"}
                                                        src={emartLogo}
                                                        alt="..."
                                                        loading="lazy"
                                                      />
                                                    </Box>
                                                  </Box>
                                                ) : (
                                                  <Typography
                                                    sx={{
                                                      //mt: 0.5,
                                                      color: "primary.dark",
                                                      fontSize: "0.9rem",
                                                      textAlign: "end",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.vendor_code}
                                                  </Typography>
                                                )}
                                              </Grid>

                                              <Grid item xs={12}>
                                                <Typography
                                                  sx={{
                                                    color: "red",
                                                    fontSize: "0.7rem",
                                                    textAlign: "start",
                                                    fontWeight: 600,
                                                    mt: 1,
                                                  }}
                                                >
                                                  {errors[index][subIndex]}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Link>
                                    <Divider sx={{ mb: 1, mt: 1 }} />
                                  </Box>
                                );
                              })}

                              {currentState === "saving" ? null : (
                                <Typography
                                  sx={{
                                    color: "primary.gray",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    m: 2,
                                  }}
                                >
                                  <LocalShipping
                                    sx={{ ml: 1, mr: 1, color: "primary.main" }}
                                  />
                                  {t("shipping_price") +
                                    ": " +
                                    (savedCart.items[index]
                                      .selected_shipping_rate
                                      ? savedCart.items[index]
                                          .selected_shipping_rate.formated_price
                                      : "-") +
                                    " " +
                                    t("egp")}
                                </Typography>
                              )}
                              {cartDetails.items.length !== 1 &&
                                index !== cartDetails.items.length - 1 && (
                                  <Box
                                    sx={{
                                      backgroundColor: "primary.mainLight",
                                      p: 2,
                                      mb: 1,
                                    }}
                                  ></Box>
                                )}
                            </Box>
                          );
                        })}
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {currentState !== "saving" ? (
                  <>
                    <Divider
                      sx={{
                        mt: { xs: 1, md: 2 },
                        mb: { xs: 0, md: 2 },
                        borderColor: "primary.border",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "top",
                      }}
                    >
                      <Typography sx={titleStyle} width="40%">
                        {"2-"} {t("shipping")}
                      </Typography>
                      <Box
                        sx={{
                          width: { xs: "100%", md: "80%" },
                          mb: 2,
                          mt: { xs: 0, md: 2 },
                        }}
                      >
                        {shippingDetails &&
                          shippingDetails.map((item, index) => {
                            return (
                              <Box
                                key={"shipping-" + index}
                                sx={{ display: "flex", width: "100%" }}
                              >
                                <Typography
                                  sx={{
                                    width: { xs: "50%", md: "30%" },
                                    color: "primary.dark",
                                    fontWeight: 400,
                                  }}
                                >
                                  {item.title}:
                                </Typography>
                                <Typography sx={{ color: "primary.gray" }}>
                                  {item.value}
                                </Typography>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        mt: { xs: 1, md: 2 },
                        mb: { xs: 0, md: 2 },
                        borderColor: "primary.border",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "top",
                      }}
                    >
                      <Typography sx={titleStyle} width="40%">
                        {"3-"} {t("payment")}
                      </Typography>
                      <Box
                        sx={{
                          width: { xs: "100%", md: "80%" },
                          mb: 2,
                          mt: { xs: 0, md: 2 },
                        }}
                      >
                        <Box sx={{ display: "flex", width: "100%" }}>
                          <Typography
                            sx={{
                              width: { xs: "50%", md: "30%" },
                              color: "primary.dark",
                              fontWeight: 500,
                            }}
                          >
                            {t("cash_on_delivery")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        mt: { xs: 1, md: 2 },
                        mb: { xs: 0, md: 2 },
                        borderColor: "primary.border",
                      }}
                    />

                    <Typography sx={titleStyle}>
                      {"4-"} {t("add_comment")}
                    </Typography>
                    <Box sx={{ mb: 2, mt: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder={t("add_comment")}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </Box>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={11.5} lg={3.5}>
                {currentState === "saving" ? (
                  <Box
                    sx={{
                      backgroundColor: "primary.mainLight",
                      p: 2,
                      borderRadius: "7px",
                      mt: 2,
                      position: "sticky",
                      top: 20,
                      zIndex: 1,
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        p: 2,
                        pt: 0,
                        color: "primary.main",
                      }}
                    >
                      {t("confirmed_step")} <SummarizeIcon />
                    </Typography>
                      
                    <Button
                      variant="contained"
                      disabled={saveCartLoading}
                      sx={{
                        width: { xs: "50%", sm: "30%", md: "20%", lg: "50%" },
                        textTransform: "capitalize",

                        backgroundColor: "primary.main",
                        color: "primary.white",
                      }}
                      onClick={() => {
                        saveCart();
                      }}
                    >
                      {!saveCartLoading ? t("confirm_order_txt"):
                       <CircularProgress
                            size={24}
                            color="inherit"
                            sx={{ mr: 1, ml: 1 }}
                          />}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "primary.mainLight",
                      p: 2,
                      borderRadius: "7px",
                      mt: 2,
                      position: "sticky",
                      top: 20,
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        p: 2,
                        pt: 0,
                        color: "primary.main",
                      }}
                    >
                      {t("order_summary")} <SummarizeIcon />
                    </Typography>
                    {orderSummary.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "primary.gray",
                          }}
                        >
                          <Typography
                            sx={{
                              width: { xs: "60%", lg: "50%" },
                              color: "inherit",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{ color: "inherit", fontWeight: "bold" }}
                          >
                            {item.value}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Divider
                      sx={{ mt: 2, mb: 2, borderColor: "primary.border" }}
                    />

                    {savedCart.coupon_code ||
                    wantToApplyCode ||
                    applyPromoCodeLoading ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            p: 1,
                            pt: 0,
                            color: "primary.main",
                          }}
                        >
                          {t("promo_codes")} <Loyalty />
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: {
                              xs: "space-between",
                              sm: "flex-start",
                              lg: "space-between",
                            },
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder={t("enter_promo_code")}
                            value={
                              savedCart.coupon_code
                                ? savedCart.coupon_code
                                : code
                            }
                            disabled={savedCart.coupon_code}
                            onChange={(e) => setCode(e.target.value)}
                            sx={{
                              width: { xs: "60%", sm: "30%", lg: "60%" },
                              color: "primary.main",
                              ".MuiOutlinedInput-input ": { padding: "0.5rem" },
                            }}
                          />
                          <Button
                            disabled={
                              applyPromoCodeLoading || updatingCartLoading
                            }
                            variant="contained"
                            color={savedCart.coupon_code ? "error" : "primary"}
                            sx={{
                              width: { xs: "30%", sm: "20%", lg: "30%" },
                              textTransform: "capitalize",
                              ml: { xs: 0, sm: 2, lg: 0 },
                              mr: { xs: 0, sm: 2, lg: 0 },
                            }}
                            onClick={() => {
                              if (savedCart.coupon_code) {
                                if (!applyPromoCodeLoading) {
                                  handleDeleteCode();
                                }
                              } else {
                                handleApplyCode();
                              }
                            }}
                          >
                            {savedCart.coupon_code
                              ? t("remove")
                              : t("activate")}
                          </Button>
                        </Box>

                        <Typography
                          sx={{
                            color: "primary.main",
                            textDecoration: "underline",
                            fontSize: "0.9rem",
                            cursor: "pointer",
                            textAlign: "end",
                            mt: 1.5,
                          }}
                          onClick={() => {
                            if (savedCart.coupon_code) {
                              handleDeleteCode();
                            }
                            setWantToApplyCode(false);
                          }}
                        >
                          {t("want_to_use_points")}
                        </Typography>
                      </Box>
                    ) : null}

                    {!savedCart.coupon_code &&
                    !wantToApplyCode &&
                    !applyPromoCodeLoading ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            p: 1,
                            pt: 0,
                            color: "primary.main",
                          }}
                        >
                          {t("use_points_balance")} <RedeemIcon />
                        </Typography>

                        <Box sx={{
                          display:"flex",
                          alignItems:"center",
                          justifyContent:"center"
                        }}>
                          <TextField
                            variant="outlined"
                            value={rewardPointsValue}
                            placeholder="0"
                            disabled={loadingBalancePointsSummary}
                            onChange={(e) => {
                              if (parseFloat(e.target.value) || parseFloat(e.target.value) === 0) {
                                // savedCart.formated_grand_total
                                // balancePointsData.reward_points_value
                                const max = Math.min(parseFloat(savedCart.formated_grand_total), parseFloat(balancePointsData.reward_points_value)) ;
                                const min = 0;
                                if (
                                  parseFloat(e.target.value) <= max &&
                                  parseFloat(e.target.value) >= min
                                ) {
                                  setRewardPointsValue(parseFloat(e.target.value));
                                }
                              }
                              else{
                                setRewardPointsValue("");
                              }
                            }}
                            type="number"
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            sx={{
                              textAlign: "center",
                              width: "20%",
                              ".MuiOutlinedInput-input ": {
                                padding: "0.1rem 0.1rem",
                              },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "primary.gray",
                              },

                              ":hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "primary.main",
                                },
                              },
                            }}
                          />
                          <Typography sx={{
                            color:"primary.gray",
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            mr:1,
                            ml:1
                          }}>
                             {/*t("you_have") + " " + balancePointsData.reward_points_value + " " +*/ t("egp")}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            color: "primary.main",
                            textDecoration: "underline",
                            fontSize: "0.9rem",
                            cursor: "pointer",
                            textAlign: "end",
                            mt: 1.5,
                          }}
                          onClick={() => {
                            setWantToApplyCode(true);
                          }}
                        >
                          {t("have_promo_code")}
                        </Typography>
                      </Box>
                    ) : null}

                    <Divider
                      sx={{ mt: 2, mb: 2, borderColor: "primary.border" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "primary.main",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          width: { xs: "60%", lg: "50%" },
                          color: "inherit",
                          fontWeight: "inherit",
                          fontSize: "inherit",
                        }}
                      >
                        {t("total")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "inherit",
                          fontWeight: "inherit",
                          fontSize: "inherit",
                        }}
                      >
                        {savedCart.formated_grand_total + " " + t("egp")}
                      </Typography>
                    </Box>
                    <Divider
                      sx={{ mt: 2, mb: 2, borderColor: "primary.border" }}
                    />
                    <Box textAlign={"center"}>
                      {updatingCartLoading ? (
                        <Button
                          variant="contained"
                          disabled={updatingCartLoading}
                          sx={{
                            width: {
                              xs: "50%",
                              sm: "30%",
                              md: "20%",
                              lg: "50%",
                            },
                            textTransform: "capitalize",

                            backgroundColor: "primary.main",
                            color: "primary.white",
                          }}
                        >
                          {t("updating_cart")}
                          <CircularProgress
                            size={24}
                            color="inherit"
                            sx={{ mr: 1, ml: 1 }}
                          />
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled={placeOrderLoading}
                          sx={{
                            width: {
                              xs: "50%",
                              sm: "30%",
                              md: "20%",
                              lg: "50%",
                            },
                            textTransform: "capitalize",

                            backgroundColor: "primary.main",
                            color: "primary.white",
                          }}
                          onClick={() => {
                            setAlertMode("submitOrder");
                            openAlert("submitOrder");
                          }}
                        >
                          {t("submit_order")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};
