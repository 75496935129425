import {
  Avatar,
  Box,
  Card,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosCustom } from "../../endpoints/Axios";
import { GET_NOTIFICATIONS } from "../../endpoints/Constants";
import emartLogo from "../../assets/logo1.png";
import { useTranslation } from "react-i18next";


export const Notifications = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosCustom
      .get(GET_NOTIFICATIONS + "?page=1&limit=5")
      .then((res) => {
        setNotifications(res.data.notifications);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const getDate = (date) => {
    let newDate = new Date(date);
    return (
      newDate.toLocaleDateString() +
      " " +
      newDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  };
  const skeletonSize = Array(5).fill(0);

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontSize: "1.7rem",
          fontWeight: "bold",
          textAlign: "center",
          color: "primary.gray",
          mb: 2,
        }}
      >
        {t("notifications")}
      </Typography>
      {loading ? (
        skeletonSize.map((item, index) => (
          <Card
            key={index}
            sx={{
              p: "1rem 1.5rem",
              mb: 2,
            }}
          >
            <Grid container>
              <Grid item xs={2.5} sm={1} display="flex" alignItems={"center"}>
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs={9} sm={11}>
                <Skeleton variant="text" width={"100%"} height={20} />
                <Skeleton
                  variant="text"
                  width={"100%"}
                  height={20}
                  sx={{ mt: 0.5, mb: 0.5 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ textAlign: theme.lang.lang === "en" ? "right" : "left" }}
              >
                <Skeleton variant="text" width={"100%"} height={20} />
              </Grid>
            </Grid>
          </Card>
        ))
      ) : (
        <Box>
          {notifications.map((notification, index) => {
            return notification.data.title ? (
              <Card
                key={index}
                sx={{
                  backgroundColor: "primary.light",
                  p: "1rem 1.5rem",
                  borderRadius: "7px",
                  mb: 2,
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={2.5}
                    sm={1}
                    display="flex"
                    alignItems={"center"}
                  >
                    <Avatar src={emartLogo} sx={{}} />
                  </Grid>
                  <Grid item xs={9} sm={11}>
                    <Typography>{notification.data.title}</Typography>
                    <Typography
                      sx={{
                        color: "primary.gray",
                        fontSize: "0.9rem",
                        mt: 0.5,
                        mb: 0.5,
                      }}
                    >
                      {notification.data.body}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      color: "primary.gray",
                      direction: "ltr",
                      textAlign: theme.lang.lang === "en" ? "right" : "left",
                      fontSize: { xs: "0.7rem", sm: "0.9rem" },
                    }}
                  >
                    {getDate(notification.created_at)}
                  </Grid>
                </Grid>
              </Card>
            ) : null;
          })}
        </Box>
      )}
    </Box>
  );
};
