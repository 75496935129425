import { Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import emartLogo from "../../../assets/logo2.png";

import { CartControl } from "../../cart/CartControl";

export const VendorsTable = (props) => {
  const product = props.product;
  const setProduct = props.setProduct;
  const { t } = useTranslation();
  const theme = useTheme();
  const [errors, setErrors] = React.useState(Array(product.vendors.length).fill(""));
  function compare(a, b){
    if((parseFloat(a.formated_price) > parseFloat(b.formated_price)) || (parseFloat(a.formated_price) === parseFloat(b.formated_price) && b.vendor_id == 0)){
      return 1
    }
    else{
      return -1
    }
  }

  useEffect(()=>{
    //product.vendors.sort((a, b) => parseFloat(a.formated_price) - parseFloat(b.formated_price));
    product.vendors.sort(compare);
  },[props])
  return (
    <>
      <Grid
        container
        key={"vendor-title"}
        sx={{
          direction: theme.lang.direction,
          p: 0.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "primary.gray",
          mb: 1,
          fontSize: "0.9rem",
        }}
      >
        <Grid item xs={2}>
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "inherit",
            }}
          >
            {t("vendor")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "inherit",
            }}
          >
            {t("quantity")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "inherit",
            }}
          >
            {t("price")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "inherit",
            }}
          >
            {}
          </Typography>
        </Grid>
      </Grid>

      {product.vendors.map((vendor, index) => {
        //error.push("")
        //setError([...error])
        const regularPrice =
          vendor.formated_regular_price &&
          parseInt(vendor.formated_regular_price) !==
            parseInt(vendor.formated_price)
            ? vendor.formated_regular_price
            : "";
        return (
          <Grid
            container
            key={"vendor-" + index}
            sx={{
              direction: theme.lang.direction,
              backgroundColor: "primary.mainLight",
              p: "0.8rem 0.5rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "primary.gray",
              mb: 1,
              fontSize: "0.9rem",
            }}
          >
            <Grid item xs={2}>
              {vendor.vendor_id === 0 ? (
                <img src={emartLogo} width={"70px"} alt="..." loading="lazy" />
              ) : (
                <Typography
                  sx={{
                    color: "primary.main",
                    fontWeight: "bold",
                    fontSize: "inherit",
                  }}
                >
                  {vendor.vendor_code}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  color: "primary.dark",
                  fontWeight: "bold",
                  fontSize: "inherit",
                }}
              >
                {vendor.available_qty}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  fontSize: {xs:"0.8rem", md:"0.9rem"},
                }}
              >
                {vendor.formated_price}{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "inherit",
                    fontWeight: 400,
                  }}
                >
                  {" " + t("egp")}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: "primary.gray",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                  textDecoration: "line-through",
                }}
              >
                {}
                {regularPrice}{" "}
                {regularPrice ? (
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "inherit",
                      fontWeight: 400,
                    }}
                  >
                    {" " + t("egp")}
                  </Typography>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CartControl
                product={product}
                setProduct={setProduct}
                index={index}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "red",
                  fontSize: "0.7rem",
                  textAlign:"center",
                  fontWeight: 600
                }}
              >
                {errors[index]}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
