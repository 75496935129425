import React, { useRef } from "react";
import { createContext } from "react";
import ForgetPassword from "../components/auth/forget-password/ForgetPassword";
import Login from "../components/auth/login/Login";
import SignupVendor from "../components/auth/signup-vendor/SignupVendor";
import Signup from "../components/auth/signup/Signup";

export const AuthComponentsContext = createContext();

const AuthComponentsContextProvider = (props) => {
  const openLoginDialog = useRef(null);
  const openSignupDialog = useRef(null);
  const openForgetPasswordDialog = useRef(null);
  const openVendorSignupDialog = useRef(null);
 

  const hideAllExcept = (dialogName) => {
    const dialogIds = [
      "login-dialog-main",
      "signup-dialog-main",
      "forget-password-dialog-main",
      "signup-vendor-dialog-main",
    ];

    dialogIds.forEach((dialogId) => {
      if (dialogId !== dialogName) {
        try {
          document.getElementById(dialogId).style.display = "none";
        } catch (err) { }
    
      }
    });
  };
 

  const openLoginFunction = () => {
    try {
      hideAllExcept("login-dialog-main");
    } catch (err) { }

    openLoginDialog.current();

    try {
      document.getElementById("login-dialog-main").style.display = "block";
    } catch (err) { }
  };

  const openSignupFunction = () => {
    try {
      hideAllExcept("signup-dialog-main");
    } catch (err) { }

    openSignupDialog.current();

    try {
      document.getElementById("signup-dialog-main").style.display = "block";
    } catch (err) { }
  };

  const openForgetPasswordFunction = () => {
    try {
      hideAllExcept("forget-password-dialog-main");
    } catch (err) { }

    openForgetPasswordDialog.current();

    try {
      document.getElementById("forget-password-dialog-main").style.display = "block";
    } catch (err) { }
  };

  const openSignupVendorFunction = () => {
    try {
      hideAllExcept("signup-vendor-dialog-main");
    } catch (err) { }

    openVendorSignupDialog.current();

    try {
      document.getElementById("signup-vendor-dialog-main").style.display = "block";
    } catch (err) { }
  };

  return (
    <AuthComponentsContext.Provider
      value={{
        openLogin: openLoginFunction,
        openSignup: openSignupFunction,
        openForgetPassword: openForgetPasswordFunction,
        openSignupVendor: openSignupVendorFunction,
      }}
    >
    
      <Login openDialog={openLoginDialog} />
      <Signup openDialog={openSignupDialog} />
      <ForgetPassword openDialog={openForgetPasswordDialog} />
      <SignupVendor openDialog={openVendorSignupDialog} />
        
      {props.children}
    </AuthComponentsContext.Provider>
  );
};

export default AuthComponentsContextProvider;
