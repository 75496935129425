import { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from "@mui/material";
import SignupForm from "./SignupForm";
import { useTranslation } from "react-i18next";

export default function Signup(props) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        props.openDialog.current = handleClickOpen;
    });

    return (
        <div>
           
            <Dialog
                id="signup-dialog-main"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}
                sx={{background:"rgba(0,0,0,0.5)", backdropFilter:"blur(16px)"}}
            >
                <Box
                    width={{ xs: "80vw", sm: 500 }}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle 
                            color="text.dark"
                            sx={{
                                fontSize:{xs:"5.5vw", md:"2rem"},
                                fontWeight:"bold",
                                textAlign:"center"
                            }}
                        >{t("register")}</DialogTitle>
                       
                        <SignupForm
                            setopen={setOpen}
                        />
                    </DialogContent>
                </Box>

            </Dialog>
        </div>);
}