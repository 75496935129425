import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/system";
import useQuery from "../common-functions/QuerySearch";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import PaginationItem from "@mui/material/PaginationItem";
import { useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export default function PaginationCustom({ totalPages }) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const theme = useTheme();

  let query = useQuery();
  const navigate = useNavigate();
  const useCurrentRoute = useLocation();

  React.useEffect(() => {
    const page = parseInt(query.get("page"));
    if (page) {
      if (parseInt(totalPages) !== -1) {
        if (parseInt(page) <= parseInt(totalPages)) {
          setCurrentPage(page);
        } else {
          navigate(
            window.location.pathname +
              window.location.search.replace("page=" + page, "page=1")
          );
        }
      }
    } else {
      if (window.location.href.indexOf("?") !== -1) {
        navigate(window.location.pathname + window.location.search + "&page=1");
      } else {
        navigate(window.location.pathname + window.location.search + "?page=1");
      }
    }
  }, [useCurrentRoute, totalPages]); // eslint-disable-line

  const handleChange = (event, value) => {
    navigate(
      window.location.pathname +
        window.location.search.replace("page=" + currentPage, "page=" + value)
    );
  };
  return (
    <Box margin={1} display="flex" justifyContent={"center"}>
      {parseInt(totalPages) ? (
        <Pagination
          page={currentPage}
          count={totalPages}
          color="primary"
          onChange={handleChange}
          sx={{
            marginTop: 2,
          }}
          size={"small"}
          disabled={totalPages === 1}
          renderItem={(item) => (
            <PaginationItem
              sx={{
                ".MuiSvgIcon-root": {
                  fontSize: "1.5rem",
                },
              }}
              components={{
                previous:
                  theme.lang.lang === "en"
                    ? KeyboardArrowLeft
                    : KeyboardArrowRight,
                next:
                  theme.lang.lang === "en"
                    ? KeyboardArrowRight
                    : KeyboardArrowLeft,
              }}
              {...item}
            />
          )}
        />
      ) : null}
    </Box>
  );
}
