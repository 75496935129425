import { axiosCustom } from "../../../endpoints/Axios";
import { REWARD_POINTS_ACCUMULATIONS, REWARD_POINTS_AVAILABLE, REWARD_POINTS_EXPIRED, REWARD_POINTS_SUMMARY, REWARD_POINTS_TRANSACTIONS } from "../../../endpoints/Constants";

export const getRewardPointsSummary = async () => {
  try {
    const response = await axiosCustom.get(REWARD_POINTS_SUMMARY);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const limit =  "&limit=10";
export const getRewardPointsAccumulations = async (page) => {
  try {
    const response = await axiosCustom.get(REWARD_POINTS_ACCUMULATIONS + "?page=" + page + limit);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getRewardPointsTransactions = async (page) => {
  try {
    const response = await axiosCustom.get(REWARD_POINTS_TRANSACTIONS + "?page=" + page + limit);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRewardPointsAvailable = async (page) => {
  try {
    const response = await axiosCustom.get(REWARD_POINTS_AVAILABLE + "?page=" + page + limit);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getRewardPointsExpired = async (page) => {
  try {
    const response = await axiosCustom.get(REWARD_POINTS_EXPIRED + "?page=" + page + limit);
    return response.data;
  } catch (error) {
    throw error;
  }
};
