import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import LanguageSelector from "../../../ui-common-components/LanguageSelector";
import { UserInfo } from "./UserInfo";

const TopBar = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        minHeight: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: theme.lang.flexRowDirection,
          width: {xs:"100%", sm:"95%"},
        }}
      >
        <Grid
          item
          xs={6}
          md={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", sm: "flex-start" },
            flexDirection: theme.lang.flexRowDirection,
            mt: { xs: 1, sm: 0 },
            mb: { xs: 1, sm: 0 },
          }}
        >
          <LanguageSelector />
        </Grid>

        <Grid
          item
          xs={6}
          md={7}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-end" },
            alignItems: "center",
            flexDirection: theme.lang.flexRowDirection,
            mb: { xs: 1, sm: 0 },
            mt: { xs: 1, sm: 0 },
          }}
        >
          <UserInfo />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBar;
