import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { RESTORE_ACCOUNT, USER_LOGIN } from "../../../endpoints/Constants";
import { useTranslation } from "react-i18next";
import { axiosCustom } from "../../../endpoints/Axios";

const LoginForm = () => {
  const authComponents = useContext(AuthComponentsContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [restoreBtn, setRestoreBtn] = useState(false);
  const [token, setToken] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        phone: "+20",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        phone: Yup.string()
          .length(13, t("phone_number_invalid"))
          .required(t("empty_phone_error")),
        password: Yup.string()
          .min(6, t("invalid_password_length"))
          .max(16, t("invalid_password_length"))
          .required(t("empty_password_error")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setLoading(true);
          let formData = {
            phone: values.phone.replace("+2", ""),
            password: values.password,
          };
          axiosCustom
            .post(USER_LOGIN, formData)
            .then((res) => {
              setLoading(false);
              if (res.data.data.status === 0 && !res.data.data.blocked) {
                //setStatus({ success: false });
                setStatus({info: true})
                setErrors({
                  submit:
                    t("registration_confirmation_message1") +
                    ". " +
                    t("registration_confirmation_message2"),
                });
              } else if (res.data.data.blocked) {
                setStatus({ success: false });
                setErrors({ submit: res.data.message });
              } else if (res.data.data.deleted) {
                setStatus({ success: false });
                setErrors({ submit: t("restore_text") });
                setRestoreBtn(true);
                setToken(res.data.token);
              } else {
                setStatus({ success: true });
                localStorage.setItem("ematkn", res.data.token);
                window.location.reload();
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus({ success: false });
              setErrors({ submit: t("invalid_phone_password") });
            });
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: t("invalid_phone_password") });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setStatus,
        status,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            {errors.submit && (
              <Alert
                severity={status.success ? "success" : (status.info ? "info" : "error")}
                sx={{
                  width: "100%",
                  margin: "0 1rem",
                  direction: theme.lang.direction,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    mr: 1,
                    ml: 1,
                    display: "flex",
                  }}
                  component="span"
                >
                  {errors.submit}
                </Typography>
              </Alert>
            )}
            <FormControl
              fullWidth
              error={Boolean(touched.phone && errors.phone)}
              sx={{
                margin: "0.5rem 1rem",
                ".react-tel-input .form-control:focus": {
                  borderColor: "primary.main",
                  boxShadow: "0px 0px 0px 1px " + theme.palette.primary.main,
                },
              }}
            >
              <FormHelperText
                sx={{
                  textAlign: theme.lang.textAlign,
                  fontSize: "1rem",
                }}
              >
                {t("phone_number")}
              </FormHelperText>
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  color: "#000",
                  borderRadius: "10px",
                }}
                inputProps={{ name: t("phone_number") }}
                value={values.phone}
                name={"phone"}
                specialLabel={""}
                onBlur={handleBlur}
                onChange={(e) => {
                  values.phone = "+" + e;
                }}
                country={"eg"}
                onlyCountries={["eg"]}
                countryCodeEditable={false}
                isValid={touched.phone && !errors.phone}
              />
              {touched.phone && errors.phone && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-phone-login"
                  sx={{
                    textAlign: theme.lang.textAlign,
                  }}
                >
                  {errors.phone}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ margin: "0.5rem 1rem" }}
            >
              <FormHelperText
                sx={{
                  textAlign: theme.lang.textAlign,
                  fontSize: "1rem",
                }}
              >
                {t("password")}
              </FormHelperText>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                autoComplete="on"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                  sx={{
                    textAlign: theme.lang.textAlign,
                  }}
                >
                  {errors.password}
                </FormHelperText>
              )}
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Typography
                  color="primary.main"
                  sx={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    textAlign: "left",
                    ":hover": {
                      color: "primary.border",
                    },
                    width: "fit-content",
                    paddingTop: "1rem",
                  }}
                  onClick={() => {
                    authComponents.openForgetPassword();
                  }}
                >
                  {t("forgot_password")}
                </Typography>
              </Box>
            </FormControl>

            <Grid item xs={11}>
              {restoreBtn ? (
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  variant="contained"
                  sx={{
                    padding: "0.7rem 0rem",
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    borderRadius: "48px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem",
                  }}
                  onClick={() => {
                    setLoading(true);

                    axiosCustom
                      .put(
                        RESTORE_ACCOUNT,
                        {},
                        {
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            locale: localStorage.getItem("i18nextLng"),
                            Authorization: "Bearer " + token,
                          },
                        }
                      )
                      .then((res) => {
                        setStatus({ success: true });
                        setErrors({ submit: t("account_restored") });
                        setLoading(false);
                        localStorage.setItem("ematkn", token);
                        window.location.reload()
                      })
                      .catch((err) => {
                        setLoading(false);
                        setStatus({ success: false });
                        setErrors({ submit: err.data.message });
                      });
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    t("restore_account")
                  )}
                </Button>
              ) : (
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: "0.7rem 0rem",
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    borderRadius: "48px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem",
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    t("login")
                  )}
                </Button>
              )}

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    marginBottom: "2rem",
                    direction: theme.lang.direction,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      mr: 1,
                      ml: 1,
                    }}
                  >
                    {t("dont_have_an_account")}
                  </Typography>

                  <Typography
                    color="primary.main"
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textAlign: "left",
                      ":hover": {
                        color: "primary.border",
                      },
                      paddingLeft: "4px",
                      fontSize: { xs: "0.9rem", md: "1rem" },
                    }}
                    onClick={() => {
                      authComponents.openSignup();
                    }}
                  >
                    {t("sign_up")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
