import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Link } from "@mui/material";
import { ExpandLess } from "@mui/icons-material";

export const parseCategoriesTree = (categoriesTree, theme, level) => {
  let list =
    categoriesTree.sub_categories &&
    categoriesTree.sub_categories.map((element) => {
      return element.sub_categories.length === 0 ? (
        <Box key={element.name} sx={{ width: "100%" }}>
          <Box
            width="100%"
            sx={{
              borderBottom: "1px solid #BCBCBC",
              p: "0.45rem 0",
              textAlign: theme.lang.textAlign,
              fontSize: "inherit",
            }}
          >
            <Link
              href={"/products?categoryId=" + element.id + "&page=1"}
              sx={{
                color: "primary.dark",
                textDecoration: "none",
                width: "100%",
                fontSize: "inherit",
                fontWeight: 900 - 100 * level,
                ":hover": {
                  color: "primary.main",
                },
              }}
            >
              {element.name}
            </Link>
          </Box>
        </Box>
      ) : (
        <Box key={element.name} sx={{ width: "100%" }}>
          {level < 0 ? ( //2
            <Box>
              <Box
                width="100%"
                sx={{
                  borderBottom: "1px solid #BCBCBC",
                  p: "0.45rem 0",
                  textAlign: theme.lang.textAlign,
                  fontSize: "inherit",
                }}
              >
                <Link
                  href={"/products?categoryId=" + element.id + "&page=1"}
                  sx={{
                    color: "primary.dark",
                    textDecoration: "none",
                    width: "100%",
                    fontSize: "inherit",
                    fontWeight: 900 - 100 * level,
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  {element.name}
                </Link>
              </Box>
              <Box sx={{ p: 0 }}>
                {parseCategoriesTree(element, theme, level + 1)}
              </Box>
            </Box>
          ) : (
            <Accordion elevation={0} sx={{ p: 0, width: "100%" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandLess />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "1px solid #BCBCBC",
                  p: "1.2rem 0",
                  minHeight: 30,
                  maxHeight: 30,
                  "&.Mui-expanded": {
                    minHeight: 30,
                    maxHeight: 30,
                  },
                }}
              >
                <Link
                  href={"/products/"+element.slug+"?categoryId=" + element.id + "&page=1"}

                  sx={{ color: "primary.dark",
                  textDecoration: "none",
                  width: "fit-content",
                  fontSize: "inherit",
                  textAlign:"start",
                  fontWeight: 900 - 100 * level,
                  ":hover": {
                    color: "primary.main",
                  }, }}
                >
                  {element.name}
                </Link>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {parseCategoriesTree(element, theme, level + 1)}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      );
    });
  return list;
};
