import { Box, Grid, Link } from "@mui/material";
import React, { useContext, useEffect } from "react";
import logo from "../../../assets/logo2.png";
import CustomizedMenu from "./Menu.js";
import { Menu, Search } from "@mui/icons-material";
import {
  InputAdornment,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CartBadge } from "../CartBadge";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ResponsiveDrawer from "./Drawer";
import { parseCategoriesTree } from "./Functions";
import { ProfileContext } from "../../../context/ProfileContext";

const SideBar = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useContext(ProfileContext);

  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  const [searchCategory, setSearchCategory] = useState("all");// eslint-disable-line no-unused-vars
  const [searchText, setSearchText] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  const searchFunction = (e) => {
    if (searchText) {
      if (searchCategory === "all") {
        navigate("/products?search=" + searchText + "&page=1");
      } else {
        navigate(
          "/products?categoryId=" +
            searchCategory +
            "&search=" +
            searchText +
            "&page=1"
        );
      }
    }
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [dataList, setDataList] = useState(null);
 // const [menuDisplay, setMenuDisplay] = useState("none");

  /*const toggleMenuDisplay = () => {
    if (menuDisplay === "none") {
      setMenuDisplay("block");
    } else {
      setMenuDisplay("none");
    }
  };*/
  useEffect(() => {
    setDataList(parseCategoriesTree(props.categoriesTree, theme, 1));
  }, []); // eslint-disable-line
  const useCurrentRoute = useLocation();

  useEffect(() => {
    if (useCurrentRoute.pathname === "/") {
      setSearchText("");
    }
  }, [useCurrentRoute]); // eslint-disable-line

  return (
    <Box
      sx={{
        width: "100%",
        height: { md: "69px" },
        padding: { xs: "1rem 1rem 0 1rem", md: "1rem 1rem 0 1rem", lg: "1rem 5rem" },
      }}
    >
      <ResponsiveDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        dataList={dataList}
      />
      <Grid
        container
        alignItems={"center"}
        sx={{ direction: theme.lang.direction, mb: {xs:1, md:2} }}
      >
        <Grid
          container
          item
          xs={12}
          md={4}
          lg={3}
          justifyContent="space-between"
          sx={{
            mb: { xs: 1, md: 0 },
            direction: theme.lang.direction,
          }}
        >
          <Grid
            item
            xs={5.8}
            sm={3.5}
            md={7}
            lg={10}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {xs:"space-around", sm:"space-between"},
              flexDirection: { xs: "row", sm: "row-reverse" },
            }}
          >
              <Menu
              sx={{
                color: "primary.dark",
                //display: { xs: "none", md: "block" },
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
               // toggleMenuDisplay();
                handleDrawerToggle()
              }}
            />
            <Box
              sx={{
                textAlign: "center",
                width:{xs:"120px", md:"140px"},
              }}
            >
              <Link href="/">
                <img
                  src={logo}
                  width="100%"
                  //style={{ maxWidth: "160px" }}
                  alt="..."
                />
              </Link>
            </Box>

          
          </Grid>
          <Grid
            item
            xs={6.2}
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {profile.connected ? <CartBadge /> : null}
          </Grid>
          {/*<Grid
            item
            xs={1}
            sm={9}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>*/}
        </Grid>

        <Grid item xs={12} sm={8} md={5} lg={6}>
          <Box width="100%">
            <OutlinedInput
              placeholder={t("search")}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchFunction();
                }
              }}
              endAdornment={
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {/*<Box>
                    <Select
                      value={searchCategory}
                      onChange={(e) => {
                        setSearchCategory(e.target.value);
                      }}
                      sx={{
                        height: "100%",
                        borderWidth: 0,
                        ".MuiSelect-select": {
                          p: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "0",
                        },
                      }}
                    >
                      <MenuItem
                        value="all"
                        sx={{ direction: theme.lang.direction }}
                      >
                        {t("all_categories")}
                      </MenuItem>
                      {props.categoriesTree &&
                        props.categoriesTree.sub_categories.map(
                          (category, index) => {
                            return (
                              <MenuItem
                                key={"category-" + index}
                                value={category.id}
                                sx={{ direction: theme.lang.direction }}
                              >
                                {category.name}
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </Box>*/}

                  <InputAdornment
                    position="start"
                    sx={{
                      backgroundColor: "primary.main",
                      padding: "20px 14px",
                      borderRadius:
                        theme.lang.lang === "en"
                          ? "0 3px 3px 0"
                          : "3px 0 0 3px",
                      border: 0,
                      marginRight: theme.lang.lang === "en" ? -1 : 1,
                      marginLeft: theme.lang.lang === "en" ? 1 : -1,
                      color: "#FFF",
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={searchFunction}
                  >
                    <Search />
                  </InputAdornment>
                </Box>
              }
              sx={{
                direction: theme.lang.direction,
                width: "100%",
                height: "40px",
                marginTop: 0,
                background: "#FFF",
                border: "1px solid",
                borderColor: "primary.border",
                borderRadius: "3px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FFF",
                  border: 0,
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FFF",
                  border: 0,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FFF",
                  border: 0,
                },
                "&.MuiOutlinedInput-root": {
                  paddingLeft: theme.lang.lang === "en" ? "auto" : 0,
                  paddingRight: theme.lang.lang === "en" ? 0 : "auto",
                  marginLeft: theme.lang.lang === "ar" ? "-2px" : "auto",
                },
              }}
            ></OutlinedInput>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            justifyContent: "end",
            flexDirection: theme.lang.flexRowDirection,
          }}
        >
          {profile.connected ? <CartBadge /> : null}
        </Grid>
      </Grid>

      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          left: theme.lang.lang === "en" ? 0 : "78%",
          top:"-1.5px",
          display: useCurrentRoute.pathname === "/" ? {xs:"none", lg:"block"} : "none",
          width: "22%",
          textAlign: "center",
          borderRadius: "8px",
         
        }}
      >
        <CustomizedMenu dataList={dataList} />
      </Box>
    </Box>
  );
};

export default SideBar;
