import { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from "@mui/material";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";

export default function Login(props) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        props.openDialog.current = handleClickOpen;
    });

    return (
        <div>
            <Dialog
                id="login-dialog-main"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}
                sx={{background:"rgba(0,0,0,0.5)", backdropFilter:"blur(16px)"}}
            >
                <Box
                    width={{ xs: "80vw", sm: 500}}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle 
                            color="text.dark"
                            sx={{
                                fontSize:{xs:"5.5vw", md:"2rem"},
                                fontWeight:"bold",
                                textAlign:"center"
                            }}
                        >{t("login")}</DialogTitle>
                        <Typography
                            variant="body2"
                            color="#667085"
                            sx={{
                                textAlign:"center",
                                marginBottom:"1rem"
                            }}
                        >
                            {t("login_welcome") + " " }
                            <Typography component={"span"} sx={{
                                color:"primary.main"
                            }}>
                                {t("app_title")}
                            </Typography>
                        </Typography>
                        <LoginForm
                            setopen={setOpen}
                        />
                    </DialogContent>
                </Box>

            </Dialog>
        </div>);
}