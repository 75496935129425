import { axiosCustom } from "../../endpoints/Axios";
import {
  CATEGORY,
  PRODUCTS,
  RELATED_PRODUCTS,
  UP_SELLING,
} from "../../endpoints/Constants";

const pageSize = 16;

export const getProductById = async (id) => {
  try {
    const response = await axiosCustom.get(PRODUCTS + "/" + id);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getRelatedProducts = async (id) => {
  try {
    const response = await axiosCustom.get(
      PRODUCTS + "/" + id + RELATED_PRODUCTS
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getUpSellingProducts = async (id) => {
  try {
    const response = await axiosCustom.get(PRODUCTS + "/" + id + UP_SELLING);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const calculatePercentage = (oldNum, newNum) => {
  let result = Math.abs((oldNum - newNum) / oldNum) * 100;

  return Math.ceil(result);
};

export const getCategoryProducts = async (
  categoryId,
  page,
  searchText,
  price,
  filters
) => {
  try {
    let params = "?page=" + page + "&limit=" + pageSize;
    if(categoryId === "offers"){
      params += "&view=offers";
    }
    else{

        if (categoryId) {
          params += "&category_id=" + categoryId;
        }
    
        if (price) {
          params += "&price=" + price;
        }
    
        if (searchText) {
          params += "&search=" + searchText;
        }
        params += filters;
    }


    const response = await axiosCustom.get(PRODUCTS + params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await axiosCustom.get(CATEGORY + "/" + id);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
// find index of object in array with code = brand
export const getBrand = (attributes) => {
  if (attributes) {
    for (let i = 0; i < attributes.length; i++) {
      if (attributes[i].brand_attribute === true) {
        return attributes[i].brand_icon
          ? {
              typeImg: true,
              value: attributes[i].brand_icon,
            }
          : {
              typeImg: false,
              value: attributes[i].value,
            };
      }
    }
  }
  return null;
};

export const getRegularPrice = (product) => {
  return product.formated_regular_price
  /*if (product.min_price_vendor === "") {
    const emartVendor = product.vendors.find(
      (item) => item.vendor_code === ""
    );
      try{
        return emartVendor.formated_regular_price &&
          parseInt(emartVendor.formated_regular_price) !==
            parseInt(emartVendor.formated_price)
          ? emartVendor.formated_regular_price
          : "";
      }
      catch{
        return "";
      }
  } else {
    return "";
  }*/
};
