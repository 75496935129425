import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosCustom } from "../../endpoints/Axios";
import { ABOUT_US } from "../../endpoints/Constants";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosCustom
      .get(ABOUT_US)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const meta = {
    title: data ? data.meta_title : "eMart | About us",
    description: data
      ? data.meta_description
      : "The online marketplace emart-eg.com is Egypt’s online market leader in the fields of IT, consumer electronics and telecommunication. It’s offering consistently low prices as well as fast, reliable and free delivery. We enable a comfortable and efficient shopping experience at consistently low prices. At the same time, we create a comprehensive platform for inspiration, information and communication that invites customers to actively participate",
    keywords: data ? data.meta_keywords : "",
  };

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
      </Helmet>

      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            height: "1500px",
          }}
        >
          <iframe
            title="AboutUs"
            width={"100%"}
            height={"100%"}
            srcDoc={data.html_content}
          />
        </Box>
      )}
    </>
  );
};

export default AboutUs;
